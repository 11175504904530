import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Rate from "./Rate";
import  { requestDelRates } from "../../redux/rates-reducer";

function RatesOptions({rate, onChange, handleEdit}) {

    const handleDelete = () => {
        requestDelRates(rate.id);
        onChange(true);
    }

    return (
        <Card sx={{ height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} >
                <MDTypography style={{color: '#1a73e8'}} component={Link} to={'#'} onClick={()=>handleEdit(rate)} >
                    <Tooltip title={'Редагувати'} placement="top">
                        <Icon>edit</Icon>
                    </Tooltip>
                </MDTypography>
                <MDBox >
                    {( rate.displayed &&
                        <MDTypography style={{color: '#1a73e8'}} component={Link} to={'#'} variant="body2" color="secondary" >
                            <Tooltip title={'Видимий'} placement="top">
                                <VisibilityIcon/>
                            </Tooltip>
                        </MDTypography>
                    )}
                </MDBox>
                <MDBox >
                        <MDTypography style={{color: '#1a73e8'}}
                                      component={Link} to={'#'}
                                      onClick={handleDelete}
                        >
                            <Tooltip title={'Видалити'} placement="top">
                                <Icon>delete</Icon>
                            </Tooltip>
                        </MDTypography>
                </MDBox>
            </MDBox>
            <Rate rate={rate} />
        </Card>
    );
}

export default RatesOptions;
