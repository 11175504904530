import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Poll from "./Poll";
import  { requestDelQuestion } from "../../redux/polls-reducer";

function PollOptions({poll, onChange, handleEdit}) {

    const handleDelete = () => {
        requestDelQuestion(poll.id);
        onChange(true);
    }

    return (
    <>
        <Card>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} >
                <MDTypography style={{color: '#1a73e8'}} component={Link} to={'#'} onClick={()=>handleEdit(poll)} >
                    <Tooltip title={'Редагувати'} placement="top">
                        <Icon>edit</Icon>
                    </Tooltip>
                </MDTypography>
                <MDBox >
                    {( poll.completed &&
                        <MDTypography style={{color: '#4babf0'}} component={Link} to={'#'} variant="body2" color="secondary" >
                            <Tooltip title={'Завершено'} placement="top">
                                <Icon>done_outline</Icon>
                            </Tooltip>
                        </MDTypography>
                    )}
                    {( poll.closed &&
                        <MDTypography style={{color: '#4babf0'}} component={Link} to={'#'} variant="body2" color="secondary" >
                            <Tooltip title={'Неактивно'} placement="top">
                                <Icon>lock</Icon>
                            </Tooltip>
                        </MDTypography>
                    )}
                </MDBox>
                <MDBox >
                        <MDTypography style={{color: '#1a73e8'}}
                                      component={Link} to={'#'}
                                      onClick={handleDelete}
                        >
                            <Tooltip title={'Видалити'} placement="top">
                                <Icon>delete</Icon>
                            </Tooltip>
                        </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox pt={1} px={2} pb={1} >
                <MDTypography variant="caption" fontWeight="bold" color="text" >
                  {"Дата відкриття: " }
                </MDTypography>
                <br></br>
                <MDTypography variant="caption" fontWeight="bold" color="text" >
                  {poll.start_date_str}
                </MDTypography>
            </MDBox>
            <MDBox pt={1} px={2}  pb={1} >
                <MDTypography variant="caption" fontWeight="bold" color="text" >
                 {"Дата закриття: " }
                </MDTypography>
                <br></br>
                <MDTypography variant="caption" fontWeight="bold" color="text" >
                 {poll.close_date_str}
                </MDTypography>
            </MDBox>
        </Card>
        <Poll poll={poll} />
    </>
    );
}

export default PollOptions;
