import {Action, applyMiddleware, combineReducers, legacy_createStore as createStore} from "redux";
import profilesReducer from "./profiles-reducer";
import profileReducer from "./profile-reducer";
import authReducer from "./auth-reducer";
import thunkMiddleware, {ThunkAction} from "redux-thunk";
import chatMessagesReducer from "./chat-messages-reducer";
import accountsReducer from "./accounts-reducer";
import housesReducer from "./houses-reducer";
import accountServicesReducer from "./account-services-reducer";
import accountProfilesReducer from "./account-profiles-reducer";
import accountAgreementsReducer from "./account-agreements-reducer";
import flatsReducer from "./flats-reducer";
import flatMetersReducer from "./flat-meters-reducer";
import houseMetersReducer from "./house-meters-reducer";
import osbbReducer from "./osbb-reducer";
import pollsReducer from "./polls-reducer";
import chatReducer from "./chat-reducer";
import ratesReducer from "./rates-reducer";
import osbbSummaryReducer from './summary-reducer';
import houseReducer from './house-reducer';
import debtorsReducer from "./debtors-reducer";
import servicestatsReducer from "./servicestats-reducer";
import paymentshistoryReducer from "./paymentshistory-reducer";
import paymentsReducer from "./payments-reducer";
import mettershistoryReducer from "./mettersdata-reducer";
import servicetypesReducer from "./servicetype-reducer.js";
import serviceReducer from "./service-reducer.js";
import deviceReducer from "./device-reducer.js";
import unitReducer from "./unit-reducer.js";

let rootReducers = combineReducers({
    profilesPage: profilesReducer,
    profilePage: profileReducer,
    auth: authReducer,
    chatMessages: chatMessagesReducer,
    accounts: accountsReducer,
    accountServices: accountServicesReducer,
    accountProfiles :accountProfilesReducer,
    accountAgreements: accountAgreementsReducer,
    houses: housesReducer,
    housePage: houseReducer,
    flats: flatsReducer,
    flatMeters: flatMetersReducer,
    houseMeters: houseMetersReducer,
    osbb: osbbReducer,
    pollData: pollsReducer,
    chat: chatReducer,
    rates: ratesReducer,
    osbbsummary: osbbSummaryReducer,
    debtors: debtorsReducer,
    servicestats: servicestatsReducer,
    payshist:paymentshistoryReducer,
    mettershist:mettershistoryReducer,
    payments:paymentsReducer,
    servicetypes: servicetypesReducer,
    service: serviceReducer,
    device: deviceReducer,
    unit: unitReducer,
})


type RootReducerType = typeof rootReducers;
export type AppStateType = ReturnType<RootReducerType>

export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>

type PropertiesTypes<T> = T extends {[key: string]: infer U} ? U : never
export type InferActionsTypes<T extends {[key: string]: (...args: any[])=>any}> = ReturnType<PropertiesTypes<T>>

let store = createStore(rootReducers, applyMiddleware(thunkMiddleware))

export default store
