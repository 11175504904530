import {AccountApi} from "../api/api";

const SET_ACCOUNT_AGREEMENTS = "SET_ACCOUNT_AGREEMENTS"
const ACCOUNT_AGREEMENTS_TOGGLE_IS_FETCHING = "ACCOUNT_AGREEMENTS_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    agreements: []
}

const accountAgreementsReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SET_ACCOUNT_AGREEMENTS:
            return {
                ...state,
                agreements: action.value
            }
        case ACCOUNT_AGREEMENTS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setAccountAgreements = (value) => ({type: SET_ACCOUNT_AGREEMENTS, value: value})
export const accountAgreementsToggleIsFetching = (isFetching) => ({type: ACCOUNT_AGREEMENTS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestAccountAgreements = (accountId) => {
    return async (dispatch) => {
        dispatch(accountAgreementsToggleIsFetching(true))
        let response = await AccountApi.getAccountAgreements(accountId)
        if (response !== undefined ) {
            dispatch(setAccountAgreements(response.results))
            dispatch(accountAgreementsToggleIsFetching(false))
        }
    }
}

export default accountAgreementsReducer
