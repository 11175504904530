import {ProfileApi} from "../api/api";

const SET_PROFILES = "GET_PROFILES"
const SET_PROFILE = "GET_PROFILE"
const ADD_PROFILES = "ADD_PROFILES"
const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";
const TOGGLE_IS_FETCHING = "TOGGLE_IS_FETCHING";

let initialState = {
    profile: {},
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    isFetching: false,
    profiles: []
}

const profilesReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case SET_PROFILES:
            return {
                ...state,
                profiles: action.value
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: action.value
            }
        case ADD_PROFILES:
            let stateCopy = {...state}
            stateCopy.profiles = [...state.profiles]
            return stateCopy
        case TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setProfile = (value) => ({type: SET_PROFILE, value: value})
export const setProfiles = (value) => ({type: SET_PROFILES, value: value})
export const addProfile = () => ({type: ADD_PROFILES})
export const setPageChanged = (value) => ({type: PAGE_CHANGED, value: value})
export const setPageSizeChanged = (value) => ({type: PAGE_SIZE_CHANGED, value: value})
export const setTotalCountChanged = (value) => ({type: TOTAL_COUNT_CHANGED, value: value})
export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, value: isFetching})

export const requestProfiles = (queryPageIndex, queryPageSize, slug_type, locationSearch) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true))
        dispatch(setPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await ProfileApi.getProfiles(offset, queryPageSize, slug_type, locationSearch)
        if (response !== undefined) {
            dispatch(setPageSizeChanged(queryPageSize))
            dispatch(setTotalCountChanged(response.count))
            dispatch(setProfiles(response.results))
        }
        dispatch(toggleIsFetching(false))
    }
}

export default profilesReducer
