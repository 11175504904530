import {ChatMessagesApi} from "../api/api";

const SET_MESSAGES = "SET_MESSAGES";
const SET_NEXT_MESSAGES = "SET_NEXT_MESSAGES";
const MESSAGES_IS_FETCHING = "MESSAGES_IS_FETCHING"
const SET_MESSAGE_OFFSET = "SET_MESSAGE_OFFSET";
const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
const SET_HAS_MORE_MESSAGES = "SET_HAS_MORE_MESSAGES";
const SET_LAST_LOADED_MESSAGE_ID = "SET_LAST_LOADED_MESSAGE_ID";
const SET_READY = "SET_READY"

let initialState = {
    messages: {},
    nextMessages: {},
    offset: {},
    isFetching: false,
    isReady: {},
    hasMoreMessages: {},
    messagesLimit: 100,
    lastLoadedMessageId: {}
}

const chatMessagesReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SET_READY:
            let isReady = state.isReady
            isReady[action.value.userId] = true
            return {
                ...state,
                isReady: isReady
            }
        case SET_MESSAGES:
            let messages = state.messages
            messages[action.value.userId] = action.value.value.reverse()
            return {...state , messages: messages}
        case SET_NEW_MESSAGE:
            let oldMessages = state.messages
            if (action.value.userId in oldMessages) {
                oldMessages[action.value.userId] = [...oldMessages[action.value.userId], action.value.value]
            } else {
                oldMessages[action.value.userId] = [action.value.value]
            }
            return {
                ...state,
                messages: oldMessages
            }

        case SET_NEXT_MESSAGES:
            let existMessages = state.messages
            if (action.value.userId in existMessages) {
                existMessages[action.value.userId] = [...action.value.value.reverse(), ...existMessages[action.value.userId]]
            } else {
                existMessages[action.value.userId] = [...action.value.value.reverse()]
            }
            return {
                    ...state,
                    messages: existMessages
            }

        case MESSAGES_IS_FETCHING:
            return {...state,
                isFetching: action.value}
        case SET_HAS_MORE_MESSAGES:
            let hasMoreMessages = state.hasMoreMessages
            hasMoreMessages[action.value.userId] = action.value.value
            return {...state,
                hasMoreMessages: hasMoreMessages}
        case SET_MESSAGE_OFFSET:
            let offset = state.offset
            offset[action.value.userId] = action.value.value
            return {...state,
                offset: offset}
        case SET_LAST_LOADED_MESSAGE_ID:
            let lastLoadedMessagesId = state.lastLoadedMessageId
            lastLoadedMessagesId[action.value.userId] = action.value.value
            return {...state,
                lastLoadedMessageId: lastLoadedMessagesId}
        default:
            return state;
    }
}


export const setMessages = (userId, value) => ({type: SET_MESSAGES, value: {userId, value}})
export const setMessageOffset = (userId, value) => ({type: SET_MESSAGE_OFFSET, value: {userId, value}})
export const setNextMessages = (userId, value) => ({type: SET_NEXT_MESSAGES, value: {userId, value}})
export const setNewMessage = (userId, value) => ({type: SET_NEW_MESSAGE, value: {userId, value}})
export const setReady = (userId) => ({type: SET_READY, value: userId})
export const messagesIsFetching = (isFetching) => ({type: MESSAGES_IS_FETCHING, value: isFetching})
export const setHasMoreMessages = (userId, value) => ({type: SET_HAS_MORE_MESSAGES, value: {userId, value}})
export const setLastLoadedMessageId = (userId, value) => ({type: SET_LAST_LOADED_MESSAGE_ID, value: {userId, value}})

export const requestMessages = (userId) => {
    return async (dispatch) => {
        dispatch(messagesIsFetching(true))
        let response = await ChatMessagesApi.getMessages(0, initialState.messagesLimit, 0, userId)
        if (response !== undefined) {
            let messages = response.data.results
            dispatch(setHasMoreMessages(userId, response.data.next != null))
            if (messages.length > 0) {
                dispatch(setLastLoadedMessageId(userId, messages[messages.length - 1].id))
            }
            dispatch(setMessages(userId, messages))
            dispatch(messagesIsFetching(false))
            dispatch(setReady(userId))
        }
    }
}

export const requestNextMessages = (userId, offset) => {
    return async (dispatch, getState) => {
        let lastLoadedMessageId = userId in getState().chatMessages.lastLoadedMessageId ? getState().chatMessages.lastLoadedMessageId[userId] : 0
        let response = await ChatMessagesApi.getMessages(offset, initialState.messagesLimit, lastLoadedMessageId, userId)
        if (response !== undefined) {
            dispatch(setHasMoreMessages(userId, response.data.next != null))
            dispatch(setMessageOffset(userId, offset + getState().chatMessages.messagesLimit))
            dispatch(setNextMessages(userId, response.data.results))
        }
    }
}

export const addNewMessages = (userId, message) => {
    return async (dispatch) => {
        dispatch(setNewMessage(userId, message))
    }
}
export default chatMessagesReducer
