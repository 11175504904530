import React, {useEffect} from "react"
import Profile from "./Profile"
import {connect} from "react-redux";
import { getProfile } from "../../redux/profile-reducer";
import {useParams} from "react-router-dom";

function ProfileContainer(props) {

    let { profileId } = useParams();
    if( !profileId ) {
        profileId = 1;
    }
    const [openEdit, setOpenEdit] = React.useState(false);

    const handleCloseEdit = () => {setOpenEdit(false);};
    const handleEdit = () => {setOpenEdit(true);};

    useEffect(() => {
        props.getProfile(profileId)
    }, [profileId, openEdit]);

    return <Profile {...props} profile={props.profile} openEdit={openEdit} handleClose={handleCloseEdit} handleEdit={handleEdit}/>
}

let mapStateToProps = (state) => {
    return {
        profile : state.profilePage.profile,
    }
}

export default connect(mapStateToProps,{getProfile}) (ProfileContainer);
