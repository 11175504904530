import React, {useState} from "react"
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import Button from '@mui/material/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from "components/MDTypography";
import DataTable from "../../components/Tables/DataTable";
import PayServicesTableData from "./data/PayServicesTableData";
import {requestPostPayments} from "../../redux/payments-reducer";

function PayDialog({open, balancedate, accountServices, handleClose}) {
    const [TotalPay, setTotalPay] = useState(0);
    const [errorMsg, setErrorMsg] = React.useState(null);

    const period = balancedate.toISOString().slice(0, 7)
    const dispatch = useDispatch()
    let Services4Pay = [...accountServices];

    const handleOnCheckboxClick = ( id, checked) => {
        Services4Pay.map((service) => { if (service.id===id)
            {   service.checked = checked;
                if (!service.sum_pay) {  service.sum_pay =  1 * parseFloat(service.balance_ish).toFixed(2) ; }
            }
        });
        setTotalPay(calculateTotalSum(Services4Pay));
    };
    const handleOnInputChange = ( id, new_sum_pay) => {
        Services4Pay.map((service) => { if (service.id===id) { service.sum_pay = 1 * parseFloat(new_sum_pay).toFixed(2) }});
        setTotalPay(calculateTotalSum(Services4Pay));
    };
    const calculateTotalSum = (data) => {
        const totalAmount = data
          .map((s) => { return s.checked?s.sum_pay*1:0.00 })
          .reduce((pay, total) => (total + pay), 0.00);
        return 1 * totalAmount.toFixed(2) ;
      };

    const handleCloseDialog = () => {
        setTotalPay(0);
        Services4Pay = [...accountServices];
        setErrorMsg(null);
        handleClose();
    };
    const navigate  = useNavigate();
    const handlePay = () => {
        let data = {'payments_data' : Services4Pay,
                    'back_ref': window.location.href
        }
        console.log('*** handlePay ', data )
        setErrorMsg(null);
        requestPostPayments(
            data
        ).then( (res) => {
                console.log('***  res', res);
                if (res.err_code==0) {
                    window.location.replace(res.data.url);
                } else {
                    setErrorMsg(" Щось пішло не так ! Помилка " + res.err_code + "-" + res.err_msg);
                };
            // alert("Оплата пройшла успішно!");
            // handleCloseDialog();
        });
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <DialogTitle id="responsive-dialog-title">
                Сплатити обрані послуги за {period}
            </DialogTitle>
            <DialogContent>
            { errorMsg && (<MDTypography variant="body1" style={{ color:'#FA0000'}}>Сталася помилка - {errorMsg} </MDTypography>) }
                <DataTable
                    table={PayServicesTableData(Services4Pay, handleOnCheckboxClick, handleOnInputChange)}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    queryPageIndex={0}
                    queryPageSize={1000}
                    totalCount={accountServices.length}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePay} autoFocus>
                    Сплатити = {TotalPay} грн.
                </Button>
                <Button onClick={handleCloseDialog} >
                    Закрити
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PayDialog;
