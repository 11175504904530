import React, {useState} from "react";
import PropTypes from 'prop-types';
// @mui material components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import Debtors from "layouts/dashboard/components/Debtors/Debtors";
import OsbbServiceSummary from "../../components/Summary/OsbbServiceSummary";
import Charts from "../../components/Charts/Charts";
import ServiceStats from "./components/ServiceStats/ServiceStats";
import Rates from "../../components/Rates/Rates";
import OsbbUserSummary from "../../components/Summary/OsbbUserSummary";

import PersoncIcon from '@mui/icons-material/Person';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const AnalyticsUserDashboardNew = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [BalanceDate, setBalanceDate] = useState(new Date());
  const handleDateChange = (BalanceDate) => {
      setBalanceDate(BalanceDate);
  };


  return (
<DashboardLayout>
  <DashboardNavbar />
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}
              onChange={handleChange}
              selectionFollowsFocus
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#b6defa'
                }
              }}
        >
          <Tab icon={<PersoncIcon/>} iconPosition="start" label="Мої дані" {...a11yProps(0)} />
          <Tab icon={<MapsHomeWorkIcon/>} iconPosition="start" label="Дані ОСББ" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
           <Rates />
         <OsbbUserSummary onChange={handleDateChange} />
          <Charts user={true}  />
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <ServiceStats balancedate={BalanceDate} user={true} />
                </Card>
              </Grid>
            </Grid>
          </MDBox>
      </TabPanel>
      <TabPanel value={value} index={1}>
          <OsbbServiceSummary onChange={handleDateChange}/>
          <Charts user={false} />
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Card  >
                  <Debtors balancedate={BalanceDate} />
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <ServiceStats balancedate={BalanceDate} />
                </Card>
              </Grid>
            </Grid>
          </MDBox>
      </TabPanel>
    </Box>
    <Footer />
    </DashboardLayout>
  );
}
