import { useState } from "react"
import {useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import PersoncIcon from '@mui/icons-material/Person';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Flats } from './components/Flats';
import FlatMetters from './components/FlatMetters';
import HouseMetters from "./components/HouseMetters";
import Houses from "./components/Houses";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const MettersDataNew = (props) => {
  let params = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
<DashboardLayout>
  <DashboardNavbar />
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#b6defa'
                }
              }}
        >
          <Tab icon={<PersoncIcon/>} iconPosition="start" label="Квартирні лічильники" {...a11yProps(0)} />
          <Tab icon={<MapsHomeWorkIcon/>} iconPosition="start"  label="Будинкові лічильники" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          {(params.flatId === undefined) && <Flats />}
          {(params.flatId !== undefined) && <FlatMetters flatId={params.flatId}/>}
      </TabPanel>
      <TabPanel value={value} index={1}>
          {(params.houseId === undefined) && <Houses />}
          {(params.houseId !== undefined) && <HouseMetters houseId={params.houseId}/>}
      </TabPanel>
    </Box>
    <Footer />
</DashboardLayout>
  );
}
