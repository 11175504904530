import {createSelector} from "reselect";

const getOsbbStatsSelector = (state) => {
    return state.osbb.osbbstats
}

export const getStats = createSelector(getOsbbStatsSelector, (stats) => {
    return stats;
})


export const getOSBBSel = (state) => {
    return state.osbb.osbb
}

export const getOsbbPositionsSel = (state) => {
    return state.osbb.osbbpositions
}

export const getOsbbDocsSel = (state) => {
    return state.osbb.osbbdocs
}

export const getUsefullInfoSel = (state) => {
    return state.osbb.usefullinfo
}
