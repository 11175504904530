import {FlatApi} from "../api/api";

const GET_FLATS = "GET_FLATS"
const SET_FLAT = "SET_FLAT"
const FLATS_PAGE_CHANGED = 'FLATS_PAGE_CHANGED';
const FLATS_PAGE_SIZE_CHANGED = "FLATS_PAGE_SIZE_CHANGED";
const FLATS_TOTAL_COUNT_CHANGED = "FLATS_TOTAL_COUNT_CHANGED";
const FLATS_TOGGLE_IS_FETCHING = "FLATS_TOGGLE_IS_FETCHING";

let initialState = {
    flat: {},
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    isFetching: false,
    flats: []
}

const flatsReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case FLATS_PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case FLATS_PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case FLATS_TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case GET_FLATS:
            return {
                ...state,
                flats: action.value
            }
        case SET_FLAT:
            return {
                ...state,
                flat: action.value
            }
        case FLATS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setFlat = (value) => ({type: SET_FLAT, value: value})
export const getFlats = (value) => ({type: GET_FLATS, value: value})
export const setFlatsPageChanged = (value) => ({type: FLATS_PAGE_CHANGED, value: value})
export const setFlatsPageSizeChanged = (value) => ({type: FLATS_PAGE_SIZE_CHANGED, value: value})
export const setFlatsTotalCountChanged = (value) => ({type: FLATS_TOTAL_COUNT_CHANGED, value: value})
export const flatsToggleIsFetching = (isFetching) => ({type: FLATS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestFlats = (queryPageIndex, queryPageSize, locationSearch) => {
    return async (dispatch) => {
        dispatch(flatsToggleIsFetching(true))
        dispatch(setFlatsPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await FlatApi.getFlats(offset, queryPageSize, locationSearch)
        if (response !== undefined) {
            dispatch(setFlatsPageSizeChanged(queryPageSize))
            dispatch(setFlatsTotalCountChanged(response.count))
            dispatch(getFlats(response.results))
            dispatch(flatsToggleIsFetching(false))
        }
    }
}


export const getFlat = (flatId) => {
    return (dispatch) => {
        FlatApi.getFlat(flatId).then(data => {
            dispatch(setFlat(data))
        });
    }
}

export default flatsReducer
