import {createSelector} from "reselect";

const getMettershistSelector = (state) => {
    return state.mettershist.mettershist
}

export const getMettershist = createSelector(getMettershistSelector, (mettershist) => {
    return mettershist;
})

export const getMettershistQueryPageIndex = (state) => {
    return state.mettershist.queryPageIndex
}

export const getMettershistQueryPageSize = (state) => {
    return state.mettershist.queryPageSize
}

export const getMettershistTotalCount = (state) => {
    return state.mettershist.totalCount
}

export const getMettershistIsFetching = (state) => {
    return state.mettershist.isFetching
}

export const getMettershistReport = (state) => {
    return state.mettershist.mettershistreport
}
