import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {requestHouseMeters} from "../../../redux/house-meters-reducer";
import {getHouseMeters} from "../../../redux/house-meters-selectors";
import {requestDelMeter} from "../../../redux/house-reducer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";



function HouseMetters({role, house, open,  handleEdit, handleClose}) {
    const houseMetters = useSelector(getHouseMeters)
    const [isDeleted, setIsDeleted] = useState(false);

    const handleDelete = (id) => {
        requestDelMeter(house.id, id);
        setIsDeleted(!isDeleted);
     }

    const dispatch = useDispatch()

    useEffect( () => {
         dispatch(requestHouseMeters(house.id))
     }, [open, isDeleted, house])

     return (
        <>
            {houseMetters && houseMetters.map((p) => (
                <MDBox mb={1} justifyContent="space-between" key={p.id}>
                    { role === 'admin' && (
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox>
                                <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleEdit(p)} >
                                    <Tooltip title={'Редагувати'} placement="top">
                                        <Icon>edit</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                            <MDBox >
                                <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleDelete(p.id)} >
                                    <Tooltip title={'Видалити'} placement="top">
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                        </MDBox>

                    )}
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            { p.name  + ' № ' + p.factory_number }
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            { p.device_name }
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            { 'Дата встановлення: ' + p.start_date }
                        </MDTypography>
                    </MDBox>
                    <Divider  />
                </MDBox>
            ))}
        </>
    );

};

export default HouseMetters;
