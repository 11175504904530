import {getUser} from "redux/auth-selectors";
import { useSelector} from "react-redux";

import Icon from "@mui/material/Icon";
import HouseIcon from '@mui/icons-material/House';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import PaymentsIcon from '@mui/icons-material/Payments';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

import Dashboard from "layouts/dashboard";
import UserDashboard from "layouts/dashboard/UserDashboard";
import AnalyticsDashboard from "layouts/dashboard/AnalyticsDashboard"
import {AnalyticsUserDashboardNew} from "layouts/dashboard/AnalyticsUserDashboardNew"
import {Profiles} from "layouts/profiles/Profiles";
import {Accounts} from "layouts/accounts/Accounts";
import Houses from "layouts/houses/Houses";
import {Flats} from "layouts/flats/Flats";
import OsbbInfo  from "components/OsbbInfo/OsbbInfo";
import MeetingsDocuments from "components/Meetings/MeetingsDocuments";
import {Payments} from "layouts/payments/Payments";
import {MettersData} from "layouts/mettersdata/MettersData";
import {MettersDataNew} from "layouts/mettersdata/MettersDataNew";
import PollsAdmin from "./components/Polls/PollsAdmin";
import RatesAdmin from "./components/Rates/RatesAdmin";
import News from './layouts/news/News';

const AllRoutes = {
  'admin': [
    {
    type: "collapse",
    name: "Новини",
    key: "news",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/news",
    component: <News/>,
    private: true,
  },
  {
    type: "collapse",
    name: "Контакти ОСББ",
    key: "osbb",
    icon: <MapsHomeWorkIcon/>,
    route: "/osbb",
    component: <OsbbInfo />,
    private: true,
  },
  {
    type: "collapse",
    name: "Аналітичні дані по розрахунках за послуги",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <AnalyticsDashboard />,
    private: true,
  },
  {
    type: "collapse",
    name: "Квартири / Особові рахунки",
    key: "accounts",
    icon: <ApartmentIcon/>,
    route: "/accounts",
    component: <Accounts />,
    private: true,
  },
  {
    type: "collapse",
    name: "Будинки",
    key: "houses",
    icon: <HouseIcon/>,
    route: "/houses",
    component: <Houses />,
    private: true,
  },
 {
    type: "collapse",
    name: "Користувачі сервісу(профілі)",
    key: "profiles",
    icon: <PeopleIcon/>,
    route: "/profiles",
    component: <Profiles />,
    private: true,
  },
  {
    type: "collapse",
    name: "Адміністрування новин",
    key: "pollsadmin",
    icon: <PollOutlinedIcon/>,
    route: "/pollsadmin",
    component: <PollsAdmin/>,
    private: true,
  },
  {
    type: "collapse",
    name: "Квартири / лічильники",
    key: "mettersdatanew",
    icon: <EventNoteIcon/>,
    route: "/mettersdatanew",
    component: <MettersDataNew />,
    private: true,
  },
  {
    type: "collapse",
    name: "Історія передачі показань ",
    key: "mettersdata",
    icon: <EventNoteIcon/>,
    route: "/mettersdata",
    component: <MettersData />,
    private: true,
  },

  {
    type: "collapse",
    name: "Тарифи по послугах",
    key: "ratesadmin",
    icon: <PollOutlinedIcon/>,
    route: "/ratesadmin",
    component: <RatesAdmin/>,
    private: true,
  },
  {
    type: "collapse",
    name: "Протоколи зборів (правління/загальних) ",
    key: "meetings",
    icon: <PollOutlinedIcon/>,
    route: "/meetings",
    component: <MeetingsDocuments/>,
    private: true,
  },
],
  'admin2': [{
            type: "collapse",
            name: "Панель",
            key: "dashboard",
            icon: <Icon fontSize="small">dashboard</Icon>,
            route: "/dashboard",
            component: <Dashboard />,
            private: true,
          },
          {
            type: "collapse",
            name: "Контакти мого ОСББ",
            key: "osbb",
            icon: <MapsHomeWorkIcon/>,
            route: "/osbb",
            component: <OsbbInfo />,
            private: true,
          },
          {
            type: "collapse",
            name: "Квартири / Особові рахунки",
            key: "accounts",
            icon: <ApartmentIcon/>,
            route: "/accounts",
            component: <Accounts />,
            private: true,
          },
          {
            type: "collapse",
            name: "Будинки",
            key: "houses",
            icon: <HouseIcon/>,
            route: "/houses",
            component: <Houses />,
            private: true,
          },
          {
            type: "collapse",
            name: "Квартири",
            key: "flats",
            icon: <Icon fontSize="small">person</Icon>,
            route: "/flats",
            component: <Flats />,
            private: true,
          },
         {
            type: "collapse",
            name: "Профілі",
            key: "profiles",
            icon: <PeopleIcon/>,
            route: "/profiles",
            component: <Profiles />,
            private: true,
          },
          {
            type: "collapse",
            name: "Голосування",
            key: "polls",
            icon: <PollOutlinedIcon/>,
            route: "/pollsadmin",
            component: <PollsAdmin/>,
            private: true,
          },
          {
            type: "collapse",
            name: "Історія передачі показань лічильників",
            key: "mettersdata",
            icon: <EventNoteIcon/>,
            route: "/mettersdata",
            component: <MettersData />,
            private: true,
          },
          {
            type: "collapse",
            name: "Тарифи",
            key: "rates",
            icon: <PollOutlinedIcon/>,
            route: "/ratesadmin",
            component: <RatesAdmin/>,
            private: true,
          },
      ],
  'default2': [{
            type: "collapse",
            name: "Панель",
            key: "dashboard",
            icon: <Icon fontSize="small">dashboard</Icon>,
            route: "/dashboard",
            component: <UserDashboard />,
            private: true,
           },
           {
            type: "collapse",
            name: "Контакти мого ОСББ",
            key: "osbb",
            icon: <MapsHomeWorkIcon/>,
            route: "/osbb",
            component: <OsbbInfo />,
            private: true,
           },
           {
            type: "collapse",
            name: "Мої особові рахунки",
            key: "accounts",
            icon: <ApartmentIcon/>,
            route: "/accounts",
            component: <Accounts />,
            private: true,
           },
          {
            type: "collapse",
            name: "Історія передачі показань лічильників",
            key: "mettersdata",
            icon: <EventNoteIcon/>,
            route: "/mettersdata",
            component: <MettersData />,
            private: true,
          },
/*           {
            type: "collapse",
            name: "Сканування лічильника",
            key: "homemeters_scans",
            icon: <Icon fontSize="small">person</Icon>,
            route: "/",
            component: <></>,
            private: true,
          }, */
          {
            type: "collapse",
            name: "Довідки про платежі",
            key: "payments",
            icon: <PaymentsIcon/>,
            route: "/payments",
            component: <Payments />,
            private: true,
          },
         /* {
            type: "collapse",
            name: "Мій профіль",
            key: "profile",
            icon: <Icon fontSize="small">person</Icon>,
            route: "/",
            component: <></>,
            private: true,
          },*/
       ],
  'default': [
        {
          type: "collapse",
          name: "Новини",
          key: "news",
          icon: <Icon fontSize="small">dashboard</Icon>,
          route: "/news",
          component: <News/>,
          private: true,
        },
      {
        type: "collapse",
        name: "Аналітичні дані по розрахунках за послуги",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <AnalyticsUserDashboardNew/>,
        private: true,
       },
       {
        type: "collapse",
        name: "Контакти ОСББ",
        key: "osbb",
        icon: <MapsHomeWorkIcon/>,
        route: "/osbb",
        component: <OsbbInfo />,
        private: true,
       },
       {
        type: "collapse",
        name: "Мої особові рахунки",
        key: "accounts",
        icon: <ApartmentIcon/>,
        route: "/accounts",
        component: <Accounts />,
        private: true,
       },
       {
        type: "collapse",
        name: "Квартири / лічильники",
        key: "mettersdatanew",
        icon: <EventNoteIcon/>,
        route: "/mettersdatanew",
        component: <MettersDataNew />,
        private: true,
      },
      {
        type: "collapse",
        name: "Історія передачі показань лічильників",
        key: "mettersdata",
        icon: <EventNoteIcon/>,
        route: "/mettersdata",
        component: <MettersData />,
        private: true,
      },
      {
        type: "collapse",
        name: "Довідки про платежі",
        key: "payments",
        icon: <PaymentsIcon/>,
        route: "/payments",
        component: <Payments />,
        private: true,
      },
      {
        type: "collapse",
        name: "Будинки",
        key: "houses",
        icon: <HouseIcon/>,
        route: "/houses",
        component: <Houses />,
        private: true,
      },
   ],
};

export default function UserRoutes() {
    const currentUser = useSelector(getUser)

    let role = ( currentUser && currentUser.role && AllRoutes[currentUser.role] ) ? currentUser.role :'default'
    return AllRoutes[role]
}
