import {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getRates, getRatesIsFetching} from "../../redux/rates-selectors";
import {requestAllRates} from "../../redux/rates-reducer";
import Loader from "../Loader/Loader";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import { ImageList } from '@material-ui/core'
import { ImageListItem }  from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import RatesOptions from './RatesOptions';
import RatesForm from "./RatesForm";

const styles = theme => ({
    root: {
       display: 'flex',
       flexWrap: 'wrap',
       //justifyContent: 'space-around',
       overflow: 'hidden',
       //backgroundColor: theme.palette.background.paper,
       'line-height': 1,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: '#1a73e8',//theme.palette.primary.light,
    },
  });

function RatesAdmin(props ) {
    const { classes } = props;
    const rates = useSelector(getRates)
    const isRateFetching = useSelector(getRatesIsFetching)

    const [isChanged, handleChanged] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const defaultRate = { "name":"", "service":null, "units":null, "sum":"",
                            "start_date": null, "end_date": null, "displayed":false
                        }
    const [editRate, setEditRate] = useState(defaultRate);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestAllRates())
    }, [isChanged, isDeleted])

    const [open, setOpen] = useState(false);

    const handleAdd = () => {
        setOpen(true)
        setEditRate(defaultRate)
    };

    const handleEdit = (rate) => {
        setOpen(true)
        setEditRate(rate)
    };

    const handleClose = () => {
        setOpen(false);
        handleChanged( !isChanged );
    };

     return (
        <DashboardLayout>
            <DashboardNavbar />
            {isRateFetching && (
                <Loader/>
            )}
            {
                !isRateFetching && (
                    <MDBox>
                        <Grid container spacing={3}>
                        <Grid item xs={12} md={12} >
                                <ListSubheader className={classes.title} component="div">Мої тарифи</ListSubheader>
                                <MDTypography className={classes.title} component={Link} to={'#'} variant="h2" onClick={()=>handleAdd()}>
                                    <Tooltip title={'Додати тариф'} placement="top">
                                        <Icon>post_add</Icon>
                                    </Tooltip>
                                </MDTypography>
                                <Grid container spacing={3} >
                                    {rates.map((rate) => (
                                        <Grid item xs={12} md={6} xl={3} key={rate.id}>
                                            <RatesOptions rate={rate} onChange={setIsDeleted} handleEdit={(p)=>handleEdit(p)}/>
                                        </Grid>
                                    ))}
                                </Grid>
                                <RatesForm open={open} handleClose={handleClose} editRate={editRate} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                        </Grid>
                        </Grid>
                    </MDBox>
                )
            }
            <Footer />
        </DashboardLayout>
    );
}

RatesAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(RatesAdmin);
