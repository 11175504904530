import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Link} from "react-router-dom";

export default function Data(accounts,balancedate,role) {

    const Account = ({number, id}) => (
       ( balancedate )?
            (   <MDButton component={Link} to={"/account/" + id + "/" + balancedate.toISOString().slice(0, 10)} size="large" variant="text" color="info">
                    {number}
                </MDButton> )
        :
        (  <MDButton component={Link} to={"/account/"} size="large" variant="text" color="info">
                {number}
            </MDButton>
        )
    );

    const columns_admin = [
        {Header: "Квартира", accessor: "flat", align: "center", width : "max-content", enableSorting: false},
        {Header: "Профіль", accessor: "profile", align: "center", width : "max-content", enableSorting: false},
        {Header: "Особовий рахунок", accessor: "number", align: "left", width : "max-content", enableSorting: false},
        {Header: "Борг", accessor: "balance", align: "center", width : "max-content"},
      ];

      const columns_user = [
        {Header: "Квартира", accessor: "flat", align: "center", width : "max-content", enableSorting: false},
        {Header: "Борг", accessor: "balance", align: "center", width : "max-content"},
      ];

    return {
        columns: ((role === 'admin' && columns_admin )|| (role != 'admin' && columns_user )) ,

        rows: accounts.map((account) => ({
            number: <Account number={account.number} id={account.id}/>,
            start_date: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.start_date}
                </MDTypography>
            ),
            profile: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.profile.first_name} {account.profile.last_name}
                </MDTypography>
            ),
            flat: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.flat.house.title} {account.flat.flat_number}
                </MDTypography>
            ),
            balance: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.debt_sum}
                </MDTypography>
            ),
        })),
    };
}
