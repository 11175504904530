import React, {useEffect} from "react"
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {Link} from "react-router-dom";
import PayDialog from "./PayDialog";
// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";


function Pay( { accountServices, balancedate }) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
    <>
        <Card>
            <MDBox display="block" justifyContent="space-between" px={6}>
                    <MDTypography component={Link} to={"#"} variant="h5" fontWeight="bold" onClick={() => handleClickOpen()}>
                        <MDBox component="img" src={masterCardLogo} alt="master card" width="10%" mr={2} />
                        <MDBox component="img" src={visaLogo} alt="visa card" width="10%" mr={2} />
                        Сплатити послуги
                    </MDTypography>
            </MDBox>
        </Card>
        <PayDialog open={open} balancedate={balancedate} accountServices={accountServices} handleClose={handleClose}  />
    </>
     );
}

export default Pay;
