import {AccountApi} from "../api/api";

const SET_ACCOUNT_SERVICES = "SET_ACCOUNT_SERVICES"
const ACCOUNT_SERVICES_TOGGLE_IS_FETCHING = "ACCOUNT_SERVICES_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    services: []
}

const accountServicesReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SET_ACCOUNT_SERVICES:
            return {
                ...state,
                services: action.value
            }
        case ACCOUNT_SERVICES_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setAccountServices = (value) => ({type: SET_ACCOUNT_SERVICES, value: value})
export const accountServicesToggleIsFetching = (isFetching) => ({type: ACCOUNT_SERVICES_TOGGLE_IS_FETCHING, value: isFetching})

export const requestAccountServices = (accountId, balanceDate) => {
    return async (dispatch) => {
        dispatch(accountServicesToggleIsFetching(true))
        let response = await AccountApi.getAccountServices(accountId, balanceDate.toISOString().slice(0, 10))
        if (response !== undefined) {
            dispatch(setAccountServices(response.results))
            dispatch(accountServicesToggleIsFetching(false))
        }
    }
}

export default accountServicesReducer
