import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DialogProfileChat from "../ProfileChat/DialogProfileChat";
import OsbbInfoEdit from './OsbbInfoEdit';
import OsbbDocuments from "./OsbbDocuments";
import DocEditForm from "./AddDocuments"
import PositionsEditForm from "./PositionsEditForm"
import {getUser} from "../../redux/auth-selectors";
import { getOSBBSel, getOsbbDocsSel, getOsbbPositionsSel } from "../../redux/osbb-selectors";
import {getOsbb, getOsbbPositions, requestDelPositions, getOsbbDocs} from "../../redux/osbb-reducer";


function OsbbInfo() {
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isPositionChanged, setIsPositionChanged] = useState(false);
    const [profile, setProfile] = useState(null);

    const [parentState, setParentState] = useState(false);

    const osbb = useSelector(getOSBBSel)
    const positions = useSelector(getOsbbPositionsSel)
    const osbbdocs = useSelector(getOsbbDocsSel)

    const currentUser = useSelector(getUser)
    const [role, setRole] = useState((currentUser && currentUser.role) ? currentUser.role :'default')

    const [openAddDocs, setOpenAddDocs] = useState(false);
    const [editDocs, seteditDocs] = useState({ "name": '',});

    const [openPosEdit, setOpenPosEdit] = useState(false);
    const [editPosInfo, setEditPositionInfo] = useState({ "name": '',"description": '' });


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOsbb())
    }, [openEdit])

    useEffect(() => {
        dispatch(getOsbbPositions())
    }, [isPositionChanged, openPosEdit])

    useEffect(() => {
        dispatch(getOsbbDocs('establishment_documents'))
    }, [parentState])

    if(!osbb) {
        return <div></div>
    }
    const info = {}
    info['Керівник'] = osbb.head ? osbb.head.last_name + ' ' + osbb.head.first_name + ' ' + osbb.head.second_name + ', тел. ' + osbb.head.phone1 + ' ' + osbb.head.email : '-'
    info['Бухгалтер'] = osbb.accountant ? osbb.accountant.last_name + ' ' + osbb.accountant.first_name + ' ' + osbb.accountant.second_name  + ', тел. ' + osbb.accountant.phone1 + ' ' + osbb.accountant.email : '-'
    info['Юридична адреса'] = osbb.legal_address
    info['Розрахунковий рахунок'] = osbb.current_account
    info['ЄДРПОУ'] = osbb.okpo
    // info['МФО'] = osbb.mfo
    info['Телефон'] = osbb.phone

    const handleClickOpen = (profile) => {
        if (profile.user) {
            setProfile(profile)
            setOpen(true)
        }
    };
    const handleClose = () => {setOpen(false);};

    const handleEditOsbbInfo = () => {setOpenEdit(true);};
    const handleCloseEditOsbbInfo = () => {setOpenEdit(false);};

    const handleAddDocs = () => {setOpenAddDocs(true); };
    const handleCloseAddDocs = () => {
        changeParentState();
        setOpenAddDocs(false);
    };

    const changeParentState = () => {
        // Вызывается после закрытия/изменения дочерней компоненты
        setParentState(!parentState);
    };

    const handleOpenPosEdit = (p) => {
        if ( p !== undefined ) {
            setEditPositionInfo(p);
        }
        setOpenPosEdit(true);
    };

    const handleClosePosEdit = () => {
        setOpenPosEdit(false);
     };

    const handleDelete = (positionId) => {
        requestDelPositions(positionId);
        setIsPositionChanged(!isPositionChanged);
    }

    return (
        <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
          <MDBox mt={5} mb={3}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Card sx={{ height: "100%" }}>
                        <MDBox
                            mx={2}
                            mt={-1}
                            py={1}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <MDTypography variant="h6" color="white">
                                Юридичні дані
                            </MDTypography>
                            { role === 'admin' && (
                                <MDTypography variant="h6" style={{color: "white" }} component={Link} to={'#'} onClick={handleEditOsbbInfo} >
                                    <Tooltip title={'Редагувати'} placement="top">
                                        <Icon>edit</Icon>
                                    </Tooltip>
                                </MDTypography>
                            )}
                        </MDBox>
                        <ProfileInfoCard
                                    title= { osbb.name }
                                    description=""
                                    info={info}
                                    social={[]}
                                    action={{ route: "", tooltip: "" }}
                                    shadow={true}
                                />
                    </Card>
                    <OsbbInfoEdit open={openEdit} handleClose={handleCloseEditOsbbInfo} osbb={osbb} />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                        <Card sx={{ height: "100%" }}>
                            <MDBox
                                mx={2}
                                mt={-1}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Корисні контакти
                                </MDTypography>
                                { role === 'admin' && (
                                    <MDTypography variant="h6" style={{color: "white" }} component={Link} to={'#'} onClick={handleOpenPosEdit} >
                                    <Tooltip title={'Додати'} placement="top">
                                        <Icon>add</Icon>
                                    </Tooltip>
                                    </MDTypography>
                                )}
                            </MDBox>

                            {positions.map((position) => (
                                    <MDBox mb={1} justifyContent="space-between" pt={1} px={1} key={position.id}>
                                        { role === 'admin' && (
                                            <MDBox display="flex" justifyContent="space-between">
                                                <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleOpenPosEdit(position)} >
                                                    <Tooltip title={'Редагувати'} placement="top">
                                                        <Icon>edit</Icon>
                                                    </Tooltip>
                                                </MDTypography>
                                                <MDBox >
                                                    <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleDelete(position.id)} >
                                                        <Tooltip title={'Видалити'} placement="top">
                                                            <Icon>delete</Icon>
                                                        </Tooltip>
                                                    </MDTypography>
                                                </MDBox>
                                            </MDBox>
                                        )}
                                        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                                            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                                { position.name }
                                            </MDTypography>
                                            { position.profile && position.profile.user && (
                                              <MDTypography component={Link} to={"#"} variant="body2" color="secondary" onClick={() => handleClickOpen(position.profile)}>
                                                <Tooltip placement="top" title=''>
                                                    <Icon>chat</Icon>
                                                </Tooltip>
                                              </MDTypography>
                                            )}
                                        </MDBox>
                                        {position.description && (
                                            <MDBox mb={2} lineHeight={1}>
                                                <MDTypography variant="button" color="text" fontWeight="light">
                                                    {position.description}
                                                </MDTypography>
                                            </MDBox>
                                        ) }
                                        {position.profile && (
                                                <MDTypography variant="h6" color="text" fontWeight="medium">
                                                    {position.profile.first_name} {position.profile.second_name} {position.profile.last_name} <br />
                                                    {position.profile.phone1} <br />
                                                    {position.profile.email}
                                                </MDTypography>
                                        ) }
                                        {!position.profile && (
                                                <MDTypography variant="h6" color="text" fontWeight="medium">
                                                    -
                                                </MDTypography>
                                        ) }
                                        <Divider  />
                                    </MDBox>

                            ))}
                            <PositionsEditForm open={openPosEdit} editInfo={editPosInfo} handleClose={handleClosePosEdit} />
                        </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                        <Card sx={{ height: "100%" }}>
                            <MDBox
                                mx={2}
                                mt={-1}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                Документи
                                </MDTypography>
                                { role === 'admin' && (
                                    <MDTypography variant="h6" style={{color: "white" }} component={Link} to={'#'} onClick={handleAddDocs} >
                                        <Tooltip title={'Додати'} placement="top">
                                            <Icon>add</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                )}
                            </MDBox>
                            <MDBox mb={1} justifyContent="space-between" pt={1} px={1} >
                                <OsbbDocuments role={role} osbbdocs={osbbdocs}  onClose={changeParentState}/>
                            </MDBox>
                            <DocEditForm open={openAddDocs} editDocs={editDocs} handleClose={handleCloseAddDocs} />
                        </Card>
                </Grid>
            </Grid>
            {profile != null && (
               <DialogProfileChat open={open} profile={profile} handleClose={handleClose} />
            )}
          </MDBox>

        <Footer />
      </DashboardLayout>
    );


};

export default OsbbInfo;
