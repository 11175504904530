import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DataTable from "components/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import GoBack from "components/GoBack";
import BalanceDatePicker from "components/BalanceDatePicker/BalanceDatePicker";
import Table from "components/Tables/Table";
import Loader from "components/Loader/Loader";
import AddMeterDataButton from "./AddMeterDataButton"
import {requestHouseMeters} from "../../../redux/house-meters-reducer";
import {getHouseMeters} from "../../../redux/house-meters-selectors";
import MetersTableData from "../../flat/data/MetersTableData";
import MettershistTableData from "./../data/MettersHistTableData";
import { requestMettershist } from "../../../redux/mettersdata-reducer";
import {
    getMettershist,
    getMettershistIsFetching,
    getMettershistQueryPageIndex,
    getMettershistQueryPageSize,
    getMettershistTotalCount
} from "../../../redux/mettersdata-selectors";


function HouseMetters({ houseId }) {

     const houseMetters = useSelector(getHouseMeters)
     const mettershist = useSelector(getMettershist)
     const queryPageIndex = useSelector(getMettershistQueryPageIndex)
     const queryPageSize = useSelector(getMettershistQueryPageSize)
     const totalCount = useSelector(getMettershistTotalCount)
     const isFetching = useSelector(getMettershistIsFetching)

     const [selectedDate, setselectedDate] = useState(); // useState(new Date());
     const [serviceType, setServiceType] = useState(0);
     const [flatId, setFlatId] = useState();

     let { selected, columns, rows } = MetersTableData(houseMetters)
     let { columns_meter, rows_meter } = MettershistTableData(mettershist, flatId);

     const handleDateChange = (selectedDate) => {
         setselectedDate(selectedDate);
     };

     const dispatch = useDispatch()

     useEffect( () => {
          dispatch(requestHouseMeters(houseId))
      }, [houseId])

     useEffect(() => {
         if (selected){
             dispatch(requestMettershist(selectedDate, serviceType, queryPageIndex, queryPageSize, selected ? selected.id:undefined))
         }
     }, [selectedDate, selected])


     const onDataChanged = ( queryPageIndex, queryPageSize) => {
         dispatch(requestMettershist(selectedDate, serviceType, queryPageIndex, queryPageSize, selected ? selected.id:undefined))
     }

return (
        <>
            <Grid mb={6} >
                <GoBack/>
            </Grid>
            <Card>
                <MDBox
                    mx={2}
                    mt={-1}
                    py={1}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                >
                    <MDTypography variant="h6" color="white">
                        Прилади обліку
                    </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                    <DataTable
                        table={{columns, rows}}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                        queryPageIndex={0}
                        queryPageSize={1000}
                        totalCount={houseMetters.length}
                    />
                </MDBox>
            </Card>
            {isFetching && (
            <Loader />
            )}
            { !isFetching && selected && (
            <>
                    <Grid container spacing={2}>
{/*                         <Grid item xs={12} md={4} lg={2} >
                            <MDBox pt={1} px={3} >
                                <BalanceDatePicker selecteddate={selectedDate} onChange={handleDateChange} />
                            </MDBox>
                        </Grid> */}
                        <Grid item mt={2} xs={12} md={8} lg={6} >
                            <MDBox pt={1} px={3} >
                                <AddMeterDataButton selectedMeterId = {selected.id} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} >
                            <Table
                                title="Історія передачі показань лічильників"
                                columns={columns_meter}
                                rows={rows_meter}
                                queryPageIndex={queryPageIndex}
                                queryPageSize={queryPageSize}
                                totalCount={totalCount}
                                onChanged={onDataChanged}
                            />
                        </Grid>
                    </Grid>
            </>
        )}
</>
    );

};

export default HouseMetters;
