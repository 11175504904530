import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Table from "components/Tables/Table";
import Loader from "components/Loader/Loader";
import House from "../house/House";
import HouseMetters from "./components/HouseMetters";
import HouseAddInfo from './components/HouseAddInfo';
import HouseMetterEditForm from "./components/HouseMetterEditForm";
import HouseAddInfoEditForm from "./components/HouseAddInfoEditForm";
import HousesTableData from "./data/HousesTableData";
import Layout from "./Layout";
import {requestHouses} from "redux/houses-reducer";
import {getUser} from "redux/auth-selectors";
import {
    getHouses,
    getHousesIsFetching,
    getHousesQueryPageIndex,
    getHousesQueryPageSize,
    getHousesTotalCount
} from "../../redux/houses-selectors";

import {get_HouseAddInfo, requestDelAddInfo} from "../../redux/house-reducer";
import {getHouseAddInfo} from "../../redux/house-selectors";


const Houses = () => {
    const dispatch = useDispatch()
    const currentUser = useSelector(getUser)
    const [role, setRole] = useState((currentUser && currentUser.role) ? currentUser.role :'default')

    const houses = useSelector(getHouses)
    const queryPageIndex = useSelector(getHousesQueryPageIndex)
    const queryPageSize = useSelector(getHousesQueryPageSize)
    const totalCount = useSelector(getHousesTotalCount)
    const isFetching = useSelector(getHousesIsFetching)
    const [openEdit, setOpenEdit] = useState(false);

    let { selected, columns, rows } = HousesTableData(houses);

    useEffect( () => {
        dispatch(requestHouses(queryPageIndex, queryPageSize))
    }, [openEdit])

    const onDataChanged = (queryPageIndex, queryPageSize) => {
        dispatch(requestHouses(queryPageIndex, queryPageSize))
    }


    const handleEdit = () => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const [openEditMetter, setOpenEditMetter] = useState(false);
    const [editInfo, seteditInfo] = useState();
    const handleEditMetter = (meter) => {
        if ( meter !== undefined ) {
            seteditInfo(meter);
        }
        setOpenEditMetter(true);
    };
    const handleCloseEditMetter = () => {
        setOpenEditMetter(false);
    };

    const houseAddinfo = useSelector(getHouseAddInfo)
    const [openEditAddInfo, setOpenEditAddInfo] = useState(false);
    const [editAddInfo, setEditAddInfo] = useState();
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect( () => {
        if ( selected !== undefined ) { dispatch(get_HouseAddInfo(selected.id)) }
        console.log('*** selected', selected)
    }, [selected, openEditAddInfo, isDeleted])

    const handleEditAddInfo = (info) => {
        if ( info !== undefined ) {  setEditAddInfo(info);  }
        setOpenEditAddInfo(true);
    };
    const handleCloseEditAddInfo = () => {
        setOpenEditAddInfo(false);
    };

    const handleDeleteInfo = (id) => {
        requestDelAddInfo(id);
        setIsDeleted(!isDeleted);
     }

    return (
    <Layout>
        {isFetching && (
            <Loader />
        )}

        <Grid container spacing={3}>
            {!isFetching && (
            <Grid item xs={12}>
                    <Table
                        title="Будинки"
                        columns={columns}
                        rows={rows}
                        queryPageIndex={queryPageIndex}
                        queryPageSize={queryPageSize}
                        totalCount={totalCount}
                        onChanged={onDataChanged}
                    />
            </Grid>
            )}
            { selected && (
                <>
                    <Grid item xs={4}>
                        <Card sx={{ height: "100%" }}>
                            <House house={selected} open={openEdit}  handleEdit={handleEdit} handleClose={handleCloseEdit}/>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{ height: "100%" }}>
                            <MDBox display="flex"  justifyContent="space-between" alignItems="center" pt={0} px={2}>
                                    <MDTypography style={{color: '#4babf0', textAlign:"center"}} variant="h6" fontWeight="medium" >
                                        {'Наявні будинкові прилади обліку'}
                                    </MDTypography>
                                    { role === 'admin' && (
                                        <MDTypography style={{color: '#4babf0'}} component={Link} to={'#'} onClick={handleEditMetter}  variant="body2" color="secondary">
                                            <Tooltip title={'Додати'} placement="top">
                                                <Icon>add</Icon>
                                            </Tooltip>
                                        </MDTypography>
                                    )}
                            </MDBox>
                            <HouseMetters role={role} house={selected} open={openEditMetter}  handleEdit={handleEditMetter} handleClose={handleCloseEditMetter} />
                            <HouseMetterEditForm open={openEditMetter}  house={selected} editInfo={editInfo} handleClose={handleCloseEditMetter} />
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{ height: "100%" }}>
                            <MDBox display="flex"  justifyContent="space-between" alignItems="center" pt={0} px={2}>
                                <MDTypography style={{color: '#4babf0'}} variant="h6" fontWeight="medium"  >
                                {'Додаткова інформація щодо утримання будинку'}
                                </MDTypography>
                                { role === 'admin' && (
                                    <MDTypography style={{color: '#4babf0'}} component={Link}  to={'#'} onClick={handleEditAddInfo}  variant="body2" color="secondary">
                                        <Tooltip title={'Додати'} placement="top">
                                            <Icon>add</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                )}
                            </MDBox>
                            <HouseAddInfo role={role} houseAddinfo={houseAddinfo} handleEdit={handleEditAddInfo} handleDelete={handleDeleteInfo}/>
                            <HouseAddInfoEditForm open={openEditAddInfo}  house={selected} editInfo={editAddInfo} handleClose={handleCloseEditAddInfo} />
                        </Card>
                    </Grid>
                </>
            )}
        </Grid>
    </Layout>
    )
}

export default Houses
