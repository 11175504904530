import {useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ServiceSum( { serv, balancedate }) {
    const date = new Date();
    const [isCurrentPeriod, setIsCurrentPeriod] = useState(date.toISOString().slice(0, 10)===balancedate.toISOString().slice(0, 10))

    useEffect(() => {
        setIsCurrentPeriod(date.toISOString().slice(0, 10)===balancedate.toISOString().slice(0, 10))
    }, [balancedate])

    return (
        <MDBox mb={1} >
            <Grid container spacing={2} key = {serv.service}>
                <Grid item xs={12} md={6} lg={2} >
                    <Card sx={{ height: "100%" }}>
                        <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                            <MDBox  lineHeight={1.25}>
                                <MDTypography variant="button" fontWeight="light" color="text" >
                                    {serv.service_name}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Борг на початок періоду"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}} >
                                      {(serv.total_balance_vh? serv.total_balance_vh:0 )}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Нарахування"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}}>
                                    { (!isCurrentPeriod)?
                                        parseFloat(serv.total_accrual).toLocaleString():
                                        '-' }
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Оплати"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}} >
                                    {serv.total_payments_buh}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Борг на кінець періоду"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}} >
                                    {(serv.total_balance_ish? serv.total_balance_ish:0)}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
}


export default ServiceSum;
