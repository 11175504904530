import {PollsApi} from "../api/api";


const SET_POLL = "GET_POLL"
const SET_POLLS = "SET_POLLS"
const POLLS_TOGGLE_IS_FETCHING = "POLLS_TOGGLE_IS_FETCHING";
const POLLS_SET_CHOICES = "POLLS_SET_CHOICES";

let initialState = {
    poll: { "question_text":"",
            "start_date": null,
            "close_date": null,
            "choice_set":[],
            "background_color":"",
            "closed":false
    } ,
    isFetching: false,
    polls: [],
    choices: {}
}

const pollsReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SET_POLL:
            return {
                ...state,
                poll: action.value
            }
        case SET_POLLS:
            return {
                ...state,
                polls: action.value
            }
        case POLLS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}

        case POLLS_SET_CHOICES:
            let choices = state.choices
            choices[action.value.question] = action.value.choice
            return {...state , choices: choices}

        default:
            return state;
    }
}

export const setPoll = (value) => ({type: SET_POLL, value: value})
export const setPolls = (value) => ({type: SET_POLLS, value: value})
export const setChoice = (question, choice) => ({type: POLLS_SET_CHOICES, value: {question, choice}})
export const pollsToggleIsFetching = (isFetching) => ({type: POLLS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestPolls = () => {
    return async (dispatch) => {
        dispatch(pollsToggleIsFetching(true))
        let response = await PollsApi.getPolls()
        if (response !== undefined) {
            response.results.forEach(question => {
                dispatch(setChoice(question.id, question.user_vote))
            });
            dispatch(setPolls(response.results))
            dispatch(pollsToggleIsFetching(false))
        }
    }
}

export const requestAllPolls = (active, completed) => {
    return async (dispatch) => {
        dispatch(pollsToggleIsFetching(true))
        let response = await PollsApi.getPolls(true, active, completed)
        if (response !== undefined) {
            response.results.forEach(question => {
                dispatch(setChoice(question.id, question.user_vote))
            });
            dispatch(setPolls(response.results))
            dispatch(pollsToggleIsFetching(false))
        }
    }
}
export const requestSetChoice = (question, value) => {
    return async (dispatch) => {
        dispatch(pollsToggleIsFetching(true))
        let response = await PollsApi.vote(question, value)
        dispatch(setChoice(question, value))
        dispatch(pollsToggleIsFetching(false))
    }
}

export const requestPoll = (pollId) => {
    return (dispatch) => {
                if(pollId && pollId > 0 ) {
                    PollsApi.getPoll(pollId).then(data => {
                        dispatch(setPoll(data))
                    });
                }
    }
}

export const sendNotification = async (pollId) => {
    try {
        const response = await PollsApi.sendNotification(pollId)
        console.log("*** Успішно відправлено! ", pollId, response)
        return response
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
        return (error);
    }
}

export const saveQuestion = (data) => {
    return async (dispatch) => {
        console.log('*** saveQuestion post', data)
        let response;
        let choice_response;
        let choice_data;
        if(data.id === undefined){
            response = await PollsApi.createQuestion(data)
            for (var key in data.choice_set ) {
                choice_data  = data.choice_set[key]
                choice_data['question'] = response.id
                choice_response = await PollsApi.createQuestionChoice(choice_data)
            };
        } else {
            response = await PollsApi.updateQuestion(data)
            for (var key in data.choice_set ) {
                    choice_data  = data.choice_set[key]
                    choice_data['question'] = response.id
                    if (choice_data.id === undefined)  {
                        choice_response = await PollsApi.createQuestionChoice( choice_data )
                    } else {
                        choice_response = await PollsApi.updateQuestionChoice( choice_data.id, choice_data )
                    }
            };
        }
    }
}

export const saveQuestionChoices = (data) => {
    return async (dispatch) => {
        console.log('*** post', data)
    }
}

export const requestDelQuestion = async (pollId) => {
    try {
        const response = await PollsApi.deleteQuestion(pollId)
        console.log("*** Успішно видалено! ", pollId, response)
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
    }
}

export default pollsReducer
