import React, {useEffect} from "react"
import {requestServiceStats} from "../../../../redux/servicestats-reducer";
import {useDispatch, useSelector} from "react-redux";
import ServiceStatsTableData from "./data/ServiceStatsTableData";
import Table from "../../../../components/Tables/Table";
import Loader from "../../../../components/Loader/Loader";
import MDBox from "components/MDBox";

import {
    getServiceStats,
    getServiceStatsIsFetching,
    getServiceStatsQueryPageIndex,
    getServiceStatsQueryPageSize,
    getServiceStatsTotalCount
} from "../../../../redux/servicestats-selectors";


function ServiceStats( { balancedate, user } ) {

  const  title = "Заборгованість у розрізі послуг(Дт та Кт)"

  const queryPageIndex = useSelector(getServiceStatsQueryPageIndex)
  const queryPageSize = useSelector(getServiceStatsQueryPageSize)
  const totalCount = useSelector(getServiceStatsTotalCount)
  const servicestats = useSelector(getServiceStats)
  const isFetching = useSelector(getServiceStatsIsFetching)

  let { columns, rows } = ServiceStatsTableData(servicestats, user);

  const dispatch = useDispatch()

  useEffect( () => {
      dispatch(requestServiceStats(queryPageIndex, queryPageSize,  balancedate, user))
  }, [ balancedate])

  const onDataChanged = ( queryPageIndex, queryPageSize) => {
      dispatch(requestServiceStats( queryPageIndex, queryPageSize,  balancedate, user))
  }

  return (
  <>
        {isFetching && (
            <Loader />
        )}
        {
        !isFetching && (
            <div>
              <MDBox><br/></MDBox>
              <Table
                    title={title}
                    columns={columns}
                    rows={rows}
                    queryPageIndex={queryPageIndex}
                    queryPageSize={queryPageSize}
                    totalCount={totalCount}
                    onChanged={onDataChanged}
                />
            </div>
          )}
  </>
  );
}

export default ServiceStats;
