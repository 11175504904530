import React from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Stats from "./data/reportsChartData";
import moment from 'moment';

const styles = theme => ({
    root: {
       display: 'flex',
       flexWrap: 'wrap',
       //justifyContent: 'space-around',
       overflow: 'hidden',
       //backgroundColor: theme.palette.background.paper,
       'line-height': 1,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: '#4babf0', //theme.palette.primary.light,
    },
  });

function Charts( props ) {
    const { classes, user } = props;
    const { debt, accruals, payments } = Stats(user);

    return (
    <MDBox py={3}>
            <div className={classes.root} >
                <ListSubheader className={classes.title} component="div">
                    Графіки
                </ListSubheader>
            </div>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                        <ReportsBarChart
                            color="info"
                            title="Борг"
                            description="Заборгованість (поточний рік)"
                            date= {moment(new Date()).format("DD-MM-YYYY HH:mm")}
                            chart={debt}
                        />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                        <ReportsLineChart
                            color="success"
                            title="Нарахування"
                            description={
                            <>
                                "Нарахування (поточний рік)"
                            </>
                            }
                            date= {moment(new Date()).format("DD-MM-YYYY HH:mm")}
                            chart={accruals}
                        />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                        <ReportsLineChart
                            color="dark"
                            title="Оплати"
                            description="Оплати (поточний рік)"
                            date= {moment(new Date()).format("DD-MM-YYYY HH:mm")}
                            chart={payments}
                        />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>

    </MDBox>
  );
}

Charts.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(Charts);
