import MDBox from "../MDBox";
import {useState} from "react";
import {useSelector} from "react-redux";
import {getChatReadyStatus} from "../../redux/chat-selectors";
import MDButton from "../MDButton";

function AddMessageForm({sendMessage, chatUser, currentUser}) {
    const [message, setMessage] = useState('')
    const readyStatus = useSelector(getChatReadyStatus)
    const sendMessageHandler = () => {
        if (!message) {
            return
        }
        sendMessage(message)
        setMessage('')
    }

    return <MDBox>
        <MDBox  pt={2} px={2}>
            <MDBox width={"100%"} component="textarea" className="rcw-input" onChange={(e) => setMessage(e.currentTarget.value)} value={message} />
        </MDBox>
        <MDBox  pt={2} px={2}>
            <MDButton
                className="rcw-send"
                color="dark"
                variant="gradient"
                disabled={readyStatus === 'pending'}
                onClick={sendMessageHandler}
                fullWidth
            >
                <>
                {chatUser.id == currentUser.id && (
                    <>Send to himself</>
                )}
                {chatUser.id != currentUser.id && (
                    <>Send to {chatUser.username}</>
                )}
                </>
            </MDButton>
        </MDBox>
    </MDBox>
}

export default AddMessageForm