
export const getNewMessage = (state) => {
    return state.chat.newMessage
}

export const getChatReadyStatus = (state) => {
    return state.chat.readyStatus
}

export const getChatUser = (state) => {
    return state.chat.chatUser
}
