import {AuthApi} from "../api/api";

let subscribers = {
    'messages-received': [] as MessageReceivedSubscriberType[],
    'status-changed': [] as StatusChangedSubscriberType[]
}

let ws: WebSocket | null = null
type EventsNamesType = 'messages-received' | 'status-changed'

const openHandler = () => {
    notifySubscribersAboutStatus('ready')
}

const errorHandler = () => {
    notifySubscribersAboutStatus('error')
}

const closeHandler = () => {
    console.log('CLOSE WS')
    notifySubscribersAboutStatus('pending')
    setTimeout(createChannel, 3000)
}

const messageHandler = (e: MessageEvent) => {
    const newMessage = JSON.parse(e.data)
    subscribers['messages-received'].forEach(subscriber => subscriber(newMessage))
}

const cleanUp = () => {
    ws?.removeEventListener('close', closeHandler);
    ws?.removeEventListener('message', messageHandler)
    ws?.removeEventListener('open', openHandler);
    ws?.removeEventListener('error', errorHandler)
}

const notifySubscribersAboutStatus = (status: StatusType) => {
    subscribers['status-changed'].forEach(subscriber => subscriber(status))
}

async function createChannel () {
    cleanUp()
    ws?.close();
    const accessToken = await AuthApi.getAccessToken()
    //ws = new WebSocket(`ws://127.0.0.1:9542/ws/chat/all/?token=${accessToken}`);
    ws = new WebSocket(`wss://api-kv.osbb.gerc.ua/ws/chat/all/?token=${accessToken}`);
    notifySubscribersAboutStatus('pending')
    ws.addEventListener('close', closeHandler);
    ws.addEventListener('message', messageHandler)
    ws.addEventListener('open', openHandler)
    ws.addEventListener('error', errorHandler)
}

export const chatApi = {

    async start() {
        await createChannel()
    },

    stop() {
        subscribers['messages-received'] = []
        subscribers['status-changed'] = []
        cleanUp()
        ws?.close()
    },
    subscribe(eventName: EventsNamesType, callback: MessageReceivedSubscriberType | StatusChangedSubscriberType) {
        // @ts-ignore
        subscribers[eventName].push(callback)
        return () => {
            // @ts-ignore
            subscribers[eventName] = subscribers[eventName].filter(s => s !== callback)
        }
    },

    unsubscribe(eventName: EventsNamesType, callback: MessageReceivedSubscriberType | StatusChangedSubscriberType) {
        // @ts-ignore
        subscribers[eventName] = subscribers[eventName].filter(s => s !== callback)
    },
    sendMessage(message: string, user_id: bigint) {
        ws?.send(JSON.stringify({ message: message, to_user: user_id }));
    }
}

type MessageReceivedSubscriberType = (message: ChatMessageType) => void
type StatusChangedSubscriberType = (status: StatusType) => void

export type ChatMessageType = {
    message: string
    userId: number
    userName: string
}

export type StatusType = 'pending' | 'ready' | 'error'