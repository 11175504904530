import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import Checkbox from '@mui/material/Checkbox';
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {uk} from "date-fns/locale";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';
import {requestHouses} from "./../../redux/houses-reducer";
import { requestUnit } from './../../redux/unit-reducer';
import { requestService } from './../../redux/service-reducer';
import {getHouses} from "./../../redux/houses-selectors";
import { getUnit } from './../../redux/unit-selectors';
import { getService } from './../../redux/service-selectors';
import { saveRates } from './../../redux/rates-reducer';



function RatesForm({open, editRate, handleClose}) {
    const dispatch = useDispatch();
    const services = useSelector(getService)
    const units = useSelector(getUnit)
    const houses = useSelector(getHouses)

    useEffect(() => {
        dispatch(requestService())
    }, [])

    useEffect(() => {
        dispatch(requestUnit())
    }, [])

    useEffect( () => {
        dispatch(requestHouses(0, 1000))
    }, [])

    const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        dispatch(
            saveRates({
                "id": values.id,
                "name": values.name,
                "service": values.service,
                "units": values.units,
                "sum": values.sum,
                "start_date": typeof values.start_date.getMonth === 'function' ? values.start_date.toISOString().split('T')[0]:values.start_date,
                "end_date":  values.end_date!=null && typeof values.end_date.getMonth === 'function' ? values.end_date.toISOString().split('T')[0]:values.end_date,
                "displayed": values.displayed,
                "house": values.house !== undefined ? (values.house.id !== undefined ? values.house.id : values.house ) : undefined
            })
        );
       handleClose();
    }
    const handleCloseDialog = () => {
        handleClose();
    };

    const SelectAdapter = props => {
        return(
            <>
                <FormControl sx={{width: "100%" }} size="medium">
                    <Select
                        id={props.id}
                        name={props.input.name}
                        value={props.input.value}
                        displayEmpty
                        onChange={props.input.onChange}
                    >
                        {props.options.map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>{opt.name}{opt.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
            </>
        )
      };
      const SelectAdapter2 = props => {
        return(
            <>
                <FormControl sx={{width: "100%" }} size="medium">
                    <Select
                        id={props.id}
                        name={props.input.name}
                        value={props.input.value.id}
                        displayEmpty
                        onChange={props.input.onChange}
                    >
                        {props.options.map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>{opt.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
            </>
        )
      };
    return (
        <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <DialogTitle id="responsive-dialog-title">
                {editRate.id ? ('Редагувати') : ('Додати')} Тариф
            </DialogTitle>
            <DialogContent>
                {editRate && (
                    <Form
                        onSubmit={onSubmit}
                        mutators={{
                            ...arrayMutators
                        }}
                        validate={values => {
                            const errors = {}
                            if (!values.name) {
                              errors.name = 'Необхідно додати назву'
                            }
                            if (!values.start_date) {
                                errors.start_date = 'Необхідно задати дату початку'
                              }
                              if (!values.sum) {
                                errors.sum = 'Введіть сумму тарифу'
                              }
                              if (!values.service) {
                                errors.service = 'Необхідно обрати послугу'
                              }
                            return errors
                          }}
                        initialValues={editRate}
                        render={({
                                submitError, submitErrors, handleSubmit,
                                form: {mutators: {push, pop}},
                                submitting, pristine, values
                        }) => (
                            <MDBox component="form" onSubmit={handleSubmit}>
                                <Card style={{height: "100%"}}>
                                    <MDBox display='flex' justifyContent="space-between" >
                                        <MDBox style={{ width: "50%"}}>
                                            <MDBox>
                                                <MDTypography variant="body1">Будинок:</MDTypography>
                                                <Field name="house"
                                                        component={SelectAdapter2}
                                                        options={houses}
                                                />
                                            </MDBox>
                                            <MDBox >
                                                <MDTypography variant="body1">Назва:</MDTypography>
                                                <Field name="name">
                                                    {props => (
                                                    <>
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                value={props.input.value} onChange={props.input.onChange}
                                                        />
                                                        {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                    </>
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox>
                                                <MDTypography variant="body1">Послуга:</MDTypography>
                                                <Field name="service"
                                                        component={SelectAdapter}
                                                        options={services}
                                                  />
                                            </MDBox>
                                            <MDBox>
                                                <MDTypography variant="body1">Одиниці:</MDTypography>
                                                <Field name="units"
                                                        component={SelectAdapter}
                                                        options={units}
                                                />
                                            </MDBox>
                                            <MDBox>
                                                <MDTypography variant="body1">Сумма:</MDTypography>
                                                <Field name="sum">
                                                    {props => (<>
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                value={props.input.value}
                                                                onChange={props.input.onChange}/>
                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                    </>
                                                    )}
                                                </Field>
                                            </MDBox>
                                        </MDBox>
                                        <MDBox style={{ width: "50%"}}>
                                            <MDBox pt={0} px={3}  pb={1}>
                                                <MDTypography variant="body1">Дата початку:</MDTypography>
                                                <Field name="start_date">
                                                    {props => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                            <DatePicker
                                                                renderInput={(params) => <TextField {...params} />}
                                                                inputFormat='yyyy-MM-dd'
                                                                closeOnSelect={true}
                                                                value={props.input.value}
                                                                onChange={props.input.onChange}
                                                            />
                                                        {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                        </LocalizationProvider>
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox pt={0} px={3}  pb={1}>
                                                <MDTypography variant="body1">Дата кінця:</MDTypography>
                                                <Field name="end_date">
                                                    {props => (
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                            <DatePicker
                                                                renderInput={(params) => <TextField {...params} />}
                                                                inputFormat="yyyy-MM-dd"
                                                                closeOnSelect={true}
                                                                ampm={false}
                                                                clearable
                                                                value={props.input.value}
                                                                onChange={props.input.onChange}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox pt={0} px={3}  pb={1}>
                                                <MDTypography variant="body1">
                                                    <Field name="displayed">
                                                        {props => (
                                                            <Checkbox defaultChecked={props.input.value}
                                                                    name={props.input.name}
                                                                    onChange={props.input.onChange}
                                                            />
                                                        )}
                                                    </Field> відображати на панелі
                                                </MDTypography>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Card>
                                <MDBox mt={4} mb={1}>
                                    <MDButton variant="gradient" color="info" fullWidth type="submit"
                                              disabled={submitting || pristine}>
                                        Зберегти
                                    </MDButton>
                                </MDBox>
                            </MDBox>

                        )}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                    Закрити
                </MDButton>
            </DialogActions>
        </Dialog>
    );
}

export default RatesForm;
