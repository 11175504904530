import AddMessageForm from "./AddMessageForm";
import {useDispatch, useSelector} from "react-redux";
import {
    getHasMoreMessages,
    getMessages,
    getMessagesOffset,
} from "../../redux/chat-messages-selectors";
import Messages from "components/ProfileChat/Messages";
import {changeChatUser, sendMessage, startMessagesListening, stopMessagesListening} from "../../redux/chat-reducer";
import {useEffect, useState} from "react";
import {getChatUser, getNewMessage} from "../../redux/chat-selectors";
import MDBox from "../MDBox";
import {addNewMessages, requestMessages, requestNextMessages} from "../../redux/chat-messages-reducer";
import {getUser} from "../../redux/auth-selectors";
import MDTypography from "../MDTypography";

function ProfileChat({profile}) {
    const dispatch = useDispatch()
    const chatUser = useSelector(getChatUser)
    const messages = useSelector(state => getMessages(state, chatUser))
    const messageOffset = useSelector(state => getMessagesOffset(state, chatUser))
    const newMessage = useSelector(getNewMessage)
    const hasMoreMessages = useSelector( state => getHasMoreMessages(state, chatUser))
    const currentUser = useSelector(getUser)
    const [newCountMessages, setNewCountMessages] = useState(0);

    useEffect(() => {
        if (chatUser) dispatch(requestMessages(chatUser.id))
    }, [chatUser])

    useEffect(() => {
        if (chatUser) {
            dispatch(startMessagesListening(chatUser.id))
            return () => {
                dispatch(stopMessagesListening(chatUser.id))
            }
        }
    }, [chatUser])

    useEffect(() => {
        if (newMessage != null && newMessage.type === "chat_message") {
            if( newMessage.target === currentUser.id || newMessage.user.id === currentUser.id) {
                if (newMessage.target === chatUser.id || newMessage.user.id === chatUser.id) {
                    dispatch(addNewMessages(chatUser.id, newMessage))
                } else {
                    setNewCountMessages(newCountMessages +1)
                }
            }
        }
    }, [newMessage])

    const changeChatWithUser = (user) => {
        setNewCountMessages(0)
        dispatch(changeChatUser(user))
    }

    const onFetchNextMessagesHandler = () => {
        dispatch(requestNextMessages(chatUser.id, messageOffset))
    }

    const onSendMessage = (message) => {
        dispatch(sendMessage(message, chatUser.id))
    }

    return (
        <>
        {chatUser && (
          <MDBox>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  messages
              </MDTypography>
              <Messages
                title="conversations"
                messages={messages}
                nextMessagesHandler={onFetchNextMessagesHandler}
                hasMoreMessages={hasMoreMessages}
                profile={profile}
                chatUser={chatUser}
                changeChatWithUser={changeChatWithUser}
                newMessages={newCountMessages}
                shadow={false} />
            <AddMessageForm sendMessage={onSendMessage} chatUser={chatUser} currentUser={currentUser} />
          </MDBox>
        )}
        </>
    )
}

export default ProfileChat