import {OsbbApi} from "../api/api";

const SET_RATES = "SET_RATES"
const RATES_TOGGLE_IS_FETCHING = "RATES_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    rates: [],
}

const ratesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RATES:
            return {
                ...state,
                rates: action.value
            }
        case RATES_TOGGLE_IS_FETCHING:
                return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setRates = (value) => ({type: SET_RATES, value: value})
export const ratesToggleIsFetching = (isFetching) => ({type: RATES_TOGGLE_IS_FETCHING, value: isFetching})

export const requestRates = () => {
    return async (dispatch) => {
        dispatch(ratesToggleIsFetching(true))
        let response = await OsbbApi.getRates()
        dispatch(setRates(response.results))
        dispatch(ratesToggleIsFetching(false))
    }
}

export const requestAllRates = () => {
    return async (dispatch) => {
        dispatch(ratesToggleIsFetching(true))
        let response = await OsbbApi.getRates(true)
        dispatch(setRates(response.results))
        dispatch(ratesToggleIsFetching(false))
    }
}

export const requestDelRates = async (Id) => {
    try {
        const response = await OsbbApi.deleteRates(Id)
        console.log("*** Успішно видалено! ", Id, response)
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
    }
}

export const saveRates = (data) => {
    return async (dispatch) => {
        let response;
        if(data.id === undefined){
            response = await OsbbApi.createRates(data)
        } else {
            response = await OsbbApi.updateRates(data)
        }
    }
}

export default ratesReducer
