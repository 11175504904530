import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ProfileEditForm from "../../layouts/profile/ProfileEditForm";
import {requestProfiles} from "../../redux/profiles-reducer";
import { getProfiles } from "../../redux/profiles-selectors";
import { saveOsbbInfo } from './../../redux/osbb-reducer';

function OsbbInfoEdit( { open, handleClose, osbb } ) {
    const dispatch = useDispatch();
    const profiles = useSelector(getProfiles)
    const [errorMsg, setErrorMsg] = React.useState(null);

    const [openEdit, setOpenEdit] = React.useState(false);
    const [profile, setProfile] = React.useState({});

    const handleEdit = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    React.useEffect( () => {
        dispatch(requestProfiles(0, 1000, '1' ))
    }, [openEdit])

    const handleCloseDialog = () => {
        setErrorMsg(null);
        handleClose();
    };

    const handleOnChange = (event) => {
        if (event.target.type === 'checkbox') {
            osbb[event.target.name] = event.target.checked;
        }
        else {
            osbb[event.target.name] = event.target.value;
        }
    };

    const handleSave = () => {
        console.log('*** handleSave', osbb)
        setErrorMsg(null);
        saveOsbbInfo({
                "id": osbb.id,
                "name": osbb.name,
                "okpo":osbb.okpo,
                "mfo": osbb.mfo,
                "legal_address": osbb.legal_address,
                "current_account": osbb.current_account,
                "phone": osbb.phone,
                "accountant": osbb.accountant && osbb.accountant.id,
                "head": osbb.head && osbb.head.id
            } ).then( (res) => {
                console.log('***  res', res);
                if (res.status >= 400) {
                    setErrorMsg(res.status + ' ' + res.statusText);
                } else {
                    handleCloseDialog();
                };
            } );
    };

return (
<>
<Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
    <DialogTitle id="responsive-dialog-title">
        Редагувати юридичні дані
    </DialogTitle>
    <DialogContent>
        { osbb && (
        <Card style={{ height: "100%"}}>
            { errorMsg && (<MDTypography variant="body1" style={{ color:'#FA0000'}}>Сталася помилка - {errorMsg} </MDTypography>) }
            <MDBox display="flex" justifyContent="space-between" >
                <MDBox style={{ width: "70%"}}>
                    <MDBox pt={1} px={2}  pb={1} >
                        <MDTypography variant="body1">Назва:</MDTypography>
                        <MDInput type="text" style={{ width: "100%"}}
                                defaultValue = {osbb.name}
                                name = 'name'
                                onChange={event => handleOnChange(event)}
                                />
                    </MDBox>
                    <MDBox pt={1} px={2}  pb={1} >
                        <MDTypography variant="body1">Юридична Адреса:</MDTypography>
                        <MDInput type="text" style={{ width: "100%"}}
                                defaultValue = {osbb.legal_address}
                                name = 'legal_address'
                                onChange={event => handleOnChange(event)}
                                />
                    </MDBox>
                    <MDBox pt={1} px={2}  pb={1} >
                        <MDTypography variant="body1">Розрахунковий Рахунок:</MDTypography>
                        <MDInput type="text" style={{ width: "100%"}}
                                defaultValue = {osbb.current_account}
                                name = 'current_account'
                                onChange={event => handleOnChange(event)}
                                />
                    </MDBox>
                </MDBox>
                <MDBox  style={{ width: "30%"}}>
                    <MDBox pt={1} px={2}  pb={1} >
                        <MDTypography variant="body1">ЄДРПОУ:</MDTypography>
                        <MDInput type="text" style={{ width: "100%"}}
                                defaultValue = {osbb.okpo}
                                name = 'okpo'
                                onChange={event => handleOnChange(event)}
                                />
                    </MDBox>
{/*                     <MDBox pt={1} px={2}  pb={1} >
                        <MDTypography variant="body1">МФО:</MDTypography>
                        <MDInput type="text" style={{ width: "100%"}}
                                defaultValue = {osbb.mfo}
                                name = 'mfo'
                                onChange={event => handleOnChange(event)}
                                />
                    </MDBox> */}
                    <MDBox pt={1} px={2}  pb={1} >
                        <MDTypography variant="body1">Телефон:</MDTypography>
                        <MDInput type="text" style={{ width: "100%"}}
                                defaultValue = {osbb.phone}
                                name = 'phone'
                                onChange={event => handleOnChange(event)}
                                />
                    </MDBox>
                </MDBox>
            </MDBox>
            <MDBox>
                    <MDBox pt={1} px={2}  pb={1} >
                        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} >
                                <MDTypography variant="body1">Керівник:</MDTypography>
                                <MDTypography variant="body1" color="secondary" component={Link} to={'#'} onClick={handleEdit} >
                                    <Tooltip title={'Додати новий профіль'} placement="top">
                                        <Icon>add</Icon>
                                    </Tooltip>
                                </MDTypography>
                        </MDBox>
                        <Autocomplete style={{ width: "100%"}}
                            value={osbb.head}
                            isOptionEqualToValue={(option) => osbb.head && option.id === osbb.head.id}
                            onChange={(event, newValue) => {osbb.head = newValue ; }}
                            id="id_head"
                            options={profiles}
                            getOptionLabel={(p) => p.last_name+' '+p.first_name+' '+p.second_name}
                            renderInput={(params) => <TextField {...params}  />}
                        />
                    </MDBox>
                    <MDBox pt={1} px={2}  pb={1} >
                        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} >
                                <MDTypography variant="body1">Бухгалтер:</MDTypography>
                                <MDTypography variant="body1" color="secondary" component={Link} to={'#'} onClick={handleEdit} >
                                    <Tooltip title={'Додати новий профіль'} placement="top">
                                        <Icon>add</Icon>
                                    </Tooltip>
                                </MDTypography>
                        </MDBox>
                        <Autocomplete style={{ width: "100%"}}
                            value={osbb.accountant}
                            isOptionEqualToValue={(option) => osbb.accountant && option.id === osbb.accountant.id}
                            onChange={(event, newValue) => { osbb.accountant = newValue; }}
                            id="id_accountant"
                            options={profiles}
                            getOptionLabel={(p) => p.last_name+' '+p.first_name+' '+p.second_name}
                            renderInput={(params) => <TextField {...params}  />}
                        />
                    </MDBox>
            </MDBox>
        </Card>
        )}
    </DialogContent>
    <DialogActions>
        <MDButton variant="gradient" fontWeight="small" color="dark"  onClick={() => handleSave() }>
            Зберегти
        </MDButton>
        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog} >
            Закрити
        </MDButton>
    </DialogActions>
</Dialog>
<ProfileEditForm open={openEdit} handleClose={handleCloseEdit} profile={profile} />
</>
);
};


export default OsbbInfoEdit;
