import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { requestDelDoc } from "../../redux/osbb-reducer";
import DocEditForm from "./AddDocuments"

function OsbbDocuments({role,  osbbdocs, onClose}) {
    const [open, setOpen] = useState(false);
    const [editDocs, setEditDocs] = useState({ "name": '',});

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    const handleEdit = (doc) => {
        setEditDocs(doc);
        setOpen(true);
    }

    const handleDelete = (file) => {
        requestDelDoc(file.id);
        onClose();
    }
     return (
        <>
            {osbbdocs.map((p) => (
                <MDBox mb={1} justifyContent="space-between" key={p.id}>
                    { role === 'admin' && (
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox>
                                <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleEdit(p)} >
                                    <Tooltip title={'Редагувати'} placement="top">
                                        <Icon>edit</Icon>
                                    </Tooltip>
                                </MDTypography>
                                <DocEditForm open={open} editDocs={editDocs} handleClose={handleClose} />
                            </MDBox>
                            <MDBox >
                                <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleDelete(p)} >
                                    <Tooltip title={'Видалити'} placement="top">
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                        </MDBox>

                    )}
                    <MDBox mb={1} lineHeight={1}>
                        Документ :
                        <MDTypography component="a" href={p.file} variant="h6" fontWeight="medium" textTransform="capitalize" color="info">
                          {p.name}
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={1}>
                        Дата :
                        <MDTypography variant="button" color="text" fontWeight="light">
                           {p.data}
                        </MDTypography>
                    </MDBox>
                    <Divider  />
                </MDBox>
            ))}
        </>
    );

};

export default OsbbDocuments;
