import {useEffect, useState} from "react"
import MDButton from "components/MDButton";
import AddMeterDataForm from "./AddMeterDataForm"

const AddMeterDataButton = ({selectedMeterId}) => {
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState({ "amount": 0,});

    const handleOpenAddForm = () => {
        setOpen(true);
    }
    const handleCloseAddForm = () => {
        setOpen(false);
    };

    return (
        <>
            <MDButton variant="outlined" color="info" size="small" onClick={handleOpenAddForm}>
                Надати показання
            </MDButton>
            <AddMeterDataForm open={open} editData={editData} handleClose={handleCloseAddForm} selectedMeterId={selectedMeterId} />
        </>
    )
}

export default AddMeterDataButton;
