import {PaymentsApi} from "../api/api";

let initialState = {
    isFetching: false,
    payments: {}
}

const paymentsReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {

        default:
            return state;
    }
}
export const requestPostPayments = async (data) => {
    try {
        let response = await PaymentsApi.create_payment(data)
        return response
    } catch (error) {
        console.error("*** requestPostPayments Щось пішло не так ! ", error)
        return (error);
    }
}

export default paymentsReducer
