import {AccountApi} from "../api/api";

const SET_DEBTOR = "SET_DEBTOR"
const GET_DEBTORS = "GET_DEBTORS"
const GET_DEBTORS_SUMMARY = "GET_DEBTORS_SUMMARY"
const GET_DEBTOR = "GET_DEBTOR"
const DEBTORS_PAGE_CHANGED = 'DEBTORS_PAGE_CHANGED';
const DEBTORS_PAGE_SIZE_CHANGED = "DEBTORS_PAGE_SIZE_CHANGED";
const DEBTORS_TOTAL_COUNT_CHANGED = "DEBTORS_TOTAL_COUNT_CHANGED";
const DEBTORS_TOGGLE_IS_FETCHING = "DEBTORS_TOGGLE_IS_FETCHING";

let initialState = {
    debtor: null,
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    debtors_summary: [{"total_debt_sum": '0'}],
    isFetching: false,
    debtors: []
}

const debtorsReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case DEBTORS_PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case DEBTORS_PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case DEBTORS_TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case GET_DEBTORS:
            return {
                ...state,
                debtors: action.value
            }
        case GET_DEBTORS_SUMMARY:
                return {
                    ...state,
                    debtors_summary: action.value
                }
        case GET_DEBTOR:
            return {
                ...state,
                debtor: action.value
            }
        case DEBTORS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        case SET_DEBTOR:
            return {
                ...state,
                debtor: action.value
            }
        default:
            return state;
    }
}

export const getDebtors = (value) => ({type: GET_DEBTORS, value: value})
export const getDebtorsSummary = (value) => ({type: GET_DEBTORS_SUMMARY, value: value})
export const setDebtor = (value) => ({type: SET_DEBTOR, value: value})
export const setDebtorsPageChanged = (value) => ({type: DEBTORS_PAGE_CHANGED, value: value})
export const setDebtorsPageSizeChanged = (value) => ({type: DEBTORS_PAGE_SIZE_CHANGED, value: value})
export const setDebtorsTotalCountChanged = (value) => ({type: DEBTORS_TOTAL_COUNT_CHANGED, value: value})
export const debtorsToggleIsFetching = (isFetching) => ({type: DEBTORS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestDebtors = (queryPageIndex, queryPageSize, debt_sum, balancedate) => {
    return async (dispatch) => {
        dispatch(debtorsToggleIsFetching(true))
        dispatch(setDebtorsPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await AccountApi.getDebtors(offset, queryPageSize, debt_sum, balancedate ? balancedate.toISOString().slice(0, 10) : null)
        if (response !== undefined) {
            dispatch(setDebtorsPageSizeChanged(queryPageSize))
            dispatch(setDebtorsTotalCountChanged(response.count))
            dispatch(getDebtors(response.results))
            dispatch(debtorsToggleIsFetching(false))
        }
    }
}

export const requestDebtorsSummary = ( debt_sum, balancedate) => {
    return async (dispatch) => {

        let response = await AccountApi.getDebtorsSummary(debt_sum, balancedate ? balancedate.toISOString().slice(0, 10) : null)
        if (response !== undefined) {
            dispatch(getDebtorsSummary(response.results))
        }
    }
}

export const getDebtor = (debtorId) => {
    return (dispatch) => {
        AccountApi.getAccount(debtorId).then(data => {
            dispatch(setDebtor(data))
        });
    }
}

export default debtorsReducer
