import React, {useEffect} from "react"
import {requestFlats} from "../../redux/flats-reducer";
import {useDispatch, useSelector} from "react-redux";
import FlatsTableData from "./data/FlatsTableData";
import Table from "../../components/Tables/Table";
import {useLocation} from "react-router-dom";
import {
    getFlats,
    getFlatsIsFetching,
    getFlatsQueryPageIndex,
    getFlatsQueryPageSize,
    getFlatsTotalCount
} from "../../redux/flats-selectors";
import Layout from "./Layout";
import Loader from "../../components/Loader/Loader";

export const Flats = () => {
    const location = useLocation();
    const locationSearch = location.search;
    const queryPageIndex = useSelector(getFlatsQueryPageIndex)
    const queryPageSize = useSelector(getFlatsQueryPageSize)
    const totalCount = useSelector(getFlatsTotalCount)
    const flats = useSelector(getFlats)
    const isFetching = useSelector(getFlatsIsFetching)

    let { columns, rows } = FlatsTableData(flats);

    const dispatch = useDispatch()

    useEffect( () => {
        dispatch(requestFlats(queryPageIndex, queryPageSize, locationSearch))
    }, [locationSearch])

    const onDataChanged = (queryPageIndex, queryPageSize) => {
        dispatch(requestFlats(queryPageIndex, queryPageSize, locationSearch))
    }

    return (
    <Layout>
        {isFetching && (
            <Loader />
        )}
        {!isFetching && (
        <Table
            title="Квартири"
            columns={columns}
            rows={rows}
            profiles={flats}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            totalCount={totalCount}
            onChanged={onDataChanged}
        />)}
    </Layout>
    )
}
