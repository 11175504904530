/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import React from "react";
import MDTypography from "../MDTypography";

function MDMessage({ message, username, time, ...rest }) {
  return (
    <MDBox className="rcw-response" {...rest}>
      <MDBox className="rcw-message-text">
        <MDBox component="p" className="rcw-username">
          {username}
        </MDBox>
        <MDTypography variant="button" fontWeight="medium" >
              {message}
        </MDTypography>
      </MDBox>
      <span className="rcw-timestamp">{time}</span>
    </MDBox>
  );
}

// Setting default values for the props of MDAlert
MDMessage.defaultProps = {
  message: "info",
  username: "",
  time: "",
};

// Typechecking props of the MDAlert
MDMessage.propTypes = {
  message: PropTypes.string,
  username: PropTypes.string,
  time: PropTypes.string,
};

export default MDMessage;
