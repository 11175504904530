import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import icon_user from "assets/images/icon-user.png";

export default function Data(profiles) {

  const Profile = ({ image, profile }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={profile.last_name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="body2" fontWeight="medium">
          {profile.last_name}  {profile.first_name}  {profile.second_name}
        </MDTypography>
        <MDTypography variant="caption">{profile.email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "ПІБ", accessor: "profile", align: "left", width : "max-content" },
      { Header: "Телефон", accessor: "phone", align: "center", width : "max-content" },
      { Header: "Адреса", accessor: "flat_num", align: "center", width : "max-content", enableSorting: false },
      { Header: "Дія", accessor: "action", align: "center", width : "max-content", enableSorting: false},
    ],

    rows: profiles.map((profile) => ({
      profile: <Profile image={icon_user} profile={profile}  />,
      phone: (
        <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
          {profile.phone1}
        </MDTypography>
      ),
      flat_num: (
        <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
          {profile.flat_num}
        </MDTypography>
      ),
      action: (
        <MDButton component={Link} to={"/profile/" + profile.id} size="small" variant="text" color="info">
          Детальніше
        </MDButton>
      ),
    })),
  };
}
