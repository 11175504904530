import MDTypography from "components/MDTypography";

export default function Data(services, balancedate) {
  return {
    columns: [
     // { Header: "Назва", accessor: "name", align: "left" , width : "min-content"},
      { Header: "Послуга", accessor: "service_name", align: "left", width : "auto" },
      { Header: "Дата початку", accessor: "start_date", align: "center" , width : "max-content"},
      { Header: "Дата кінця", accessor: "end_date", align: "center" , width : "max-content"},
     // { Header: "Дата балансу", accessor: "date", align: "center" , width : "max-content"},
      { Header: "борг (+)/ переплата (-)", accessor: "balance_vh", align: "center" , width : "max-content"},
      //{ Header: "Показання лічильника Пепереднє", accessor: "device_amount_start", align: "center", width : "max-content" },
      //{ Header: "Показання лічильника Поточне", accessor: "device_amount_end", align: "center", width : "max-content" },
      //{ Header: "Одиниці", accessor: "units_name", align: "center", width : "max-content" },
      //{ Header: "Кількість", accessor: "amount", align: "center", width : "max-content" },
      { Header: "Вартісь/тариф/розмір", accessor: "tariff", align: "center", width : "max-content" },
      { Header: "Нараховано за " + balancedate.toLocaleDateString('uk-uk', { year:"numeric", month:"numeric"}),
          accessor: "current_accrual_amount", align: "center", width : "max-content" },
      { Header: "Оплата у " + balancedate.toLocaleDateString('uk-uk', { year:"numeric", month:"numeric"}),
          accessor: "current_payments_amount", align: "center", width : "max-content" },
      { Header: "Перерахунок", accessor: "recalculation_amount", align: "center", width : "max-content" },
      { Header: "До сплати", accessor: "balance_ish", align: "center", width : "max-content" },

],
    rows: services.map((service) => ({
      // name: (
      //     <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
      //       {service.name}
      //     </MDTypography>
      // ),
      service_name: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {service.service_firme} - {service.service_name}
          </MDTypography>
      ),
      start_date: (
        <MDTypography component="span" variant="body" color="text" fontWeight="medium">
          {service.start_date}
        </MDTypography>
      ),
      end_date: (
       <MDTypography component="span" variant="body" color="text" fontWeight="medium">
         {service.end_date}
       </MDTypography>
      ),
      date: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {service.date}
          </MDTypography>
      ),
      balance_vh: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {(service.balance_vh).toLocaleString()}
          </MDTypography>
      ),
      device_amount_start: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {service.device_amount_start}
          </MDTypography>
      ),
      device_amount_end: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {service.device_amount_end}
          </MDTypography>
      ),
      // units_name: (
      //     <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
      //       {service.units_name}
      //     </MDTypography>
      // ),
      amount: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {service.amount}
          </MDTypography>
      ),
      tariff: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {Number(service.tariff).toFixed(2)}
          </MDTypography>
      ),
      current_accrual_amount: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {service.current_accrual_amount}
          </MDTypography>
      ),
      current_payments_amount: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {service.current_payments_buh}
          </MDTypography>
      ),
      recalculation_amount: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {service.recalculation_amount}
          </MDTypography>
      ),
      balance_ish: (
        <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
          {service.balance_ish}
        </MDTypography>
    ),
    })),
  };
}
