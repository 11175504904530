import {useState} from "react";
import {useSelector} from "react-redux";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HouseEditForm from "./HouseEditForm"
import {getUser} from "../../redux/auth-selectors";

function House(props) {

    const currentUser = useSelector(getUser)
    const [role, setRole] = useState((currentUser && currentUser.role) ? currentUser.role :'default')

  if(!props.house) {
      return <div></div>
  }

  return (
    <div>
       <MDBox mb={1} justifyContent="space-between" pt={1} px={1} key={ props.house.id}>
                <MDBox display="flex"  justifyContent="space-between" alignItems="center" pt={0} px={2}>
                  <MDTypography variant="h6" style={{color: '#4babf0'}} >
                    Інформація про будинок - {props.house.title}
                  </MDTypography>
                  { role === 'admin' && (
                    <MDTypography style={{color: '#4babf0'}} component={Link} to={'#'} onClick={props.handleEdit}  variant="body2" color="secondary">
                            <Tooltip title={'Редагувати'} placement="top">
                                <Icon>edit</Icon>
                            </Tooltip>
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox  display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="bold" >
                      Вулиця: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      &nbsp;{ props.house.street_name }
                    </MDTypography>
                </MDBox>
                <MDBox  display="flex" py={1} pr={2}>
                    <MDBox  display="flex"  py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                          Номер будинку: &nbsp; &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {props.house.house_number}
                        </MDTypography>
                    </MDBox>
                    <MDBox  display="flex"  py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                        Дріб: &nbsp; &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                        {props.house.house_drob}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox  display="flex" >
                    <MDBox  display="flex"  py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                        Корпус/секція: &nbsp;&nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {props.house.house_corps}
                        </MDTypography>
                    </MDBox>
                    <MDBox  display="flex"  py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                          Буква: &nbsp;&nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          {props.house.house_letter}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox  display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="bold" >
                    Поштовий індекс: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {props.house.postcode}
                    </MDTypography>
                </MDBox>
                <MDBox  display="flex" >
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                            Загальна площа будинку: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.total_square}
                        </MDTypography>
                    </MDBox>
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                          Площа загального користування: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.common_square}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox  display="flex" >
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                            Площа корисна: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.polezn_square}
                        </MDTypography>
                    </MDBox>
                    <MDBox  display="flex" py={1} pr={2} ml={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                            Площа жила: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.live_square}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox  display="flex" >
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                          Прибудинкова територія: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.adjacent_square}
                        </MDTypography>
                    </MDBox>
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                            Паркінг площа: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.parking_square}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox  display="flex" >
                    <MDTypography variant="button" fontWeight="bold" >
                      Кількість квартир фізичних осіб: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      {props.house.flats_number}
                    </MDTypography>
                </MDBox>
                <MDBox  display="flex" >
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                            Кількість поверхів: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.floors_number}
                        </MDTypography>
                    </MDBox>
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                            Кількість підїздів: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.entrances_number}
                        </MDTypography>
                    </MDBox>
                    <MDBox  display="flex" py={1} pr={2}>
                        <MDTypography variant="button" fontWeight="bold" >
                            Кількість ліфтів: &nbsp;
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {props.house.elevators_number}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox  display="flex" py={1} pr={2}>

                </MDBox>
       </MDBox>
       <HouseEditForm open={props.open} editInfo={props.house} handleClose={props.handleClose} />
    </div>
  );
}

export default House;
