import {createSelector} from "reselect";

const getServiceTypesSelector = (state) => {
    return state.servicetypes.servicetypes
}

export const getServiceTypes = createSelector(getServiceTypesSelector, (servicetypes) => {
    return servicetypes;
})

export const getServiceTypesIsFetching = (state) => {
    return state.servicetypes.isFetching
}
