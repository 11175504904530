import React, {useEffect, useState} from "react"
import {requestAccounts} from "../../redux/accounts-reducer";
import {useDispatch, useSelector} from "react-redux";
import AccountsTableData from "./data/AccountsTableData";
import Table from "../../components/Tables/Table";
import {useLocation} from "react-router-dom";

import {
    getAccounts,
    getAccountsIsFetching,
    getAccountsQueryPageIndex,
    getAccountsQueryPageSize,
    getAccountsTotalCount
} from "../../redux/accounts-selectors";
import Layout from "./Layout";
import Loader from "../../components/Loader/Loader";

export const Accounts = () => {
    const location = useLocation();
    const locationSearch = location.search;
    const queryPageIndex = useSelector(getAccountsQueryPageIndex)
    const queryPageSize = useSelector(getAccountsQueryPageSize)
    const totalCount = useSelector(getAccountsTotalCount)
    const accounts = useSelector(getAccounts)
    const isFetching = useSelector(getAccountsIsFetching)

    let { columns, rows } = AccountsTableData(accounts);

    const dispatch = useDispatch()

    useEffect( () => {
        dispatch(requestAccounts(queryPageIndex, queryPageSize, locationSearch))
    }, [ locationSearch])

    const onDataChanged = (queryPageIndex, queryPageSize, locationSearch) => {
        dispatch(requestAccounts(queryPageIndex, queryPageSize, locationSearch))
    }

    return (
    <Layout>
        {isFetching && (
            <Loader />
        )}
        {!isFetching && (
        <Table
            title="Особові рахунки"
            columns={columns}
            rows={rows}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            totalCount={totalCount}
            onChanged={onDataChanged}
        />)}
    </Layout>
    )
}

