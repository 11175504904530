import React, {useEffect} from "react"
import Grid from "@mui/material/Grid";
import {requestHouses} from "../../../redux/houses-reducer";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../../components/Tables/Table";
import HousesTableData from "./../data/HousesTableData";

import {
    getHouses,
    getHousesIsFetching,
    getHousesQueryPageIndex,
    getHousesQueryPageSize,
    getHousesTotalCount
} from "../../../redux/houses-selectors";
import Loader from "../../../components/Loader/Loader";


const Houses = () => {
    const queryPageIndex = useSelector(getHousesQueryPageIndex)
    const queryPageSize = useSelector(getHousesQueryPageSize)
    const houses = useSelector(getHouses)
    const isFetching = useSelector(getHousesIsFetching)
    const totalCount = useSelector(getHousesTotalCount)

    let { selected, columns, rows } = HousesTableData(houses);

    const dispatch = useDispatch()

    useEffect( () => {
        dispatch(requestHouses(queryPageIndex, queryPageSize))
    }, [])

    const onDataChanged = (queryPageIndex, queryPageSize) => {
        dispatch(requestHouses(queryPageIndex, queryPageSize))
    }

    return (
        <>
            {isFetching && (
                <Loader />
            )}

            <Grid container spacing={3}>
                {!isFetching && (
                <Grid item xs={12}>
                        <Table
                            title="Будинки"
                            columns={columns}
                            rows={rows}
                            queryPageIndex={queryPageIndex}
                            queryPageSize={queryPageSize}
                            totalCount={totalCount}
                            onChanged={onDataChanged}
                        />
                </Grid>
                )}

            </Grid>
        </>
    )
}

export default Houses;
