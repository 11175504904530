import {createSelector} from "reselect";

const getProfilesSelector = (state) => {
    return state.profilesPage.profiles
}

export const getProfiles = createSelector(getProfilesSelector, (profiles) => {
    return profiles;
})

export const  getQueryPageIndex = (state) => {
    return state.profilesPage.queryPageIndex
}

export const getQueryPageSize = (state) => {
    return state.profilesPage.queryPageSize
}

export const  getTotalCount = (state) => {
    return state.profilesPage.totalCount
}

export const getIsFetching = (state) => {
    return state.profilesPage.isFetching
}
