import React, {useEffect} from "react"
import ProfileChat from "./ProfileChat";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {useDispatch} from "react-redux";
import {changeChatUser} from "../../redux/chat-reducer";

function DialogProfileChat({open, profile, handleClose}) {
    const dispatch = useDispatch()
    useEffect(() => {
        if (profile && profile.user) {
            dispatch(
                changeChatUser(
                    {
                        id: profile.user,
                        username: profile.first_name + " " + profile.last_name
                    }
                )
            )
        }
    }, [profile])

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogContent>
                <ProfileChat profile={profile}/>
            </DialogContent>
        </Dialog>
    )
}

export default DialogProfileChat;
