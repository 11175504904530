import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Checkbox from '@mui/material/Checkbox';

export default function Data(services, handleOnCheckboxClick, handleOnInputChange) {

  return {
    columns: [
      { Header: " ", accessor: "service_checkbox", align: "center", width : "min-content" },
      { Header: "Послуга", accessor: "service_name", align: "left", width : "fit-content" },
      { Header: "Баланс на кінець періоду", accessor: "balance_ish", align: "center", width : "max-content" },
      { Header: "До сплати", accessor: "pay_sum", align: "right", width : "max-content" },
],
    rows: services.map((service) => ({
      service_checkbox: (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          <Checkbox
            onChange={(event) => {  handleOnCheckboxClick(service.id, event.target.checked) }}
          />
        </MDTypography>
      ),
      service_name: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {service.service_name}
          </MDTypography>
      ),
      balance_ish: (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {service.balance_ish}
        </MDTypography>
    ),
    pay_sum: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        <MDInput
                type="number"
                defaultValue = {service.balance_ish>0 ? service.balance_ish : 0}
                size="small"
                inputProps={{ min:"0" }}
                onChange = {(event) => { handleOnInputChange(service.id, event.target.value)}}
        />
      </MDTypography>
  ),
    })),
  };
}
