import {FinanceApi} from "../api/api";

const SET_SERVICETYPES = "SET_SERVICETYPES"
const SERVICETYPES_TOGGLE_IS_FETCHING = "SERVICETYPES_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    servicetypes: [],
}

const servicetypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SERVICETYPES:
            return {
                ...state,
                servicetypes: action.value
            }
        case SERVICETYPES_TOGGLE_IS_FETCHING:
                return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setServiceTypes = (value) => ({type: SET_SERVICETYPES, value: value})
export const servicetypesToggleIsFetching = (isFetching) => ({type: SERVICETYPES_TOGGLE_IS_FETCHING, value: isFetching})

export const requestServiceTypes = () => {
    return async (dispatch) => {
        dispatch(servicetypesToggleIsFetching(true))
        let response = await FinanceApi.getServiceType()
        dispatch(setServiceTypes(response.results))
        dispatch(servicetypesToggleIsFetching(false))
    }
}

export default servicetypesReducer
