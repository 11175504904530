import {useState} from "react"
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

import Checkbox from '@mui/material/Checkbox';
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import TextField from '@mui/material/TextField';
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {uk} from "date-fns/locale";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {FieldArray} from 'react-final-form-arrays'
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {saveQuestion, sendNotification} from "../../redux/polls-reducer";

import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';


function PollDialog({open, editPoll, handleClose}) {
    //console.log('*** PollDialogForm editPoll ***', editPoll)
    const dispatch = useDispatch();
    const [delChoice, setDelChoice] = useState([]);

    const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        dispatch(
            saveQuestion({
                "id": values.id,
                "question_text": values.question_text,
                "start_date": values.start_date,
                "close_date": values.close_date,
                "background_color": values.background_color,
                "choice_set": values.choice_set,
                "closed": values.closed
            })
        );
        if (delChoice.length > 0) {

        }
        handleClose();
    }
    const handleCloseDialog = () => {
        // console.log("**** handleCloseDialog  ***")
        handleClose();
    };

    const sendNotificationData = () => {
        sendNotification(editPoll.id
            ).then( (res) => {
                console.log('***  res', res);
                if (res.status >= 400) {
                    alert(res.status + ' ' + res.statusText);
                } else {
                    alert('Успішно відправлено!');
                };
            } );
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="lg">
            <DialogTitle id="responsive-dialog-title">
                {editPoll.id ? ('Редагувати') : ('Додати')} оголошення
            </DialogTitle>
            <DialogContent>
                {editPoll && (
                    <Form
                        onSubmit={onSubmit}
                        mutators={{
                            ...arrayMutators
                        }}
                        validate={values => {
                            const errors = {}
                            if (!values.question_text) {
                              errors.question_text = 'Необхідно додати текст'
                            }
                            if (!values.start_date) {
                                errors.start_date = 'Необхідно задати дату'
                              }
                              if (!values.background_color) {
                                errors.background_color = 'Оберіть колір'
                              }
                            return errors
                          }}
                        initialValues={editPoll}
                        render={({
                                submitError, submitErrors, handleSubmit,
                                form: {mutators: {push, pop}},
                                submitting, pristine, values
                        }) => (
                            <MDBox component="form" onSubmit={handleSubmit}>
                                <Card style={{height: "100%"}}>
                                    <MDBox pt={1} px={2} pb={1}>
                                        <MDTypography variant="body1">Текст:</MDTypography>
                                        <Field name="question_text">
                                            {props => (
                                            <>
                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                         value={props.input.value} onChange={props.input.onChange}
                                                         multiline rows={5}/>
                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                            </>
                                            )}
                                        </Field>

                                    </MDBox>
                                    <MDBox pt={1} px={2} pb={1}>
                                        <MDTypography variant="body1">Відповіді:</MDTypography>
                                        <MDTypography style={{color: '#1a73e8'}} component={Link} to={'#'}
                                                      onClick={() => push('choice_set', undefined)}>
                                            <Tooltip title={'Додати ще відповідь'} placement="top">
                                                <Icon>add_circle_outline</Icon>
                                            </Tooltip>
                                        </MDTypography>
                                        <FieldArray name="choice_set">
                                            {({fields}) =>
                                                fields.map((choice, index) => (
                                                    <MDBox pt={1} px={1} pb={1} key={index}>
                                                        <Field name={`${choice}.choice_text`}>
                                                            {props => (
                                                                <MDInput type="text" style={{width: "98%"}}
                                                                         name={props.input.name}
                                                                         value={props.input.value}
                                                                         onChange={props.input.onChange}/>
                                                            )}
                                                        </Field>

                                                        <MDTypography style={{color: '#1a73e8'}} component={Link}
                                                                      to={'#'}
                                                                      onClick={() => {fields.remove(index); if (choice.id) { setDelChoice([...delChoice, choice ]);}} }
                                                        >
                                                            <Tooltip title={'Видалити'} placement="top">
                                                                <Icon>remove_circle_outline</Icon>
                                                            </Tooltip>
                                                        </MDTypography>
                                                    </MDBox>
                                                ))
                                            }
                                        </FieldArray>
                                    </MDBox>
                                    <MDBox display='flex' justifyContent="space-between" pt={1} px={2} pb={1}>
                                        <MDBox>
                                            <MDTypography variant="body1">Колір фону:</MDTypography>
                                            <Field name="background_color">
                                                {props => (<>
                                                    <MDInput type="color" style={{width: "100%"}} name={props.input.name}
                                                             value={props.input.value}
                                                            onChange={props.input.onChange}/>
                                                 {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                 </>
                                                )}
                                            </Field>
                                        </MDBox>
                                        <MDBox>
                                            <MDTypography variant="body1">Дата відкриття:</MDTypography>
                                            <Field name="start_date">
                                                {props => (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale ={uk} >
                                                        <DateTimePicker
                                                            renderInput={(params) => <TextField {...params} />}
                                                            inputFormat='yyyy-MM-dd HH:mm'
                                                            closeOnSelect={true}
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                        />
                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                    </LocalizationProvider>
                                                )}
                                            </Field>

                                        </MDBox>
                                        <MDBox>
                                            <MDTypography variant="body1">Дата закриття:</MDTypography>
                                            <Field name="close_date">
                                                {props => (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale ={uk} >
                                                        <DateTimePicker
                                                            renderInput={(params) => <TextField {...params} />}
                                                            inputFormat="yyyy-MM-dd HH:mm"
                                                            closeOnSelect={true}
                                                            ampm={false}
                                                            clearable
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            </Field>
                                        </MDBox>
                                        <MDTypography variant="body1">
                                            <Field name="closed">
                                                {props => (
                                                    <Checkbox defaultChecked={props.input.value}
                                                              name={props.input.name}
                                                              onChange={props.input.onChange}
                                                    />
                                                )}
                                            </Field> Неактивно
                                        </MDTypography>
                                    </MDBox>
                                </Card>
                                <MDBox mt={4} mb={1}>
                                    <MDButton variant="gradient" color="info" fullWidth type="submit"
                                              disabled={submitting || pristine}>
                                        Зберегти
                                    </MDButton>
                                </MDBox>
                            </MDBox>

                        )}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" fontWeight="small" color="dark" onClick={sendNotificationData}>
                    Відправити на пошту повідомленя про нову об'яву
                </MDButton>
                <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                    Закрити
                </MDButton>
            </DialogActions>
        </Dialog>
    );
}

export default PollDialog;
