import React, {useEffect} from "react"
import {requestPayshist} from "../../redux/paymentshistory-reducer";
import {useDispatch, useSelector} from "react-redux";
import PayshistTableData from "./data/PaymentsTableData";
import Table from "../../components/Tables/Table";

import {
    getPayshist,
    getPayshistIsFetching,
    getPayshistQueryPageIndex,
    getPayshistQueryPageSize,
    getPayshistTotalCount
} from "../../redux/paymentshistory-selectors";
import Layout from "./Layout";
import Loader from "../../components/Loader/Loader";

export const Payments = () => {
    const queryPageIndex = useSelector(getPayshistQueryPageIndex)
    const queryPageSize = useSelector(getPayshistQueryPageSize)
    const totalCount = useSelector(getPayshistTotalCount)
    const payshist = useSelector(getPayshist)
    const isFetching = useSelector(getPayshistIsFetching)

    let { columns, rows } = PayshistTableData(payshist);

    const dispatch = useDispatch()

    useEffect( () => {
        dispatch(requestPayshist(queryPageIndex, queryPageSize))
    }, [])

    const onDataChanged = (queryPageIndex, queryPageSize) => {
        dispatch(requestPayshist(queryPageIndex, queryPageSize))
    }

    return (
    <Layout>
        {isFetching && (
            <Loader />
        )}
        {!isFetching && (
        <Table
            title="Оплати"
            columns={columns}
            rows={rows}
            profiles={payshist}
            queryPageIndex={queryPageIndex}
            queryPageSize={queryPageSize}
            totalCount={totalCount}
            onChanged={onDataChanged}
        />)}
    </Layout>
    )
}
