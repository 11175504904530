import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {uk} from "date-fns/locale";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';
import { saveHouseMeter } from '../../../redux/house-reducer';
import { getService } from 'redux/service-selectors';
import { requestService } from 'redux/service-reducer';
import { getDevice } from 'redux/device-selectors';
import { requestDevice } from 'redux/device-reducer';

function HouseMetterEditForm({open, house, editInfo, handleClose}) {
    const services = useSelector(getService)
    const devices = useSelector(getDevice)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(requestService())
    }, [])

    useEffect(() => {
        dispatch(requestDevice())
    }, [])

    const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        saveHouseMeter({
                "id": values.id,
                "service": values.service,
                "name": values.name,
                "factory_number": values.factory_number,
                "counter_no": values.counter_no,
                "start_date": values.start_date && typeof values.start_date.getMonth === 'function' ? values.start_date.toISOString().split('T')[0]:values.start_date,
                "end_date": values.end_date && typeof values.end_date.getMonth === 'function' ? values.end_date.toISOString().split('T')[0]:values.end_date,
                "device": values.device,
                "house": house.id,
                "localization": values.localization
            });
        handleClose();
    }
    const handleCloseDialog = () => {
        handleClose();
    };

    const SelectAdapter = props => {
        return(
            <>
                <FormControl sx={{width: "100%" }} size="medium">
                    <Select
                        id={props.id}
                        name={'-'}
                        value={props.input.value}
                        displayEmpty
                        onChange={props.input.onChange}
                    >
                        {props.options.map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>{opt.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
            </>
        )
      };

    return (
        <>
            {true && (
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
                    <DialogTitle id="responsive-dialog-title">
                        Додати/Редагувати прилад обліку
                    </DialogTitle>
                    <DialogContent>
                            <Form
                                onSubmit={onSubmit}
                                mutators={{
                                    ...arrayMutators
                                }}
                                validate={values => {
                                    const errors = {}
                                    return errors
                                }}
                                initialValues={editInfo}
                                render={({submitError, submitErrors,handleSubmit, form, submitting, pristine, values}) => (
                                    <MDBox component="form" onSubmit={handleSubmit}>
                                        <Card style={{height: "100%"}}>
                                                <MDBox >
                                                    <MDBox >
                                                        <MDTypography variant="body1">Послуга:</MDTypography>
{/*                                                         <Field name="service">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field> */}
                                                            <Field name="service"
                                                                    component={SelectAdapter}
                                                                    options={services}
                                                            />

                                                    </MDBox>
                                                    <MDBox >
                                                        <MDTypography variant="body1">Прилад:</MDTypography>
                                                        {/* <Field name="device">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field> */}
                                                            <Field name="device"
                                                                    component={SelectAdapter}
                                                                    options={devices}
                                                            />
                                                    </MDBox>
                                                    <MDBox display='flex' justifyContent="space-between" pt={1} pb={1}>
                                                    <MDBox width="50%">
                                                        <MDTypography variant="body1">Назва:</MDTypography>
                                                        <Field name="name">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDTypography variant="body1">Заводський номер:</MDTypography>
                                                        <Field name="factory_number">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDTypography variant="body1">Номер приладу:</MDTypography>
                                                        <Field name="counter_no">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDTypography variant="body1">Локалізація приладу:</MDTypography>
                                                        <Field name="localization">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox display='flex' justifyContent="space-between" pt={1} pb={1}>
                                                        <MDBox>
                                                            <MDTypography variant="body1">Дата встановлення:</MDTypography>
                                                            <Field name="start_date">
                                                                {props => (
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale ={uk} >
                                                                    <DatePicker
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        inputFormat='yyyy-MM-dd'
                                                                        closeOnSelect={true}
                                                                        value={props.input.value}
                                                                        onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                    </LocalizationProvider>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body1">Дата зняття:</MDTypography>
                                                            <Field name="end_date">
                                                                {props => (
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale ={uk} >
                                                                    <DatePicker
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        inputFormat='yyyy-MM-dd'
                                                                        closeOnSelect={true}
                                                                        value={props.input.value}
                                                                        onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                    </LocalizationProvider>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                    </MDBox>

                                                </MDBox>
                                        </Card>
                                        {submitError && <MDBox display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            color="error" fontSize="small">
                                                        {submitError}
                                                        </MDBox>
                                        }
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" fullWidth type="submit"
                                                    disabled={submitting || pristine}>
                                                Зберегти
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>

                                )}
                            />

                    </DialogContent>
                    <DialogActions>
                        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                            Закрити
                        </MDButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default HouseMetterEditForm;
