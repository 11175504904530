import {createSelector} from "reselect";

const getServiceStatsSelector = (state) => {
    return state.servicestats.servicestats
}

export const getServiceStats = createSelector(getServiceStatsSelector, (servicestats) => {
    return servicestats;
})

export const  getServiceStatsQueryPageIndex = (state) => {
    return state.servicestats.queryPageIndex
}

export const getServiceStatsQueryPageSize = (state) => {
    return state.servicestats.queryPageSize
}

export const  getServiceStatsTotalCount = (state) => {
    return state.servicestats.totalCount
}

export const getServiceStatsIsFetching = (state) => {
    return state.servicestats.isFetching
}
