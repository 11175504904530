import {useState, useEffect  } from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import BalanceDatePicker from "components/BalanceDatePicker/BalanceDatePicker";
import ListSubheader from '@material-ui/core/ListSubheader';
import { getOsbbUserSummary } from "../../redux/summary-reducer";
import { get_OsbbUserSummary } from "../../redux/summary-selectors";

function OsbbUserSummary( { balancedate, onChange }) {
    const dispatch = useDispatch()
    const usersummary = useSelector(get_OsbbUserSummary)
    const [selectedDate, setselectedDate] = useState(balancedate?balancedate:new Date());
    const handleDateChange = (selectedDate) => {
          setselectedDate(selectedDate);
          onChange(selectedDate);
    };
    const date = new Date();
    const [isCurrentPeriod, setIsCurrentPeriod] = useState(date.toISOString().slice(0, 10)===selectedDate.toISOString().slice(0, 10))

    useEffect(() => {
        dispatch(getOsbbUserSummary(selectedDate))
        setIsCurrentPeriod(date.toISOString().slice(0, 10)===selectedDate.toISOString().slice(0, 10))
    }, [selectedDate])


return (  ( usersummary && usersummary.results ) ?
    <div >
      <ListSubheader style={{color: '#4babf0'}} component="div">
        {"Дані по розрахунках "} &nbsp;&nbsp;&nbsp;
        <BalanceDatePicker selecteddate={selectedDate} onChange={handleDateChange} />
      </ListSubheader>
      {usersummary.results.map((s) => (
         <MDBox mb={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={2} >
                    <Card sx={{ height: "100%" }}>
                        <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                            <MDBox  lineHeight={1.25}>
                                <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Рахунок"}
                                </MDTypography>
                                <MDTypography style={{color: '#4babf0'}} >
                                    {s.personal_account__number}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Борг на початок періоду"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}} >
                                    {(s.total_balance_vh? parseFloat(s.total_balance_vh).toLocaleString() : 0 )}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Нарахування"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}}>
                                    { (!isCurrentPeriod)?
                                        parseFloat(s.total_accrual).toLocaleString():
                                        '-' }
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Оплати"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}} >
                                    { parseFloat(s.total_payments_buh).toLocaleString() }
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={2.5} >
                    <Card>
                            <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                                <MDBox  lineHeight={1.25}>
                                    <MDTypography variant="button" fontWeight="light" color="text">
                                    {"Борг на кінець періоду"}
                                    </MDTypography>
                                    <MDTypography style={{color: '#4babf0'}} >
                                    {s.total_balance_ish? parseFloat(s.total_balance_ish).toLocaleString():0 }
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
     ))}
    </div>
   : <div></div>
    );
}

export default OsbbUserSummary;
