import React ,  {useEffect} from "react"
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import ProfileChat from "../../components/ProfileChat/ProfileChat";
import {changeChatUser} from "../../redux/chat-reducer";
import ProfileEdit from "./ProfileEditForm";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Block from '@mui/icons-material/Block';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import {sendAccount, deactivate, activate, deleteProfile} from "../../redux/profile-reducer"



function Profile({profile, openEdit, handleEdit, handleClose }) {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        if (profile && profile.user) {
            dispatch(
                changeChatUser(
                    {
                        id: profile.user,
                        username: profile.first_name + " " + profile.last_name
                    }
                )
            )
        }
    }, [profile])

    const sendAccountData = () => {
        sendAccount(profile.id
            ).then( (res) => {
                console.log('***  res', res);
                if (res.status >= 400) {
                    alert(res.status + ' ' + res.statusText);
                } else {
                    alert('Успішно відправлено!');
                };
            } );
    };

    const deactivateUser = () => {
        deactivate(profile.id
            ).then( (res) => {
                console.log('***  res', res);
                if (res.status >= 400) {
                    alert(res.status + ' ' + res.statusText);
                } else {
                    alert('Користувача заблоковано!');
                };
            } );
    };

    const activateUser = () => {
        activate(profile.id
            ).then( (res) => {
                console.log('***  res', res);
                if (res.status >= 400) {
                    alert(res.status + ' ' + res.statusText);
                } else {
                    alert('Користувача активовано!');
                };
            } );
    };

    const delete_profile = () => {
        deleteProfile(profile.id
            ).then( (res) => {
                console.log('***  res', res);
                if (res.status >= 400) {
                    alert(res.status + ' ' + res.statusText);
                } else {
                    alert('Користувача видалено!');
                    navigate('/profiles');
                };
            } );
    };


    return (
        <>
            {profile && !profile.deleted && (
                    <DashboardLayout>
                        <DashboardNavbar/>
                        <MDBox mb={2}/>
                        <Header profile={profile}>
                            <MDBox mt={5} mb={3}>
                                <Grid container spacing={1}>
                                    {/* <Grid item xs={12} md={6} xl={4}>
                                        <PlatformSettings/>
                                    </Grid> */}
                                    <Grid item xl={8}>
                                        <Grid item xs={12} md={6} sx={{display: "flex"}}>
                                            <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                                            <ProfileInfoCard
                                                title="Дані мешканця"
                                                description=""
                                                info={{
                                                    ПІБ: `${profile.last_name} ${profile.first_name} ${profile.second_name} `,
                                                    Телефон1: `${profile.phone1}`,
                                                    Телефон2: `${profile.phone2}`,
                                                    email: `${profile.email}`,
                                                    Додаткова_інформація: `${profile.comment}`,
                                                    Коментар: `${profile.comment2}`,
                                                }}
                                                social={[]}
                                                action={{route: "", tooltip: "Edit Profile"}}
                                                shadow={false}
                                            />
                                            <MDTypography variant="h6"   component={Link} to={'#'} onClick={handleEdit} >
                                                <Tooltip title={'Редагувати'} placement="top">
                                                    <Icon>edit</Icon>
                                                </Tooltip>
                                            </MDTypography>
                                            <ProfileEdit open={openEdit} handleClose={handleClose} profile={profile} />

                                        </Grid>
                                        {profile.user && profile.email && (
                                        <MDButton  variant="outlined" color="info" onClick={sendAccountData}>
                                                <ForwardToInboxIcon/>&nbsp;
                                                Відправити облікові дані для доступу на email
                                        </MDButton>
                                        )}
                                        <br/><br/>
                                        {profile.user && profile.is_active && (
                                            <>
                                                <MDButton  variant="outlined" color="warning" onClick={deactivateUser}>
                                                    <Block/>&nbsp;
                                                    Заблокувати користувача
                                                </MDButton>
                                                 <br/><br/>
                                            </>
                                         )}
                                        {profile.user && !profile.is_active && (
                                            <>
                                                <MDButton  variant="outlined" color="info" onClick={activateUser}>
                                                    <VerifiedUserIcon/>&nbsp;
                                                    Активувати користувача
                                                </MDButton>
                                                <br/><br/>
                                            </>
                                         )}
                                        <MDButton  variant="outlined" color="error" onClick={delete_profile}>
                                                    <PersonRemoveOutlinedIcon/>&nbsp;
                                                    Видалити
                                        </MDButton>
                                    </Grid>
                                    {profile.user && (
                                        <Grid item xs={12} xl={4} sx={{ height: "100%" }}>
                                            <ProfileChat profile={profile}/>
                                        </Grid>
                                    )}
                                </Grid>
                            </MDBox>
                        </Header>
                        <Footer/>
                    </DashboardLayout>
            )}
        </>
    );
}

export default Profile;
