import {FinanceApi} from "../api/api";

const SET_UNIT = "SET_UNIT"
const UNIT_TOGGLE_IS_FETCHING = "UNIT_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    unit: [],
}

const unitReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UNIT:
            return {
                ...state,
                unit: action.value
            }
        case UNIT_TOGGLE_IS_FETCHING:
                return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setUnit = (value) => ({type: SET_UNIT, value: value})
export const unitToggleIsFetching = (isFetching) => ({type: UNIT_TOGGLE_IS_FETCHING, value: isFetching})

export const requestUnit = () => {
    return async (dispatch) => {
        dispatch(unitToggleIsFetching(true))
        let response = await FinanceApi.getUnit()
        if (response !== undefined) {
            dispatch(setUnit(response.results))
            dispatch(unitToggleIsFetching(false))
        }
    }
}

export default unitReducer
