import {createSelector} from "reselect";

const getIsAuthSelector = (state) => {
    return state.auth.isAuth
}

export const getIsAuth = createSelector(getIsAuthSelector, (isAuth) => {
    return isAuth;
})

export const getUser = (state) => {
    return state.auth.user
}

export const getInitialized = (state) => {
    return state.auth.initialized
}

export const getAccessToken = (state) => {
    return state.auth.accessToken
}
