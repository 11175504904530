import React from "react"
import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';
import { updateHouse } from '../../redux/house-reducer';


function HouseEditForm({open, editInfo, handleClose}) {

     const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        updateHouse({
                "id":values.id,
                "street_name": editInfo.street_name,
                "title": values.title,
                "house_number": values.house_number,
                'house_drob': values.house_drob,
                "house_letter": values.house_letter,
                "house_corps": values.house_corps,
                "postcode": values.postcode,
                "total_square": values.total_square,
                "common_square": values.common_square,
                "polezn_square": values.polezn_square,
                "live_square": values.live_square,
                "adjacent_square": values.adjacent_square,
                "parking_square": values.parking_square,
                "flats_number": values.flats_number,
                "floors_number": values.floors_number,
                "entrances_number": values.entrances_number,
                "elevators_number": values.elevators_number,
                "entities": editInfo.entities,
                "osbb": editInfo.osbb,
            });
        handleClose();
    }
    const handleCloseDialog = () => {
        handleClose();
    };


    return (
        <>
            {editInfo && (
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
                    <DialogTitle id="responsive-dialog-title">
                     { 'Редагувати інформацію про будинок - ' + editInfo.title}
                    </DialogTitle>
                    <DialogContent>
                            <Form
                                onSubmit={onSubmit}
                                mutators={{
                                    ...arrayMutators
                                }}
                                validate={values => {
                                    const errors = {}
                                    return errors
                                }}
                                initialValues={editInfo}
                                render={({
                                        submitError, submitErrors, handleSubmit,
                                        form: {mutators: {push, pop}},
                                        submitting, pristine, values
                                }) => (
                                    <MDBox component="form" onSubmit={handleSubmit}>
                                        <Card style={{height: "100%"}}>
                                                <MDBox >
                                                    <MDBox >
                                                        <MDTypography variant="body2">Назва:</MDTypography>
                                                        <Field name="title">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox display='flex' justifyContent="space-between" pt={1} px={0} pb={1}>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Поштовий індекс:</MDTypography>
                                                            <Field name="postcode">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox width='95%' pt={0}>
                                                            <MDTypography variant="body2">Вулиця:</MDTypography>
                                                            <Field name="street_name">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                    </MDBox>
                                                    <MDBox display='flex' justifyContent="space-between" pt={1} px={0} pb={1}>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Номер будинку:</MDTypography>
                                                            <Field name="house_number">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Дріб:</MDTypography>
                                                            <Field name="house_drob">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Корпус/секція:</MDTypography>
                                                            <Field name="house_corps">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Буква:</MDTypography>
                                                            <Field name="house_letter">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                    </MDBox>

                                                    <MDBox display='flex' justifyContent="space-between" pt={1} px={0} pb={1}>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Загальна площа будинку:</MDTypography>
                                                            <Field name="total_square">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Площа загального користування:</MDTypography>
                                                            <Field name="common_square">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox pt={3}>
                                                            <MDTypography variant="body2">Площа корисна:</MDTypography>
                                                            <Field name="polezn_square">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox pt={3} >
                                                            <MDTypography variant="body2">Площа жила:</MDTypography>
                                                            <Field name="live_square">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Прибудинкова територія:</MDTypography>
                                                            <Field name="adjacent_square">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox pt={3} >
                                                            <MDTypography variant="body2">Паркінг:</MDTypography>
                                                            <Field name="parking_square">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                    </MDBox>
                                                    <MDBox display='flex' justifyContent="space-between" pt={1} px={0} pb={1}>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Кількість квартир фізичних осіб:</MDTypography>
                                                            <Field name="flats_number">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox>
                                                            <MDTypography variant="body2">Кількість поверхів:</MDTypography>
                                                            <Field name="floors_number">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox >
                                                            <MDTypography variant="body2">Кількість під'їздів:</MDTypography>
                                                            <Field name="entrances_number">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                        <MDBox >
                                                            <MDTypography variant="body2">Кількість ліфтів:</MDTypography>
                                                            <Field name="elevators_number">
                                                                {props => (
                                                                <>
                                                                    <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                            value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </>
                                                                )}
                                                            </Field>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                        </Card>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" fullWidth type="submit"
                                                    disabled={submitting || pristine}>
                                                Зберегти
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>

                                )}
                            />

                    </DialogContent>
                    <DialogActions>
                        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                            Закрити
                        </MDButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default HouseEditForm;
