import {createSelector} from "reselect";

const getServiceSelector = (state) => {
    return state.service.service
}

export const getService = createSelector(getServiceSelector, (service) => {
    return service;
})

export const getServiceIsFetching = (state) => {
    return state.service.isFetching
}
