import {useState  } from "react";
import TextField from '@mui/material/TextField';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {uk} from "date-fns/locale";

export default function BalanceDatePicker( { selecteddate, onChange } ) {

  const [selectedDate, setselectedDate] = useState(selecteddate?selecteddate:new Date());
  const handleDateChange = (selectedDate) => {
      setselectedDate(selectedDate);
      onChange(selectedDate)
    };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale ={uk} >
      <DatePicker
          //label="період"
          views={[ "month","year"]}
          closeOnSelect={true}
          ampm={false}
          clearable
          value={selectedDate}
          onChange={handleDateChange}
          inputFormat="MM/yyyy"
          renderInput={(params) => <TextField {...params} size="medium"  margin="dense"  sx={{p: '2px 4px', alignItems: 'center', width: 150 }} />}
      />
    </LocalizationProvider>

  );
}
