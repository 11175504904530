import {AuthApi} from "../api/api";
import {isTokenExpired} from "../utils/utils";
import jwtDecode from "jwt-decode";

const SET_AUTH_DATA = "SET_AUTH_DATA"
const GET_AUTH_DATA = "GET_AUTH_DATA"
const SET_USER = "SET_USER"
const INITIALIZED_SUCCESS = "INITIALIZED_SUCCESS"
const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN"

let initialState = {
    initialized: false,
    accessToken: null,
    refreshToken: null,
    user: null,
    isAuth: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZED_SUCCESS:
            return {
                ...state,
                initialized: true
            }
        case SET_AUTH_DATA:
            return {
                ...state,
                ...action.value,
            }
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.value,
            }
        case GET_AUTH_DATA:
            return {...state}
        case SET_USER:
            return {
                ...state,
                user: action.value
            }
        default:
            return state;
    }
}

export const setAuthData = (accessToken, refreshToken, isAuth) => ({type: SET_AUTH_DATA, value: {accessToken, refreshToken, isAuth}})
export const setUser = (user) => ({type: SET_USER, value: user})
export const setAccessToken = (accessToken) => ({type: SET_ACCESS_TOKEN, value: accessToken})
export const getAuthData = () => ({type: GET_AUTH_DATA})
export const initializedSuccess = () => ({type: INITIALIZED_SUCCESS})

export const isAuthenticated = () => {
    return (dispatch) => {
        let accessToken = localStorage.getItem('accessToken')
        let refreshToken = localStorage.getItem('refreshToken')
        let user = localStorage.getItem("user");
        let isAuth = !!(accessToken && refreshToken && user)
        dispatch(setUser(JSON.parse(user)))
        dispatch(setAuthData(accessToken, refreshToken, isAuth))
        dispatch(initializedSuccess())
    }
}

export const login = (username, password, osbb) => {
    return async (dispatch) => {
        let status = {"success": true, "error": "Something went wrong please try again", "osbbs": []}
        await AuthApi.login(username, password, osbb).then(data => {
            if (data.data?.access) {
                localStorage.setItem('accessToken', data.data.access)
                localStorage.setItem('refreshToken', data.data.refresh)
                localStorage.setItem("user", JSON.stringify(data.data.user));
                dispatch(setAuthData(data.data.access, data.data.refresh, true))
                dispatch(setUser(data.data.user))
            } else {
                status["success"] = false
                if (data.data?.osbbs?.length > 1) {
                    status["osbbs"] = data.data.osbbs
                }
            }
        }).catch(error => {
            console.log('*** error login', error)
            status["success"] = false
            status["error"] = error.response?.data?.detail
            console.log('*** error login status', status)
        });
        return status
    }
}

export const checkAccessToken = () => {
    return async (dispatch) => {
        const accessToken = localStorage.getItem('accessToken')
        if (isTokenExpired(jwtDecode(accessToken))) {
            let response = await AuthApi.refresh(localStorage.getItem('refreshToken'));
            if (response !== undefined) {
                localStorage.setItem('accessToken', response.access)
                dispatch(setAccessToken(response.access))
                return response.access;
            }
        }
        return accessToken
    }
}

export const logout = () => {
    return (dispatch) => {
        localStorage.clear()
        dispatch(setAuthData(null, null,false))
        dispatch(setUser(null))
        window.location = "/authentication/sign-in";
    }
}

export default authReducer
