import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import Header from "layouts/profile/components/Header";
import AccountServices from "layouts/account/components/AccountServices/AccountServices";
import AccountAgreements from "layouts/account/components/AccountAgreements/AccountAgreements";
import AccountSummary from "../../components/Summary/AccountSummary";
import Pay from "../../components/Pay/Pay";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

function Account({ account, accountProfiles, accountAgreements, accountServices, balancedate, onChange } ) {

    if (!account) {
        return <div></div>
    }

    const handleDateChange = (selectedDate) => {
        onChange(selectedDate)
      };

    const account_title = "Дані по особовому рахунку № "  + account.number

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={2}/>
            <Header profile={account.profile}>
                <MDBox mt={5} mb={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} xl={4} sx={{display: "flex"}}>
                            <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                            <ProfileInfoCard
                                title= { account_title }
                                description=""
                                info={{
                                    ПІБ: `${account.profile.first_name} ${account.profile.last_name}`,
                                    Телефон: `${account.profile.phone1}`,
                                    email: `${account.profile.email}`,
                                }}
                                social={[]}
                                action={{route: "", tooltip: "Edit Profile"}}
                                shadow={false}
                            />
                            <Divider orientation="vertical" sx={{mx: 0}}/>
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            {/* <ProfilesList title="Кількість мешканців" profiles={accountProfiles} shadow={false}/> */}
                            <Card sx={{ height: "100%", boxShadow: "none" }}>
                            <MDBox pt={2} px={2}>
                                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                    Кількість мешканців
                                </MDTypography>
                            </MDBox>
                            <MDBox p={2}>
                                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                                {account.flat.count_inhabit}
                                </MDBox>
                            </MDBox>
                            </Card>
                        </Grid>
                        <Grid item xs={12} xl={4}>
                              <Pay accountServices={accountServices} balancedate={balancedate}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <AccountSummary accountId={account.id} balancedate={balancedate} onChange={handleDateChange}/>
                        </Grid>
                    </Grid>
                </MDBox>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <AccountServices accountServices={accountServices} balancedate={balancedate}/>
                    </Grid>
{/*                     <Grid item xs={12}>
                        <AccountAgreements accountAgreements={accountAgreements} />
                    </Grid> */}
                </Grid>
            </Header>
            <Footer/>
        </DashboardLayout>
    );
}

export default Account;
