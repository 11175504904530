import {HouseApi} from "../api/api";

const GET_HOUSES = "GET_HOUSES"
const GET_HOUSE = "GET_HOUSE"
const HOUSES_PAGE_CHANGED = 'HOUSES_PAGE_CHANGED';
const HOUSES_PAGE_SIZE_CHANGED = "HOUSES_PAGE_SIZE_CHANGED";
const HOUSES_TOTAL_COUNT_CHANGED = "HOUSES_TOTAL_COUNT_CHANGED";
const HOUSES_TOGGLE_IS_FETCHING = "HOUSES_TOGGLE_IS_FETCHING";

let initialState = {
    house: {},
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    isFetching: false,
    houses: []
}

const housesReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case HOUSES_PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case HOUSES_PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case HOUSES_TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case GET_HOUSES:
            return {
                ...state,
                houses: action.value
            }
        case GET_HOUSE:
            return {
                ...state,
                house: action.value
            }
        case HOUSES_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const getHouse = (value) => ({type: GET_HOUSE, value: value})
export const getHouses = (value) => ({type: GET_HOUSES, value: value})
export const setHousesPageChanged = (value) => ({type: HOUSES_PAGE_CHANGED, value: value})
export const setHousesPageSizeChanged = (value) => ({type: HOUSES_PAGE_SIZE_CHANGED, value: value})
export const setHousesTotalCountChanged = (value) => ({type: HOUSES_TOTAL_COUNT_CHANGED, value: value})
export const housesToggleIsFetching = (isFetching) => ({type: HOUSES_TOGGLE_IS_FETCHING, value: isFetching})

export const requestHouses = (queryPageIndex, queryPageSize) => {
    return async (dispatch) => {
        dispatch(housesToggleIsFetching(true))
        dispatch(setHousesPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await HouseApi.getHouses(offset, queryPageSize)
        if (response !== undefined) {
            dispatch(setHousesPageSizeChanged(queryPageSize))
            dispatch(setHousesTotalCountChanged(response.count))
            dispatch(getHouses(response.results))
            dispatch(housesToggleIsFetching(false))
        }
    }
}

export default housesReducer
