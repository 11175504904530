import {useState,useEffect  } from "react";
import {useSelector, useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ListSubheader from '@material-ui/core/ListSubheader';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BalanceDatePicker from "components/BalanceDatePicker/BalanceDatePicker";
import {getAccountSummary} from "../../redux/summary-reducer";
import { get_AccountSummary } from "../../redux/summary-selectors";



function AccountSummary( { accountId, balancedate, onChange }) {
    const dispatch = useDispatch()
    const accountsummary = useSelector(get_AccountSummary)
    const [selectedDate, setselectedDate] = useState(balancedate?balancedate:new Date());
    const handleDateChange = (selectedDate) => {
          setselectedDate(selectedDate);
          onChange(selectedDate);
    };

    const date = new Date();
    const [isCurrentPeriod, setIsCurrentPeriod] = useState(() =>
        date.toISOString().slice(0, 10)===selectedDate.toISOString().slice(0, 10)
        )


  useEffect(() => {
    dispatch(getAccountSummary(selectedDate, accountId))
    setIsCurrentPeriod(date.toISOString().slice(0, 10)===selectedDate.toISOString().slice(0, 10))
}, [selectedDate, accountId])



    return ( ( accountsummary && accountsummary.results ) ?
    <div >
      <ListSubheader style={{color: '#4babf0'}} component="div">Дані по розрахунках</ListSubheader>
        <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2} >
                  <Card sx={{ height: "100%" }}>
                      <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                          <MDBox lineHeight={1.25}>
                              <BalanceDatePicker selecteddate={selectedDate} onChange={handleDateChange} />
                          </MDBox>
                      </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Борг на початок періоду"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}} >
                                  {(accountsummary.results.length>0)?
                                        (accountsummary.results[0].total_balance_vh?
                                            accountsummary.results[0].total_balance_vh:
                                            0
                                        ):
                                    0}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Нарахування"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}}>
                                  {(accountsummary.results.length>0 && !isCurrentPeriod)?
                                    accountsummary.results[0].total_accrual:
                                    '-'}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Оплати"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}} >
                                  {(accountsummary.results.length>0)?
                                    accountsummary.results[0].total_payments_buh:
                                    0}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Борг на кінець періоду"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}} >
                                  {(accountsummary.results.length>0)?
                                        (accountsummary.results[0].total_balance_ish?
                                            accountsummary.results[0].total_balance_ish:
                                            0):
                                        0}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
        </Grid>
    </div>
    : <div></div>
    );
}

export default AccountSummary;
