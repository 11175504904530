import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {useDispatch} from "react-redux";


function Rate({rate}) {
    const dispatch = useDispatch()
    return (
        <Card sx={{ height: "100%" }}>
            <MDBox mb={1} justifyContent="space-between" pt={1} px={1} >
                {rate.house &&
                ( <>
                    <MDTypography variant="button" fontWeight="bold" textTransform="uppercase">
                        {rate.house.title}
                     </MDTypography>
                     <br/>
                 </>
                )}
                <MDTypography variant="caption" textTransform="uppercase">
                    {rate.name}
                </MDTypography>
                <br/>
                <MDTypography variant="button" style={{color: '#4babf0'}}>
                    {parseFloat(rate.sum).toFixed(2).toLocaleString()} грн.  за {rate.units_name}
                </MDTypography>
                <br/>
                <MDTypography variant="caption">
                    (Тариф дійсний з {rate.start_date} по {rate.end_date} )
                </MDTypography>
            </MDBox>
        </Card>
    );
}

export default Rate;
