import React, {useEffect} from "react"
import Grid from "@mui/material/Grid";
import Poll from "./Poll";
import {useDispatch, useSelector} from "react-redux";
import {getPolls, getPollsIsFetching} from "../../redux/polls-selectors";
import {requestPolls} from "../../redux/polls-reducer";
import Loader from "../../components/Loader/Loader";
import Layout from "./Layout";
import ListSubheader from '@material-ui/core/ListSubheader';

  function News() {
    const polls = useSelector(getPolls)
    const isPollFetching = useSelector(getPollsIsFetching)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestPolls())
    }, [])

    return (
    <Layout>
            {isPollFetching && (
                <Loader/>
            )}
            { !isPollFetching && (
                   <div>
                        <ListSubheader style={{ color: "#1A73E8"}} component="div">Новини</ListSubheader>
                        <Grid container spacing={3} >
                            {polls.map((poll) => (
                                <Grid item xs={12} md={6} xl={3} key={poll.id}>
                                    <Poll poll={poll}/>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    )
            }
    </Layout>
    )
}



  export default News;
