import MDTypography from "components/MDTypography";

export default function Data(servicestats, user) {
    return {
        columns: [
          {Header: "Послуга", accessor: "service", align: "left", width : user ? "max-content": "min-content", enableSorting: false},
          {Header: "Борг", accessor: "balance", align: "center", width : "max-content"},
        ],

        rows: servicestats.map((s) => ({
            service: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {s.service_name}
                </MDTypography>
            ),
            balance: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    { s.debt_sum_str }
                </MDTypography>
            ),
        })),
    };
}
