import {createSelector} from "reselect";

const getPayshistSelector = (state) => {
    return state.payshist.payshist
}

export const getPayshist = createSelector(getPayshistSelector, (payshist) => {
    return payshist;
})

export const getPayshistQueryPageIndex = (state) => {
    return state.payshist.queryPageIndex
}

export const getPayshistQueryPageSize = (state) => {
    return state.payshist.queryPageSize
}

export const getPayshistTotalCount = (state) => {
    return state.payshist.totalCount
}

export const getPayshistIsFetching = (state) => {
    return state.payshist.isFetching
}
