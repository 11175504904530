import {createSelector} from "reselect";

const getOsbbUserSummarySelector = (state) => {
    return state.osbbsummary.osbbusersummary
}
const getOsbbServiceSummarySelector = (state) => {
    return state.osbbsummary.osbbservicesummary
}
const getOsbbSummarySelector = (state) => {
    return state.osbbsummary.osbbsummary
}
const getAccountSummarySelector = (state) => {
    return state.osbbsummary.accountsummary
}

export const get_OsbbUserSummary = createSelector(getOsbbUserSummarySelector, (summary) => {
    return summary;
})
export const get_OsbbServiceSummary = createSelector(getOsbbServiceSummarySelector, (summary) => {
    return summary;
})
export const get_OsbbSummary = createSelector(getOsbbSummarySelector, (summary) => {
    return summary;
})
export const get_AccountSummary = createSelector(getAccountSummarySelector, (summary) => {
    return summary;
})
