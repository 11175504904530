import {createSelector} from "reselect";

const getAccountsSelector = (state) => {
    return state.accounts.accounts
}

export const getAccounts = createSelector(getAccountsSelector, (accounts) => {
    return accounts;
})

export const  getAccountsQueryPageIndex = (state) => {
    return state.accounts.queryPageIndex
}

export const getAccountsQueryPageSize = (state) => {
    return state.accounts.queryPageSize
}

export const  getAccountsTotalCount = (state) => {
    return state.accounts.totalCount
}

export const getAccountsIsFetching = (state) => {
    return state.accounts.isFetching
}
