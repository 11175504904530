import React from "react";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import UserRoutes from "routes";

import {useMaterialUIController, setMiniSidenav, setOpenConfigurator} from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import PrivateRoute from "PrivateRoute";
import Chat from "components/Chat";
import Loader from "./components/Loader/Loader";
import Profile from "layouts/profile/ProfileContainer"
import Account from "./layouts/account/AccountContainer";
import Flat from "./layouts/flat/Flat";
import {MettersDataNew} from "./layouts/mettersdata/MettersDataNew";
import {getInitialized, getIsAuth} from "./redux/auth-selectors";
import {isAuthenticated, logout} from "./redux/auth-reducer";
import {getOsbb} from "./redux/osbb-reducer";
import {getOSBBSel} from "./redux/osbb-selectors";


const App = () => {
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;

    const isInitialized = useSelector(getInitialized)
    const isAuth = useSelector(getIsAuth)
    const osbb = useSelector(getOSBBSel)

    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction, isInitialized]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname,  isInitialized]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route && route.private) {
                return (
                    <Route exact path={route.route} element={<PrivateRoute/>} key={`private-${route.key}`}>
                        <Route exact path={route.route} element={route.component}/>
                    </Route>
                );
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });

    const dispatchApi = useDispatch()

    useEffect(() => {
        dispatchApi(isAuthenticated())
    }, [])

    useEffect(() => {
        dispatchApi(getOsbb())
    }, [isAuth])

    const onLogout = () => {
        dispatchApi(logout())
    }

    const routes=UserRoutes()


    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline/>
            <>
                {!isInitialized && (
                    <Loader />
                )}
                {isInitialized && isAuth && osbb && layout === "dashboard" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                           // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                            brandName={osbb.name}
                            brandOKPO={osbb.okpo}
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                            logout={onLogout}
                        />
                        <Configurator/>
                         <Chat/>
                    </>
                )}
                {isInitialized && (
                    <Routes>
                        {getRoutes(routes)}
                        <Route exact path="/authentication/sign-in" element={<SignIn/>} key="sign-in"/>
                        <Route exact path="/authentication/sign-up" element={<SignUp/>} key="sign-up"/>
                        <Route exact path="/profile/:profileId" element={<PrivateRoute/>} key="private-profile-with-id">
                            <Route path="/profile/:profileId" element={<Profile/>} key="profile-with-id"/>
                        </Route>
                        <Route exact path="/account/:accountId" element={<PrivateRoute/>} key="private-account-with-id">
                            <Route path="/account/:accountId/" element={<Account/>} key="profile-account-id"/>
                        </Route>
                        <Route exact path="/account/:accountId/:balancedate" element={<PrivateRoute/>} key="private-account-with-id2">
                            <Route path="/account/:accountId/:balancedate" element={<Account/>} key="profile-account-id2"/>
                        </Route>
                        <Route exact path="/flat/:flatId" element={<PrivateRoute/>} key="private-flat-with-id">
                            <Route path="/flat/:flatId" element={<Flat/>} key="profile-flat-id"/>
                        </Route>
                        <Route exact path="/flatmetters/:flatId" element={<PrivateRoute/>} key="private-flatmetters-with-id">
                            <Route path="/flatmetters/:flatId" element={<MettersDataNew/>} key="profile-flatmetters-id"/>
                        </Route>
                        <Route exact path="/housemetters/:houseId" element={<PrivateRoute/>} key="housemetters-with-id">
                            <Route path="/housemetters/:houseId" element={<MettersDataNew/>} key="housemetters-id"/>
                        </Route>
                        <Route exact path="*" element={<PrivateRoute/>} key="private-default">
                            <Route exact path="*" element={<Navigate to="/dashboard"/>}/>
                        </Route>
                    </Routes>
                )}
            </>
        </ThemeProvider>
    );
}

export default App
