import {createSelector} from "reselect";

const getPollsSelector = (state) => {
    return state.pollData.polls
}

export const getPolls = createSelector(getPollsSelector, (polls) => {
    return polls;
})


const getPollSelector = (state) => {
    return state.pollData.poll
}

export const getPoll = createSelector(getPollSelector, (poll) => {
    return poll;
})

export const getPollsIsFetching = (state) => {
    return state.pollData.isFetching
}

const getChoices = (state) => {
    return state.pollData.choices
}

const getId = (_, id) => id

export const getQuestionChoice = createSelector(
    [getChoices, getId],
    (choices, id) => {
        return choices[id];
    }
)

export const getChoicesByQuestion = (state, questionId) => {
    return state.pollData.choices[questionId]
}
