import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';
import { saveHouseAddInfo } from '../../../redux/house-reducer';


function HouseAddInfoEditForm({open, house, editInfo, handleClose}) {
    const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        saveHouseAddInfo({
                "id": values.id,
                "info": values.info,
                "house": house.id,
            });
        handleClose();
    }
    const handleCloseDialog = () => {
        handleClose();
    };

   return (
        <>
            {true && (
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
                    <DialogTitle id="responsive-dialog-title">
                        Додати/Редагувати прилад обліку
                    </DialogTitle>
                    <DialogContent>
                            <Form
                                onSubmit={onSubmit}
                                mutators={{
                                    ...arrayMutators
                                }}
                                validate={values => {
                                    const errors = {}
                                    return errors
                                }}
                                initialValues={editInfo}
                                render={({submitError, submitErrors,handleSubmit, form, submitting, pristine, values}) => (
                                    <MDBox component="form" onSubmit={handleSubmit}>
                                        <Card style={{height: "100%"}}>
                                                <MDBox >
                                                    <MDBox>
                                                        <MDTypography variant="body1">інформація:</MDTypography>
                                                        <Field name="info">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                        multiline rows={10}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                </MDBox>
                                        </Card>
                                        {submitError && <MDBox display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            color="error" fontSize="small">
                                                        {submitError}
                                                        </MDBox>
                                        }
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" fullWidth type="submit"
                                                    disabled={submitting || pristine}>
                                                Зберегти
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                )}
                            />
                    </DialogContent>
                    <DialogActions>
                        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                            Закрити
                        </MDButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default HouseAddInfoEditForm;
