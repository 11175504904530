import React from "react";
import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { uk } from "date-fns/locale";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';
import { saveOsbbDoc } from '../../redux/osbb-reducer';
import FileField from "./components/FileField"


function DocEditForm({open, editDocs, handleClose}) {

    const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        if (values.file) {
            values.file.map((file) => {
                saveOsbbDoc({
                        "id": values.id,
                        "name": values.name,
                        "doc_type": 'establishment_documents',
                        "data": typeof values.data.getMonth === 'function' ? values.data.toISOString().split('T')[0]:values.data,
                        "file": file,
                        "file_type": file.type,
                    });
            });
            alert("Файли успішно завантажено!!!")
        } else {
            alert("Файли НЕ завантажено!!!");
        }
        handleClose();
    }

    const handleCloseDialog = () => {
        handleClose();
    };

    return (
        <>
            {editDocs && (
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle id="responsive-dialog-title">
                        {editDocs.id ? ('Редагувати') : ('Додати')} документ
                    </DialogTitle>
                    <DialogContent>
                            <Form
                                onSubmit={onSubmit}
                                mutators={{
                                    ...arrayMutators
                                }}
                                validate={values => {
                                    const errors = {}
                                    if (!values.name) {
                                    errors.name = 'Додати назву'
                                    }
                                    return errors
                                }}
                                initialValues={editDocs}
                                render={({
                                        submitError, submitErrors, handleSubmit,
                                        form: {mutators: {push, pop}},
                                        submitting, pristine, values
                                }) => (
                                    <MDBox component="form" onSubmit={handleSubmit}>
                                        <Card style={{height: "100%"}}>
                                                <MDBox >
                                                    <MDBox >
                                                        <MDTypography variant="body1">Примітка:</MDTypography>
                                                        <Field name="name">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDTypography variant="body1">Дата:</MDTypography>
                                                        <Field name="data">
                                                            {props => (
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale ={uk} >
                                                                <DatePicker
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    inputFormat='yyyy-MM-dd'
                                                                    closeOnSelect={true}
                                                                    value={props.input.value}
                                                                    onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                                </LocalizationProvider>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox mt={2}>
                                                        <FileField name="file" />
                                                    </MDBox>
                                                </MDBox>

                                        </Card>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" fullWidth type="submit"
                                                    disabled={submitting || pristine}>
                                                Зберегти
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>

                                )}
                            />

                    </DialogContent>
                    <DialogActions>
                        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                            Закрити
                        </MDButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default DocEditForm;
