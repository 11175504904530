import MDTypography from "components/MDTypography";
import Checkbox from '@mui/material/Checkbox';

export default function Data(mettershist, flatId ) {

  const columns_meter_flat = [
    { Header: "Квартира", accessor: "flat", align: "center", width : "max-content" , enableSorting: false },
    { Header: "Лічильник", accessor: "metter", align: "center", width : "max-content" , enableSorting: false},
    { Header: "Послуга", accessor: "service_name", align: "left", width : "max-content" , enableSorting: false},
    { Header: "Показання Попереднє", accessor: "device_amount_start", align: "center", width : "min-content" , enableSorting: false},
    { Header: "Показання Поточне", accessor: "device_amount_end", align: "center", width : "max-content" , enableSorting: false},
    { Header: "Обсяг", accessor: "amount", align: "center", width : "max-content" , enableSorting: false},
    { Header: "Дата передачі ", accessor: "send_date", align: "center", width : "max-content", enableSorting: false },
    { Header: "Завантажено з ЄСАН", accessor: "esan", align: "center", width : "min-content" , enableSorting: false},
  ]

  const columns_meter_house = [
    //{ Header: "Квартира", accessor: "flat", align: "center", width : "max-content" , enableSorting: false },
    { Header: "Лічильник", accessor: "metter", align: "center", width : "max-content" , enableSorting: false},
    { Header: "Послуга", accessor: "service_name", align: "left", width : "max-content" , enableSorting: false},
    { Header: "Показання Попереднє", accessor: "device_amount_start", align: "center", width : "min-content" , enableSorting: false},
    { Header: "Показання Поточне", accessor: "device_amount_end", align: "center", width : "max-content" , enableSorting: false},
    { Header: "Обсяг", accessor: "amount", align: "center", width : "max-content" , enableSorting: false},
    { Header: "Дата передачі ", accessor: "send_date", align: "center", width : "max-content", enableSorting: false },
    //{ Header: "Завантажено з ЄСАН", accessor: "esan", align: "center", width : "min-content" , enableSorting: false},
  ]

  return {
    columns_meter: ( flatId  ? columns_meter_flat : columns_meter_house ),

    rows_meter: mettershist.map((metter) => ({
      flat: (
        <MDTypography component="span" variant="caption" color="text"  >
        { metter.meter.flat  ? '№ '+metter.meter.flat.flat_number+' ('+ metter.meter.flat.house.title+')' : metter.meter.house.title}
        </MDTypography>
      ),
      metter: (
          <MDTypography component="span" variant="body3" color="text"  >
           {metter.meter.factory_number ? metter.meter.factory_number : metter.meter.name }
          </MDTypography>
      ),
      service_name: (
        <MDTypography component="span" variant="caption" color="text"  >
          {metter.service_name}
        </MDTypography>
      ),
      device_amount_start: (
          <MDTypography component="span" variant="body3" color="text"  >
            {metter.device_amount_start}
          </MDTypography>
      ),
      device_amount_end: (
          <MDTypography component="span" variant="body3" color="text"  >
            {metter.device_amount_end}
          </MDTypography>
      ),
      amount: (
          <MDTypography component="span" variant="body3" color="text"  >
            {metter.amount}
          </MDTypography>
      ),
      send_date: (
        <MDTypography component="span" variant="body2" color="text"  >
           {metter.send_date}
        </MDTypography>
      ),
      esan: (
        <MDTypography component="span" variant="body2" color="text"  >
          <Checkbox checked={metter.esan} />
        </MDTypography>
      ),
    })),
  };
}
