import {HouseApi} from "../api/api";

const SET_HOUSE_METERS = "SET_HOUSE_METERS"
const HOUSE_METERS_TOGGLE_IS_FETCHING = "HOUSE_METERS_TOGGLE_IS_FETCHING";


let initialState = {
    isFetching: false,
    meters: []
}

const houseMetersReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SET_HOUSE_METERS:
            return {
                ...state,
                meters: action.value
            }
        case HOUSE_METERS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setHouseMeters = (value) => ({type: SET_HOUSE_METERS, value: value})
export const houseMetersToggleIsFetching = (isFetching) => ({type: HOUSE_METERS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestHouseMeters = (houseId) => {
    return async (dispatch) => {
        dispatch(houseMetersToggleIsFetching(true))
        let response = await HouseApi.getHouseMetters(houseId)
        if (response !== undefined) {
            dispatch(setHouseMeters(response.results))
            dispatch(houseMetersToggleIsFetching(false))
        }
    }
}


export const sendMeterData = async (data, meterId) => {
    try {
        let response = await HouseApi.sendMeterData(data, meterId)
        return response
    } catch (error) {
        console.error("*** sendMeterData Щось пішло не так ! ", error)
        return (error);
    }
}


export default houseMetersReducer
