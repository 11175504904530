// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import React, {useState} from "react";
import Polls from "../../components/Polls/Polls";
import Rates from "../../components/Rates/Rates";
import OsbbUserSummary from "../../components/Summary/OsbbUserSummary";
import Charts from "../../components/Charts/Charts";
import ServiceStats from "./components/ServiceStats/ServiceStats";


function UserDashboard() {
  const [BalanceDate, setBalanceDate] = useState(new Date());
  const handleDateChange = (BalanceDate) => {
      setBalanceDate(BalanceDate);
  };
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Rates />
      <OsbbUserSummary onChange={handleDateChange} />
      <Charts />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <ServiceStats balancedate={BalanceDate} user={true} />
            </Card> 
          </Grid>
        </Grid>
      </MDBox>
      <Polls />
      <Footer />
    </DashboardLayout>
  );
}

export default UserDashboard;
