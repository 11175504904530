import {createSelector} from "reselect";

const getFlatsSelector = (state) => {
    return state.flats.flats
}

export const getFlats = createSelector(getFlatsSelector, (flats) => {
    return flats;
})

export const getFlatsQueryPageIndex = (state) => {
    return state.flats.queryPageIndex
}

export const getFlatsQueryPageSize = (state) => {
    return state.flats.queryPageSize
}

export const getFlatsTotalCount = (state) => {
    return state.flats.totalCount
}

export const getFlatsIsFetching = (state) => {
    return state.flats.isFetching
}

export const getFlatSel = (state) => {
    return state.flats.flat
}
