import {OsbbSummaryApi} from "../api/api";

const SET_OSBB_SUMMARY = "SET_OSBB_SUMMARY"
const SET_OSBB_SERVICE_SUMMARY = "SET_OSBB_SERVICE_SUMMARY"
const SET_OSBB_USER_SUMMARY = "SET_OSBB_USER_SUMMARY"
const SET_ACCOUNT_SUMMARY = "SET_ACCOUNT_SUMMARY"

let initialState = {
    accountsummary: [],
    osbbsummary: [],
    osbbservicesummary:[],
    osbbusersummary:[],
}

const osbbSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT_SUMMARY:
            return {
                ...state,
                accountsummary: action.value
            }
        case SET_OSBB_SUMMARY:
            return {
                ...state,
                osbbsummary: action.value
            }
        case SET_OSBB_SERVICE_SUMMARY:
                return {
                    ...state,
                    osbbservicesummary: action.value
                }
        case SET_OSBB_USER_SUMMARY:
                return {
                    ...state,
                    osbbusersummary: action.value
                }
        default:
            return state;
    }
}

export const setAccountSummary = (value) => ({type: SET_ACCOUNT_SUMMARY, value: value})
export const setOsbbSummary = (value) => ({type: SET_OSBB_SUMMARY, value: value})
export const setOsbbServiceSummary = (value) => ({type: SET_OSBB_SERVICE_SUMMARY, value: value})
export const setOsbbUserSummary = (value) => ({type: SET_OSBB_USER_SUMMARY, value: value})

export const getAccountSummary = (balanceDate, accountId) => {
    return (dispatch) => {
        OsbbSummaryApi.getSummary(balanceDate.toISOString().slice(0, 10), accountId).then(data => {
                dispatch(setAccountSummary(data))
            });
    }
}

export const getOsbbSummary = (balanceDate) => {
    return (dispatch) => {
        OsbbSummaryApi.getSummary(balanceDate.toISOString().slice(0, 10)).then(data => {
                dispatch(setOsbbSummary(data))
            });
    }
}

export const getOsbbServiceSummary = (balancedate) => {
    return (dispatch) => {
        OsbbSummaryApi.get_serviceSummary(balancedate.toISOString().slice(0, 10)).then(data => {
                dispatch(setOsbbServiceSummary(data))
            });
    }
}

export const getOsbbUserSummary = (balancedate) => {
    return (dispatch) => {
        OsbbSummaryApi.get_userSummary(balancedate.toISOString().slice(0, 10)).then(data => {
                dispatch(setOsbbUserSummary(data))
            });
    }
}

export default osbbSummaryReducer
