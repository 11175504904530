import MDBox from "components/MDBox";
import React from "react";
import DataTable from "../../../../components/Tables/DataTable";
import ServicesTableData from "../../data/ServicesTableData";
import MDTypography from "../../../../components/MDTypography";
import Card from "@mui/material/Card";

function AccountServices({ accountServices , balancedate} ) {

    return (
        <Card>
            <MDBox
                mx={2}
                mt={-1}
                py={1}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    Послуги
                </MDTypography>
            </MDBox>

            <MDBox pt={3}>
                <DataTable
                    table={ServicesTableData(accountServices, balancedate)}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    queryPageIndex={0}
                    queryPageSize={1000}
                    totalCount={accountServices.length}
                />
            </MDBox>
        </Card>
    );
}

export default AccountServices ;
