import {createSelector} from "reselect";

const getFlatMetersSelector = (state) => {
    return state.flatMeters.meters
}

export const getFlatMeters = createSelector(getFlatMetersSelector, (meters) => {
    return meters;
})

export const getRecentAmount = (state) => {
    return state.flatMeters.meterRecentAmount
}
