import MDBox from "../MDBox";
import loader from "../../assets/images/illustrations/loader.gif";
import React from "react";

const Loader = () => {
    return (
        <MDBox>
            <MDBox component="img" src={loader} alt="Loading ..." />
        </MDBox>
    )
}

export default Loader