import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";

const Table = ({title, columns, rows, queryPageIndex, queryPageSize, totalCount, onChanged}) => {

  return (
      <MDBox pt={1} pb={0} >
            <Card>
              <MDBox
                  mx={2}
                  mt={-1}
                  py={1}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {title}
                </MDTypography>
              </MDBox>
                <DataTable
                    table={{columns, rows}}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    queryPageIndex={queryPageIndex}
                    queryPageSize={queryPageSize}
                    totalCount={totalCount}
                    onTableDataChanged={onChanged}
                />
            </Card>
      </MDBox>
  );
}

export default Table;
