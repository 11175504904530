import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

export default function GoBack( ) {
    const navigate = useNavigate();
    return (
        <MDButton variant="gradient" color="info" size="small" onClick={() => navigate(-1)}>
            Повернутись
        </MDButton>
    );
};
