import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import React from "react";
import DataTable from "../../components/Tables/DataTable";
import MetersTableData from "./data/MetersTableData";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import Header from "./components/Header"

function Flat({flat, flatMeters}) {
    if (!flat) {
        return <div></div>
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mb={2}/>
            <Header >
                  <MDBox mt={5} mb={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} xl={2} sx={{display: "flex"}}>
                            <Divider orientation="vertical" sx={{ml: -2, mr: 1}}/>
                            <ProfileInfoCard
                                title="Інформація по квартирі"
                                description=""
                                info={{
                                    "Номер_квартири": `${flat.flat_number}`,
                                    "Загальна_площа": `${flat.total_square}`,
                                    "Житлова_площа": `${flat.living_square}`,
                                    "Поверх": `${flat.floor_number}`,
                                    "Під'їзд": `${flat.entrance_number}`,
                                }}
                                social={[]}
                                action={{route: "", tooltip: "Edit Profile"}}
                                shadow={false}
                            />
                            <Divider orientation="vertical" sx={{mx: 0}}/>
                        </Grid>
                        <Grid item xs={12} md={8} xl={10}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-1}
                                    py={1}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Прилади обліку
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={3}>
                                    <DataTable
                                        table={MetersTableData(flatMeters)}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                        queryPageIndex={0}
                                        queryPageSize={1000}
                                        totalCount={flatMeters.length}
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </Header>
            <Footer/>
        </DashboardLayout>
    );
}

export default Flat;
