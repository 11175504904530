import {OsbbApi} from "../api/api";

const SET_SERVICESTAT = "SET_SERVICESTAT"
const GET_SERVICESTATS = "GET_SERVICESTATS"
const GET_SERVICESTAT = "GET_SERVICESTAT"
const SERVICESTATS_PAGE_CHANGED = 'SERVICESTATS_PAGE_CHANGED';
const SERVICESTATS_PAGE_SIZE_CHANGED = "SERVICESTATS_PAGE_SIZE_CHANGED";
const SERVICESTATS_TOTAL_COUNT_CHANGED = "SERVICESTATS_TOTAL_COUNT_CHANGED";
const SERVICESTATS_TOGGLE_IS_FETCHING = "SERVICESTATS_TOGGLE_IS_FETCHING";

let initialState = {
    servicestat: null,
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    isFetching: false,
    servicestats: []
}

const servicestatsReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SERVICESTATS_PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case SERVICESTATS_PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case SERVICESTATS_TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case GET_SERVICESTATS:
            return {
                ...state,
                servicestats: action.value
            }
        case GET_SERVICESTAT:
            return {
                ...state,
                servicestat: action.value
            }
        case SERVICESTATS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        case SET_SERVICESTAT:
            return {
                ...state,
                servicestat: action.value
            }
        default:
            return state;
    }
}

export const getServiceStats = (value) => ({type: GET_SERVICESTATS, value: value})
export const setServiceStatsPageChanged = (value) => ({type: SERVICESTATS_PAGE_CHANGED, value: value})
export const setServiceStatsPageSizeChanged = (value) => ({type: SERVICESTATS_PAGE_SIZE_CHANGED, value: value})
export const setServiceStatsTotalCountChanged = (value) => ({type: SERVICESTATS_TOTAL_COUNT_CHANGED, value: value})
export const servicestatsToggleIsFetching = (isFetching) => ({type: SERVICESTATS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestServiceStats = (queryPageIndex, queryPageSize, balancedate, user) => { 
    return async (dispatch) => {
        dispatch(servicestatsToggleIsFetching(true))
        dispatch(setServiceStatsPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await OsbbApi.get_servicestats(offset, queryPageSize, balancedate ? balancedate.toISOString().slice(0, 10) : null, user)
        if (response !== undefined) {
            dispatch(setServiceStatsPageSizeChanged(queryPageSize))
            dispatch(setServiceStatsTotalCountChanged(response.count))
            dispatch(getServiceStats(response.results))
            dispatch(servicestatsToggleIsFetching(false))
        }
    }
}

export default servicestatsReducer
