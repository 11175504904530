import React, {useEffect} from "react";
import Rate from "./Rate";
import {useDispatch, useSelector} from "react-redux";
import {getRates, getRatesIsFetching} from "../../redux/rates-selectors";
import {requestRates} from "../../redux/rates-reducer";
import Loader from "../Loader/Loader";

import { ImageList } from '@material-ui/core'
import { ImageListItem }  from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';


const styles = theme => ({
    root: {
       display: 'flex',
       flexWrap: 'wrap',
       //justifyContent: 'space-around',
       overflow: 'hidden',
       //backgroundColor: theme.palette.background.paper,
       'line-height': 1,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: '#4babf0',// theme.palette.primary.light,
    },
  });

function Rates(props) {
    const { classes } = props;
    const rates = useSelector(getRates)
    const isRateFetching = useSelector(getRatesIsFetching)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(requestRates())
    }, [])

    return (
        <>
            {isRateFetching && (
                <Loader/>
            )}
            {
                !isRateFetching && (
                    <div className={classes.root}>
                        <ListSubheader className={classes.title} component="div">Мої тарифи</ListSubheader>
                        <ImageList className={classes.gridList}  cols={rates.length < 4 ? rates.length : 4.5} rowHeight ={'auto'}  >
                            {rates.map((rate) => (
                                <ImageListItem key={rate.id}>
                                    <Rate rate={rate}/>
                                </ImageListItem >
                            ))}
                        </ImageList >
                    </div>
                )
            }
        </>
    );
}

Rates.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(Rates);
