import {useState, useEffect  } from "react";
import {useSelector, useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {getOsbbSummary} from "../../redux/summary-reducer";
import {get_OsbbSummary} from "../../redux/summary-selectors";


function OsbbSummary( { balancedate }) {
    const dispatch = useDispatch()
    const osbbsummary = useSelector(get_OsbbSummary)
    const date = new Date();
    const [isCurrentPeriod, setIsCurrentPeriod] = useState(date.toISOString().slice(0, 10)===balancedate.toISOString().slice(0, 10))

  useEffect(() => {
    dispatch(getOsbbSummary(balancedate))
    setIsCurrentPeriod(date.toISOString().slice(0, 10)===balancedate.toISOString().slice(0, 10))
  }, [balancedate])


    return ( ( osbbsummary && osbbsummary.results ) ?
    <div >
        <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={2} >
                  <Card sx={{ height: "100%" }}>
                      <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                          <MDBox lineHeight={1.25}>
                              <MDTypography variant="button" fontWeight="light" color="text">
                                {"Всього по всім послугам"}
                              </MDTypography>
                          </MDBox>
                      </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Борг на початок періоду"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}} >
                                  {(osbbsummary.results.length>0)?
                                        (osbbsummary.results[0].total_balance_vh?
                                            osbbsummary.results[0].total_balance_vh:
                                            0
                                        ):
                                    0}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Нарахування"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}}>
                                  {(osbbsummary.results.length>0 && !isCurrentPeriod)?
                                    osbbsummary.results[0].total_accrual:
                                    '-'}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Оплати"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}} >
                                  {(osbbsummary.results.length>0)?
                                    osbbsummary.results[0].total_payments_buh:
                                    0}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5} >
                  <Card>
                          <MDBox  display="flex" justifyContent="space-between" pt={1} px={2}>
                              <MDBox  lineHeight={1.25}>
                                  <MDTypography variant="button" fontWeight="light" color="text">
                                  {"Борг на кінець періоду"}
                                  </MDTypography>
                                  <MDTypography style={{color: '#4babf0'}} >
                                  {(osbbsummary.results.length>0)?
                                        (osbbsummary.results[0].total_balance_ish?
                                            osbbsummary.results[0].total_balance_ish:
                                            0):
                                        0}
                                  </MDTypography>
                              </MDBox>
                          </MDBox>
                  </Card>
              </Grid>
        </Grid>
    </div>
    : <div></div>
    );
}

export default OsbbSummary;
