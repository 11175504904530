// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import Debtors from "layouts/dashboard/components/Debtors/Debtors";
import React, {useState} from "react";
import OsbbServiceSummary from "../../components/Summary/OsbbServiceSummary";
import Charts from "../../components/Charts/Charts";
import ServiceStats from "./components/ServiceStats/ServiceStats";


function AnalyticsDashboard() {
  const [BalanceDate, setBalanceDate] = useState(new Date());
  const handleDateChange = (BalanceDate) => {
      setBalanceDate(BalanceDate);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <OsbbServiceSummary onChange={handleDateChange}/>
      <Charts user={false}/>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Card  >
              <Debtors balancedate={BalanceDate} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <ServiceStats balancedate={BalanceDate} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AnalyticsDashboard;
