// @mui material components
import { useRef, useEffect, useState } from "react";
import moment from 'moment';
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import {
  Widget,
  renderCustomComponent,
  setBadgeCount,
  deleteMessages,
  toggleWidget,
  dropMessages,
  isWidgetOpened,
} from "react-chat-widget";
import MDMessage from "components/Chat/MDMessage";
import MDUserMessage from "components/Chat/MDUserMessage";
import "assets/react-chat-widget/styles.css";
import {getUser} from "../../redux/auth-selectors";
import {useDispatch, useSelector} from "react-redux";
import {getIsReady, getCommonMessages} from "../../redux/chat-messages-selectors";
import {sendMessage, startMessagesListening, stopMessagesListening} from "../../redux/chat-reducer";
import {getChatReadyStatus, getNewMessage} from "../../redux/chat-selectors";
import {requestMessages} from "../../redux/chat-messages-reducer";
import {count} from "react-table/src/aggregations";


const Chat = ({url, chat, title}) => {
 // const [isChatOpened, setIsChatOpened] = useState(false)
  const newMessageCount = useRef(0);
  const lastTimestamp = useRef('1970-01-01');
  const dispatch = useDispatch()
  const newMessage = useSelector(getNewMessage)
  const messages = useSelector(state => getCommonMessages(state, 0))
  const user = useSelector(getUser)
  const readyStatus = useSelector(getChatReadyStatus)

 /* useEffect(() => {
    dispatch(requestMessages(0))
  }, []) */

  useEffect(() => {
    dispatch(startMessagesListening(0))
    return () => {
      dispatch(stopMessagesListening(0))
    }
  }, [])

  useEffect(() => {
    dropMessages()
    addMessages(messages)
  }, [messages])

  useEffect(() => {
    if (newMessage != null) {
      if(newMessage.type === "chat_message" && newMessage.target === undefined ) {
        addMessages([newMessage])
      }
      if (newMessage.type === "user_join") {
        lastTimestamp.current = newMessage.timestamp
        dispatch(requestMessages(0))
      }
    }
  }, [newMessage])


  function addMessage(component, message) {
        renderCustomComponent(component, {
          message: message.content,
          username: message.user.username, // додати будинок квартиру для мешканця
          time: moment(new Date(message.timestamp)).format("DD MMM YY HH:mm"),
        }, false, message.id);
  }

  function addMessages(messages) {
    messages.forEach((message)=> {
      if (message.user.id !== user.id) {
        if (message.timestamp > lastTimestamp.current) {
          newMessageCount.current = newMessageCount.current + 1
        }
        setBadgeCount(newMessageCount.current);
        addMessage(MDMessage, message);
      } else {
        addMessage(MDUserMessage, message);
      }
    });
  };

  /*const handleScroll = (event) => {
    let scrollTop = event.srcElement.body.scrollTop,
        itemTranslate = Math.min(0, scrollTop/3 - 60);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);
*/

  const handleNewUserMessage = (newMessage) => {
    dispatch(sendMessage(newMessage, null))
    deleteMessages(1, null)
  };

  const handleSubmit = (newMessage) => {
  };

  const handleToggle = (status) =>  {
    newMessageCount.current = 0
    setBadgeCount(newMessageCount.current);
    //setIsChatOpened(status);
  };

return (
    <>
    {readyStatus === 'ready' && (
    <Widget
      chatId={chat}
      handleNewUserMessage={handleNewUserMessage}
      handleSubmit={handleSubmit}
      fullScreenMode={false}
      handleToggle={handleToggle}
      title={title}
      subtitle=""
      showTimeStamp={true}
      resizable={true}
      emojis={true}
      showBadge={true}
    />
   )}
    {readyStatus === 'pending' && (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="60px"
      height="60px"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
    >
      <Icon fontSize="small" color="inherit">
        cloud_off
      </Icon>
    </MDBox>
   )}
   </>
  );
}

Chat.defaultProps = {
  //url: "ws://127.0.0.1:9542/ws/chat/",
  url: "wss://api-kv.osbb.gerc.ua/ws/chat/",
  chat: "all",
  title: "Загальний чат"
};

Chat.propTypes = {
  url: PropTypes.string,
  chat: PropTypes.string,
  title: PropTypes.string,
};

export default Chat
