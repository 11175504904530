import {createSelector} from "reselect";

export const getMessages = (state, user) => {
    return user && user.id in state.chatMessages.messages ? state.chatMessages.messages[user.id] : [];
}

export const getCommonMessages = (state) => {
    return 0 in state.chatMessages.messages ? state.chatMessages.messages[0] : [];
}

export const getNextMessages = (state, user) => {
    return user && user.id in state.chatMessages.nextMessages ? state.chatMessages.nextMessages[user.id] : []
}

export const getIsReady = (state, user) => {
    return user && user.id in state.chatMessages.isReady ? state.chatMessages.isReady[user.id] : false
}

export const getMessagesOffset = (state, user) => {
    return user && user.id in state.chatMessages.offset ? state.chatMessages.offset[user.id] : 0
}

export const getHasMoreMessages = (state, user) => {
    return user && user.id in state.chatMessages.hasMoreMessages ? state.chatMessages.hasMoreMessages[user.id] : false
}

export const getMessagesLimit = (state) => {
    return state.chatMessages.messagesLimit
}
