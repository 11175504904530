import MDTypography from "components/MDTypography";

export default function Data(payshist) {
  return {
    columns: [
      { Header: "Особовий рахунок", accessor: "personal_account", align: "center", width : "max-content" },
      { Header: "Послуга", accessor: "service_name", align: "left", width : "min-content" },
      { Header: "Тип платежу", accessor: "name_vid_plat", align: "center", width : "min-content" },
      { Header: "Сумма", accessor: "summa", align: "center", width : "max-content" },
      { Header: "Дата оплати", accessor: "data_plat", align: "center", width : "max-content" },
      { Header: "Дата обробки", accessor: "data", align: "center", width : "max-content" },
      { Header: "Коментар", accessor: "comment", align: "center", width : "max-content" },

    ],

    rows: payshist.map((pay) => ({
      personal_account: (
          <MDTypography component="span" variant="body2" color="text"  >
           {pay.personal_account.number} 
          </MDTypography>
      ),
      service_name: (
        <MDTypography component="span" variant="body2" color="text"  >
          {pay.service_name}
        </MDTypography>
      ),
      name_vid_plat: (
          <MDTypography component="span" variant="body2" color="text"  >
            {pay.name_vid_plat}
          </MDTypography>
      ),
      summa: (
          <MDTypography component="span" variant="body2" color="text"  >
            {pay.summa}
          </MDTypography>
      ),
      data_plat: (
          <MDTypography component="span" variant="body2" color="text"  >
            {pay.data_plat}
          </MDTypography>
      ),
      data: (
        <MDTypography component="span" variant="body2" color="text"  >
           {pay.data}
        </MDTypography>
      ),
      comment: (
        <MDTypography ccomponent="span" variant="body2" color="text"  >
          {pay.comment ? pay.comment : '-' }
        </MDTypography>
      ),    })),
  };
}
