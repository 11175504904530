import React, {useEffect} from "react"
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DataTable from "../../components/Tables/DataTable";
import Loader from "../../components/Loader/Loader";
import ProfileEditForm from "../profile/ProfileEditForm";
import {requestProfiles} from "../../redux/profiles-reducer";
import {
    getProfiles,
    getIsFetching,
    getQueryPageIndex,
    getQueryPageSize,
    getTotalCount
} from "../../redux/profiles-selectors";
import ProfilesTableData from "./data/ProfilesTableData";
import Layout from "./Layout";


export const Profiles = () => {
    const location = useLocation();
    const locationSearch = location.search;
    const queryPageIndex = useSelector(getQueryPageIndex)
    const queryPageSize = useSelector(getQueryPageSize)
    const totalCount = useSelector(getTotalCount)
    const profiles = useSelector(getProfiles)
    const isFetching = useSelector(getIsFetching)

    let { columns, rows } = ProfilesTableData(profiles);

    const [openEdit, setOpenEdit] = React.useState(false);
    const [profile, setProfile] = React.useState({});

    const handleEdit = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const dispatch = useDispatch()

    useEffect( () => {
        dispatch(requestProfiles(queryPageIndex, queryPageSize, '0', locationSearch))
    }, [locationSearch,openEdit])

    const onDataChanged = (queryPageIndex, queryPageSize, locationSearch) => {
        dispatch(requestProfiles(queryPageIndex, queryPageSize, '0', locationSearch))
    }



    return (
    <Layout>
        {isFetching && (
            <Loader />
        )}
        {!isFetching && (
            <>
                <MDBox pt={1} pb={0} >
                    <Card>
                    <MDBox
                        mx={2}
                        mt={-1}
                        py={1}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <MDTypography variant="h6" color="white">
                            { "Профілі" }
                        </MDTypography>
                        <MDTypography variant="h5" style={{color: "white" }} component={Link} to={'#'} onClick={handleEdit} >
                                    <Tooltip title={'Додати новий профіль'} placement="top">
                                        <Icon>add</Icon>
                                    </Tooltip>
                        </MDTypography>
                    </MDBox>
                        <DataTable
                            table={{columns, rows}}
                            isSorted={true}
                            entriesPerPage={true}
                            showTotalEntries={true}
                            noEndBorder
                            queryPageIndex={queryPageIndex}
                            queryPageSize={queryPageSize}
                            totalCount={totalCount}
                            onTableDataChanged={onDataChanged}
                        />
                    </Card>
                </MDBox>
                <ProfileEditForm open={openEdit} handleClose={handleCloseEdit} profile={profile} />
            </>
        )}
    </Layout>
    )
}
