import {OsbbApi} from "../api/api";

const SET_OSBB = "SET_OSBB"
const SET_OSBB_POSITIONS = "SET_OSBB_POSITIONS"
const SET_OSBB_STATS = "SET_OSBB_STATS"
const SET_OSBB_USEFULLINFO = "SET_OSBB_USEFULLINFO"
const SET_OSBB_DOCS="SET_OSBB_DOCS"

let initialState = {
    osbb: null,
    osbbpositions: [],
    osbbstats:[],
    usefullinfo:[],
    osbbdocs: [],
}

const osbbReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OSBB:
            return {
                ...state,
                osbb: action.value
            }
        case SET_OSBB_STATS:
                return {
                    ...state,
                    osbbstats: action.value
                }
        case SET_OSBB_POSITIONS:
                return {
                    ...state,
                    osbbpositions: action.value
                }
        case SET_OSBB_USEFULLINFO:
                return {
                    ...state,
                    usefullinfo: action.value
                }
        case SET_OSBB_DOCS:
                return {
                    ...state,
                    osbbdocs: action.value
                }
        default:
            return state;
    }
}

export const setOsbb = (value) => ({type: SET_OSBB, value: value})
export const setOsbbPositions = (value) => ({type: SET_OSBB_POSITIONS, value: value})
export const setOsbbStats = (value) => ({type: SET_OSBB_STATS, value: value})
export const setUsefullInfo = (value) => ({type: SET_OSBB_USEFULLINFO, value: value})
export const setOsbbDocs = (value) => ({type: SET_OSBB_DOCS, value: value})


export const getOsbb = () => {
    return (dispatch) => {
        OsbbApi.get().then(data => {
              if (data !== undefined) {
                dispatch(setOsbb(data.results[0]))
              }
            });
    }
}

export const getUsefullInfo = () => {
    return (dispatch) => {
        OsbbApi.get_UsefullInfo().then(data => {
                dispatch(setUsefullInfo(data.results))
            });
    }
}

export const getOsbbPositions = () => {
    return (dispatch) => {
        OsbbApi.get_positions().then(data => {
                dispatch(setOsbbPositions(data.results))
            });
    }
}

export const getOsbbStats = (user) => {
    return (dispatch) => {
        OsbbApi.get_stats(user).then(data => {
              if(data !== undefined) {
                dispatch(setOsbbStats(data.results))
              }
            });
    }
}

export const saveOsbbInfo = async (data) => {
    try {
        let response = await OsbbApi.updateOsbbInfo(data)
        return response
    } catch (error) {
        console.error("*** saveOsbbInfo Щось пішло не так ! ", error)
        return (error);
    }
}

export const saveOsbbUsefullInfo = async (data) => {
    try {
        let response;
            if(data.id === undefined){
                response = await OsbbApi.createUsefullInfo(data)
            } else {
                response = await OsbbApi.updateUsefullInfo(data)
            }
        return response
    } catch (error) {
        console.error("*** saveOsbbUsefullInfo Щось пішло не так ! ", error)
        return (error);
    }
}

export const requestDelUsefullInfo = async (Id) => {
    try {
        const response = await OsbbApi.deleteUsefullInfo(Id)
        console.log("*** Успішно видалено! ", Id, response)
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
    }
}

export const savePositions = async (data) => {
    try {
        let response;
            if(data.id === undefined){
                response = await OsbbApi.createPositions(data)
            } else {
                response = await OsbbApi.updatePositions(data)
            }
        return response
    } catch (error) {
        console.error("*** savePositions Щось пішло не так ! ", error)
        return (error);
    }
}

export const requestDelPositions = async (Id) => {
    try {
        const response = await OsbbApi.deletePositions(Id)
        console.log("*** Успішно видалено! ", Id, response)
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
    }
}

export const getOsbbDocs = (doc_type, data, fileName) => {
    return (dispatch) => {
        OsbbApi.get_OsbbDocs(doc_type, data, fileName).then(data => {
                dispatch(setOsbbDocs(data.results))
            });
    }
}

export const saveOsbbDoc = async (data) => {
    try {
        let response;
            if(data.id === undefined){
                response = await OsbbApi.createOsbbDoc(data)
            } else {
                response = await OsbbApi.updateOsbbDoc(data)
            }
        return response
    } catch (error) {
        console.error("*** saveOsbbDoc Щось пішло не так ! ", error)
        return (error);
    }
}

export const requestDelDoc = async (Id) => {
    try {
        const response = await OsbbApi.deleteOsbbDoc(Id)
        console.log("*** Успішно видалено! ", Id, response)
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
    }
}



export default osbbReducer
