import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FileField from "./FileField"
import arrayMutators from 'final-form-arrays'
import { FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';

import {sendMeterData, getRecentMeterDataAmount} from '../../../redux/flat-meters-reducer';
import {getRecentAmount} from '../../../redux/flat-meters-selectors';

function AddMeterDataForm({open, editData, handleClose, selectedMeterId}) {
    const dispatch = useDispatch();

    const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        sendMeterData( {
                "source": "WEB",
                "amount": values.amount,
                "img": values.img?values.img[0]:undefined,
            }, selectedMeterId ).then( (response) => {
                console.log('*** response', response);
                if (response.status===200) {
                            alert('Показники прийнято!');
                            handleClose();
                }
                else if (response.status >= 400 && response.status < 500) {
                            //setError('amount','hgkhgkjhkjh');
                            setError(response.data);
                            console.log('*** errors',errors)
                            alert(JSON.stringify(response.data));
                            return errors;
                }
                else if (response.status === 500){
                            setError(response.data);
                            alert(response.statusText);
                            return errors;
                }
            });
            setError(FORM_ERROR, 'Failed');
            console.log('*** errors1',errors)
            if (Object.entries(errors).length > 0) {
                console.log('*** errors2',errors)
                return errors
            }
    };

    const handleCloseDialog = () => {
        handleClose();
    };
    const recentAmount = useSelector(getRecentAmount)

    useEffect(() => {
        (getRecentMeterDataAmount(selectedMeterId))
    }, [])


    return (
        <>
            {editData && (
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle id="responsive-dialog-title">
                        {editData.id ? ('Редагування') : ('Введення')} поточних показників
                    </DialogTitle>
                    <DialogContent>
                            <Form
                                onSubmit={onSubmit}
                                mutators={{
                                    ...arrayMutators
                                }}
                                validate={values => {
                                    let errors = {}
                                    if (!values.amount) {
                                        errors.amount = 'Додайте поточні показники'
                                        }
                                    return errors
                                }}
                                initialValues={editData}
                                render={({errors,
                                        submitError, submitErrors, handleSubmit,
                                        form: {mutators: {push, pop}},
                                        submitting, pristine, values
                                }) => (
                                    <MDBox component="form" onSubmit={handleSubmit}>
                                        <Card style={{height: "100%"}}>
                                                <MDBox >
                                                    <MDBox >
                                                        <MDTypography variant="body1">Попередні показання:</MDTypography>
                                                               <span >{recentAmount}</span>
                                                    </MDBox>
                                                    <MDBox >
                                                        <MDTypography variant="body1">Поточні показання:</MDTypography>
                                                        <Field name="amount">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {(props.meta.error || props.meta.submitError) && props.meta.touched &&
                                                                <span style={{ color:'#FA0000'}}>{props.meta.error || props.meta.submitError}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>

                                                    <MDBox>
                                                        <FileField name="img" />
                                                    </MDBox>
                                                </MDBox>

                                        </Card>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" fullWidth type="submit"
                                                    disabled={submitting || pristine}>
                                                Зберегти
                                            </MDButton>
                                        </MDBox>
                                        {/* <pre>submitErrors: {JSON.stringify(submitErrors, 0, 2)}</pre>
                                        <pre>errors: {JSON.stringify(errors, 0, 2)}</pre> */}
                                    </MDBox>
                                )}
                            />

                    </DialogContent>
                    <DialogActions>
                        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                            Закрити
                        </MDButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default AddMeterDataForm;
