import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";

const Layout = ({children}) => {
    return (
    <DashboardLayout>
        <DashboardNavbar/>
        {children}
        <Footer/>
    </DashboardLayout>
    )
}

export default Layout;
