import React from 'react'
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import icon_export_excel from "assets/images/icons8-export-excel-48b.png";

export const ExportToExcel = ({ RepHeaders, reportsData, fileName }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (reportsData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(reportsData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const exportToCSV2 = ( headers, reportsData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(reportsData);
        /* custom headers */
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };

      const onClickButton = (e) => {
           // exportToCSV(reportsData, fileName);
           exportToCSV2(RepHeaders, reportsData, fileName);
      }

    return (
         <MDButton  size="small" sx={{ m: 1, minWidth: 165 }} variant="outlined" color="info" onClick={onClickButton} >
            Експорт &nbsp;<MDAvatar src={icon_export_excel} size="xs" />
         </MDButton>
    );
};
