import {FlatApi} from "../api/api";

const SET_FLAT_METERS = "SET_FLAT_METERS"
const FLAT_METERS_TOGGLE_IS_FETCHING = "FLAT_METERS_TOGGLE_IS_FETCHING";
const SET_METER_RECENT_AMOUNT ="SET_METER_RECENT_AMOUNT"

let initialState = {
    isFetching: false,
    meters: [],
    meterRecentAmount: 0
}

const flatMetersReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SET_FLAT_METERS:
            return {
                ...state,
                meters: action.value
            }
        case SET_METER_RECENT_AMOUNT:
            return {
                ...state,
                meterRecentAmount: action.value
            }
        case FLAT_METERS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setFlatMeters = (value) => ({type: SET_FLAT_METERS, value: value})
export const setMeterRecentAmount = (value) => ({type: SET_METER_RECENT_AMOUNT, value: value})
export const flatMetersToggleIsFetching = (isFetching) => ({type: FLAT_METERS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestFlatMeters = (flatId) => {
    return async (dispatch) => {
        dispatch(flatMetersToggleIsFetching(true))
        let response = await FlatApi.getFlatMetters(flatId)
        if (response !== undefined) {
            dispatch(setFlatMeters(response.results))
            dispatch(flatMetersToggleIsFetching(false))
        }
    }
}


export const sendMeterData = async (data, meterId) => {
    try {
        let response = await FlatApi.sendMeterData(data, meterId)
        return response
    } catch (error) {
        console.error("*** sendMeterData Щось пішло не так ! ", error)
        return (error);
    }
}

export const getRecentMeterDataAmount = async (meterId) => {
    return async (dispatch) => {
        let response = await FlatApi.getRecentMeterData(meterId)
        if (response !== undefined) {
            dispatch(setMeterRecentAmount(response.results))
        }
    }
}


export default flatMetersReducer
