import React from "react"
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';
import { savePositions } from '../../redux/osbb-reducer';
import {requestProfiles} from "../../redux/profiles-reducer";
import { getProfiles } from "../../redux/profiles-selectors";
import ProfileEditForm from "../../layouts/profile/ProfileEditForm";

function PositionsEditForm({open, editInfo, handleClose}) {
    const dispatch = useDispatch();
    const profiles = useSelector(getProfiles)

    const [openEdit, setOpenEdit] = React.useState(false);
    const [profile, setProfile] = React.useState({});

    const handleEdit = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    React.useEffect( () => {
        dispatch(requestProfiles(0, 1000, '1' ))
    }, [openEdit])

    const onSubmit = async values => {
        let errors = {}
        const setError = (key, value) => {
            errors = setIn(errors, key, value)
        }
        let desc = values.description === undefined ? '' : values.description
        savePositions({
                "id": values.id,
                "name": values.name,
                "description": desc,
                "profile": values.profile && values.profile.id,
            });
        handleClose();
    }
    const handleCloseDialog = () => {
        handleClose();
    };

    const SelectAdapter = props => {
        return (<>
            <Autocomplete style={{ width: "100%"}}
                name={props.input.name}
                value={props.input.value}
                isOptionEqualToValue={(option) => props.input.value && option.id === props.input.value.id}
                onChange={(event, newValue) => props.input.onChange(newValue) }
                options={props.options}
                getOptionLabel={(profile) => profile && profile.last_name+' '+profile.first_name+' '+profile.second_name}
                renderInput={(params) => <TextField {...params}  />}
            />
            {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
        </>
        )
      };

    return (
        <>
            {editInfo && (
                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle id="responsive-dialog-title">
                        {editInfo.id ? ('Редагувати') : ('Додати')} контакт
                    </DialogTitle>
                    <DialogContent>
                            <Form
                                onSubmit={onSubmit}
                                mutators={{
                                    ...arrayMutators
                                }}
                                validate={values => {
                                    const errors = {}
                                    if (!values.name) {
                                        errors.name = 'Додати посаду'
                                        }
                                    return errors
                                }}
                                initialValues={editInfo}
                                render={({
                                        submitError, submitErrors, handleSubmit,
                                        form: {mutators: {push, pop}},
                                        submitting, pristine, values
                                }) => (
                                    <MDBox component="form" onSubmit={handleSubmit}>
                                        <Card style={{height: "100%"}}>
                                                <MDBox >
                                                    <MDBox >
                                                        <MDTypography variant="body1">Посада:</MDTypography>
                                                        <Field name="name">
                                                            {props => (
                                                            <>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                                />
                                                                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDTypography variant="body1">Опис:</MDTypography>
                                                        <Field name="description">
                                                            {props => (<>
                                                                <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value}
                                                                        onChange={props.input.onChange}
                                                                        multiline rows={5} />
                                                            {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
                                                            </>
                                                            )}
                                                        </Field>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} >
                                                            <MDTypography variant="body1">Профіль:</MDTypography>
                                                            <MDTypography variant="body1" color="secondary" component={Link} to={'#'} onClick={handleEdit} >
                                                                <Tooltip title={'Додати новий профіль'} placement="top">
                                                                    <Icon>add</Icon>
                                                                </Tooltip>
                                                            </MDTypography>
                                                        </MDBox>
                                                        <Field name="profile"
                                                            component={SelectAdapter}
                                                            options={profiles}
                                                        />
                                                    </MDBox>

                                                </MDBox>
                                        </Card>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" fullWidth type="submit"
                                                    disabled={submitting || pristine}>
                                                Зберегти
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>

                                )}
                            />

                    </DialogContent>
                    <DialogActions>
                        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleCloseDialog}>
                            Закрити
                        </MDButton>
                    </DialogActions>
                </Dialog>
            )}
            <ProfileEditForm open={openEdit} handleClose={handleCloseEdit} profile={profile} />
        </>
    );
}

export default PositionsEditForm;
