import {createSelector} from "reselect";

const getDeviceSelector = (state) => {
    return state.device.device
}

export const getDevice = createSelector(getDeviceSelector, (device) => {
    return device;
})

export const getDeviceIsFetching = (state) => {
    return state.device.isFetching
}
