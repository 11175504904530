import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import {useLocation} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import BalanceDatePicker from "../../components/BalanceDatePicker/BalanceDatePicker";
import Table from "../../components/Tables/Table";
import MDBox from "../../components/MDBox";
import Loader from "../../components/Loader/Loader";
import {getUser} from "../../redux/auth-selectors";
import { requestMettershist, requestMettershistReport } from "../../redux/mettersdata-reducer";
import { requestServiceTypes } from "../../redux/servicetype-reducer";
import { getServiceTypes } from "../../redux/servicetype-selectors";
import {
    getMettershist,
    getMettershistReport,
    getMettershistIsFetching,
    getMettershistQueryPageIndex,
    getMettershistQueryPageSize,
    getMettershistTotalCount
} from "../../redux/mettersdata-selectors";
import Layout from "./Layout";
import MettershistTableData from "./data/MettersHistTableData";
import { ExportToExcel } from './ExportToExcel'

export const MettersData = () => {
    const location = useLocation();
    const locationSearch = location.search;
    const queryPageIndex = useSelector(getMettershistQueryPageIndex)
    const queryPageSize = useSelector(getMettershistQueryPageSize)
    const totalCount = useSelector(getMettershistTotalCount)
    const mettershist = useSelector(getMettershist)
    const isFetching = useSelector(getMettershistIsFetching)
    const servicetypes = useSelector(getServiceTypes)
    const reportsData = useSelector(getMettershistReport)
    const currentUser = useSelector(getUser)

    const [meter, setMeter] = useState();
    const [selectedDate, setselectedDate] = useState(new Date());
    const [selectedDate2, setselectedDate2] = useState(new Date());
    const [serviceType, setServiceType] = useState(0);
    const [role, setRole] = useState((currentUser && currentUser.role) ? currentUser.role :'default')


    const handleChange = (event) => {
        setServiceType(event.target.value);
    };

    const handleDateChange = (selectedDate) => {
        setselectedDate(selectedDate);
    };

    const handleDateChange2 = (selectedDate) => {
        setselectedDate2(selectedDate);
    };

    useEffect(() => {
        dispatch(requestMettershistReport(selectedDate,  selectedDate2, serviceType))
    }, [selectedDate, selectedDate2, serviceType])

    const [flatId, setFlatId] = useState('0');
    let { columns_meter, rows_meter } = MettershistTableData(mettershist, flatId);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestServiceTypes())
    }, [])

    useEffect(() => {
        dispatch(requestMettershist(selectedDate, serviceType, queryPageIndex, queryPageSize, meter, selectedDate2, locationSearch))
    }, [selectedDate, selectedDate2, serviceType, locationSearch])

    const onDataChanged = ( queryPageIndex, queryPageSize) => {
        dispatch(requestMettershist(selectedDate, serviceType, queryPageIndex, queryPageSize, meter, selectedDate2, locationSearch))
    }

    const fileName = "HomeMetersData";

    const RepHeaders = ['№ кв',	'Назва','№ Лічільника',	'Покази лічильника на :','Покази лічильника на :','Різниця, кВт','Дата передачі']

    return (
        <Layout>
            {isFetching && (
                <Loader />
            )}
            {!isFetching && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={2} >
                            <MDBox pt={1} px={2} >
                                <BalanceDatePicker selecteddate={selectedDate} onChange={handleDateChange} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} lg={2} >
                            <MDBox pt={1} >
                                <BalanceDatePicker selecteddate={selectedDate2} onChange={handleDateChange2} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} >
                            <MDBox pt={1} >
                                <MDBox lineHeight={2}>
                                    <FormControl sx={{ m: 1, minWidth: 165 }} size="medium">
                                        <InputLabel id="select-serv">Послуга</InputLabel>
                                        <Select
                                            labelId="select-serv"
                                            id="select-serv"
                                            name={'-'}
                                            value={serviceType}
                                            label="Послуга"
                                            onChange={handleChange}
                                        >
                                            {servicetypes.map((serv) => (
                                                <MenuItem key={serv.id} value={serv.id}>{serv.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </MDBox>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2} >
                            <MDBox pt={1} px={1}>
                                <MDBox lineHeight={2.5}>
                                    { role === 'admin' && ( <ExportToExcel reportsData={reportsData} RepHeaders={RepHeaders} fileName={fileName} /> )}
                                </MDBox>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} >
                            <Table
                                title="Історія передачі показань лічильників"
                                columns={columns_meter}
                                rows={rows_meter}
                                queryPageIndex={queryPageIndex}
                                queryPageSize={queryPageSize}
                                totalCount={totalCount}
                                onChanged={onDataChanged}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Layout>
    )
}
