import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import icon_user from "assets/images/icon-user.png";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import MDButton from "../MDButton";
import {useSelector} from "react-redux";
import {getUser} from "../../redux/auth-selectors";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

function Messages({title, messages, shadow, nextMessagesHandler, hasMoreMessages, profile, chatUser, changeChatWithUser, newMessages}) {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const [isAutoScroll, setIsAutoScroll] = useState(false)
    const currentUser = useSelector(getUser)
    const messagesAnchorRef = useRef(null)
    useEffect(() => {
        if (isAutoScroll) {
            messagesAnchorRef.current?.scrollIntoView({behavior: 'smooth'})
        }
    }, [messages])

    const scrollHandler = (e) => {
        const element = e.currentTarget

        if(element.scrollTop === 0 ) {
           // alert(1);
        }
        if (Math.abs((element.scrollHeight - element.scrollTop) - element.clientHeight) < 100) {
            !isAutoScroll && setIsAutoScroll(true)
        } else {
            isAutoScroll && setIsAutoScroll(false)
        }
    }

    const changeChat = (user) => {
        if (profile.user == currentUser.id) {
            changeChatWithUser(user)
        }
    }

    const renderMessages = (messages) => {
        return messages.map(({id, user, content, timestamp}) => (
            <MDBox className={ currentUser.id === user.id ? "": ""} key={user.username + timestamp} component="li" display="flex" alignItems="center" py={1} mb={1}>
                <MDBox mr={2}>
                    {profile.user == currentUser.id && (
                      <MDAvatar src={icon_user} alt="" shadow="md" onClick={ () => changeChat(user)}/>
                    )}
                    {profile.user != currentUser.id && (
                        <MDAvatar src={icon_user} alt="" shadow="md" />
                    )}
                    <MDTypography variant="button" color="text" fontWeight="regular">
                        {user.username}
                    </MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <MDTypography variant="button" fontWeight="medium" color="text">
                        {content}
                    </MDTypography>
                    <MDTypography variant="caption" color="text">
                        {moment(new Date(timestamp)).format("DD MMM YY HH:mm")}
                    </MDTypography>
                </MDBox>
            </MDBox>
        ));
    }

    return (
        <Card sx={{height: "100%", boxShadow: !shadow && "none"}}>
            <MDBox pt={2} px={2}>
              <MDTypography  variant="h6" fontWeight="medium" textTransform="capitalize" color="text">
                  { profile.user == currentUser.id && (
                  <Grid item xs={12} md={6} lg={10} sx={{ ml: "auto" }}>
                      <AppBar position="static">
                          <Tabs orientation={tabsOrientation} value={tabValue} >
                              <Tab
                                  onClick={(e) => changeChat(currentUser)}
                                  label="All my messages"
                                  icon={
                                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                          email
                                      </Icon>
                                  }
                              />
                              { currentUser.id != chatUser.id && (
                              <Tab
                                  label={chatUser.username}
                                  icon={
                                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                          email
                                      </Icon>
                                  }
                              />
                              )}
                              {newMessages > 0 && (
                                      <Tab
                                          label={newMessages}
                                          icon={
                                              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                                                  email
                                              </Icon>
                                          }
                                      />
                                  )
                              }
                          </Tabs>
                      </AppBar>
                  </Grid>
                  )}
              </MDTypography>
            </MDBox>
            <MDBox p={2} sx={{overflowY: "auto", height: "25rem"}} onScroll={scrollHandler}>
                {hasMoreMessages && (
                  <MDButton variant="gradient" fontWeight="small" color="white" fullWidth onClick={(e) => nextMessagesHandler()}>
                    Load more
                  </MDButton>
                )}
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {renderMessages(messages)}
                </MDBox>
                <MDBox ref={messagesAnchorRef}></MDBox>
            </MDBox>
        </Card>
    );
}

// Setting default props for the ProfilesList
Messages.defaultProps = {
    shadow: true,
    hasMoreMessages: false
};

// Typechecking props for the ProfilesList
Messages.propTypes = {
    title: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
    shadow: PropTypes.bool,
    hasMoreMessages: PropTypes.bool,
};

export default Messages;
