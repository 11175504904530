import {AccountApi} from "../api/api";

const SET_ACCOUNT_PROFILES = "SET_ACCOUNT_PROFILES"
const ACCOUNT_PROFILES_TOGGLE_IS_FETCHING = "ACCOUNT_PROFILES_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    profiles: []
}

const accountProfilesReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case SET_ACCOUNT_PROFILES:
            return {
                ...state,
                profiles: action.value
            }
        case ACCOUNT_PROFILES_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setAccountProfiles = (value) => ({type: SET_ACCOUNT_PROFILES, value: value})
export const accountProfilesToggleIsFetching = (isFetching) => ({type: ACCOUNT_PROFILES_TOGGLE_IS_FETCHING, value: isFetching})

export const requestAccountProfiles = (accountId) => {
    return async (dispatch) => {
        dispatch(accountProfilesToggleIsFetching(true))
        let response = await AccountApi.getAccountProfiles(accountId)
        if (response !== undefined) {
            dispatch(setAccountProfiles(response.results))
            dispatch(accountProfilesToggleIsFetching(false))
        }
    }
}

export default accountProfilesReducer
