import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { MaterialUIControllerProvider } from "context";
import store from "redux/redux-store";
import {Provider} from "react-redux";

ReactDOM.render(
    <BrowserRouter>
        <MaterialUIControllerProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </MaterialUIControllerProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
