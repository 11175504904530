import {Link} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";

function HouseAddInfo({role, houseAddinfo, handleEdit, handleDelete}) {
     return (
        <>
            {houseAddinfo && houseAddinfo.map((p) => (
                <MDBox mb={1} justifyContent="space-between" key={p.id}>
                    { role === 'admin' && (
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox>
                                <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleEdit(p)} >
                                    <Tooltip title={'Редагувати'} placement="top">
                                        <Icon>edit</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                            <MDBox >
                                <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleDelete(p.id)} >
                                    <Tooltip title={'Видалити'} placement="top">
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                        </MDBox>

                    )}
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                            { p.info }
                        </MDTypography>
                    </MDBox>
                    <Divider  />
                </MDBox>
            ))}
        </>
    );

};

export default HouseAddInfo;
