import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {useDispatch, useSelector} from "react-redux";
import {requestSetChoice} from "../../redux/polls-reducer";
import {getChoicesByQuestion} from "../../redux/polls-selectors";
import MDProgress from "components/MDProgress";

function Poll({poll}) {
    const selectedChoice = useSelector(state => getChoicesByQuestion(state, poll.id))
    const dispatch = useDispatch()
    const setChoice = (value) => {
        dispatch(requestSetChoice(poll.id, value))
    }

    return (
        <Card style={{backgroundColor: poll.background_color, height: "100%"}}>
        <MDBox mb={1.5} justifyContent="space-between" pt={1} px={2} >
            <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                {poll.question_text} ( Всього {poll.answers} )
            </MDTypography>
            {poll.choice_set.map((choice) => (
                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5} key={choice.id}>
                    <MDBox mt={0.5}>
                        <Switch disabled={poll.completed} checked={choice.id === selectedChoice} onChange={() => setChoice(choice.id)}/>
                    </MDBox>
                    <MDBox width="80%" ml={0.5} >
                        {poll.completed && (
                            <MDProgress value={choice.user_votes} color="info" variant="gradient" label={true} />
                        )}
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {choice.choice_text}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            ))}
        </MDBox>
        </Card>
    );
}

export default Poll;
