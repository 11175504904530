import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import arrayMutators from 'final-form-arrays'
import {FORM_ERROR, setIn} from "final-form";
import {Form, Field} from 'react-final-form';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {requestProfileTypes, saveProfile} from "../../redux/profile-reducer"
import {getProfileTypes} from "../../redux/profile-selectors"

function ProfileEditForm({open, handleClose, profile}) {
    const dispatch = useDispatch();
    const profiletypes = useSelector(getProfileTypes)

    useEffect(() => {
        dispatch(requestProfileTypes())
    }, [])

/*     const handleCloseDialog = () => {
        handleClose();
    }; */

    const onSubmit = (values) => {
        let errors = {}
        const setError = (key, value) => {
          errors = setIn(errors, key, value)
        }
        return saveProfile({
                values
            } ).then( (res) => {
                if (res.status >= 400) {
                    errors=res.data;
                    setError(FORM_ERROR, res.status + ' ' + res.statusText)
                    return errors;
                } else {
                    handleClose();
                };
            } );
    };

    const SelectAdapter = props => {
        console.log("SelectAdapter props ", props );
        return(
            <>
                <FormControl sx={{width: "100%" }} size="medium">
                    <Select
                        id={props.id}
                        name={props.input.name}
                        value={props.input.value}
                        displayEmpty
                        onChange={props.input.onChange}
                    >
                        {props.options.results.map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>{opt.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {props.meta.error && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error}</span>}
            </>
        )
      };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle id="responsive-dialog-title" >
             {profile.id ? ('Редагувати') : ('Додати')} дані мешканця
        </DialogTitle>
        <DialogContent>
        { profile && (
            <Form
                onSubmit={onSubmit}
                mutators={{
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {}
                    if (!values.first_name) {
                        errors.first_name = "Необхідно задати Ім'я"
                      }
                      if (!values.last_name) {
                        errors.last_name = 'Необхідно задати Прізвище'
                      }
                    return errors
                    }}
                initialValues={profile}
                render={({errors,
                        submitError, submitErrors, handleSubmit,
                        form: {mutators: {push, pop}},
                        submitting, pristine, values
                }) => (
                        <MDBox component="form" onSubmit={handleSubmit}>
                            <Card style={{ height: "100%"}}>
                                {submitError && <div style={{ color:'#FA0000'}}>{submitError}</div>}
                                    <MDBox display="flex" justifyContent="space-between" >
                                        <MDBox style={{ width: "50%"}}>
                                            <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">Прізвище:</MDTypography>
                                                <Field name="last_name">
                                                    {props => (
                                                        <>
                                                            <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                    value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                                    { (props.meta.error || props.meta.submitError) && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error || props.meta.submitError}</span>}
                                                        </>
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">Ім'я:</MDTypography>
                                                <Field name="first_name">
                                                    {props => (
                                                        <>
                                                            <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                        value={props.input.value} onChange={props.input.onChange}
                                                            />
                                                            { (props.meta.error || props.meta.submitError) && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error || props.meta.submitError}</span>}
                                                        </>
                                                    )}

                                                </Field>
                                            </MDBox>
                                            <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">По-батькові:</MDTypography>
                                                <Field name="second_name">
                                                    {props => (
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                    value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox pt={1} px={2}  pb={1}>
                                                <MDTypography variant="body1">Тип:</MDTypography>
                                                <Field name="type"
                                                        component={SelectAdapter}
                                                        options={profiletypes}
                                                  />
                                            </MDBox>
                                        </MDBox>
                                        <MDBox  style={{ width: "50%"}}>
                                            <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">Телефон 1:</MDTypography>
                                                <Field name="phone1">
                                                    {props => (
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                    value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">Телефон 2:</MDTypography>
                                                <Field name="phone2">
                                                    {props => (
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                    value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">Email:</MDTypography>
                                                <Field name="email">
                                                    {props => (
                                                    <>
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                    value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                        { (props.meta.error || props.meta.submitError) && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error || props.meta.submitError}</span>}
                                                    </>
                                                    )}
                                                </Field>
                                            </MDBox>
                                            <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">Додаткова інформація:</MDTypography>
                                                <Field name="comment">
                                                    {props => (
                                                    <>
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                    value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                        { (props.meta.error || props.meta.submitError) && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error || props.meta.submitError}</span>}
                                                    </>
                                                    )}
                                                </Field>
                                            </MDBox>

                                        </MDBox>
                                    </MDBox>
                                    <MDBox pt={1} px={2}  pb={1} >
                                                <MDTypography variant="body1">Коментар:</MDTypography>
                                                <Field name="comment2">
                                                    {props => (
                                                    <>
                                                        <MDInput type="text" style={{width: "100%"}} name={props.input.name}
                                                                    value={props.input.value} onChange={props.input.onChange}
                                                                    />
                                                        { (props.meta.error || props.meta.submitError) && props.meta.touched && <span style={{ color:'#FA0000'}}>{props.meta.error || props.meta.submitError}</span>}
                                                    </>
                                                    )}
                                                </Field>
                                    </MDBox>
                            </Card>
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" fullWidth type="submit"
                                            disabled={submitting || pristine}>
                                    Зберегти
                                </MDButton>
                            </MDBox>
                        </MDBox>
                )}
            />
          )}
        </DialogContent>
        <DialogActions>
            <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleClose} >
                Закрити
            </MDButton>
        </DialogActions>
    </Dialog>
    );
}

export default ProfileEditForm;
