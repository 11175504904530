import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// import FileViewer from "react-file-viewer";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TextField from '@mui/material/TextField';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {uk} from "date-fns/locale";
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DocEditForm from "./AddDocuments"
import { requestDelDoc } from "../../redux/osbb-reducer";
import {getOsbbDocsSel } from "../../redux/osbb-selectors";
import {getOsbbDocs} from "../../redux/osbb-reducer";
import {getUser} from "../../redux/auth-selectors";



function MeetingsDocuments() {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [fileName, setFileName] = useState();
    const [name, setName] = useState();
    const currentUser = useSelector(getUser)
    const [role, setRole] = useState((currentUser && currentUser.role) ? currentUser.role :'default')
    const osbbdocs = useSelector(getOsbbDocsSel)

    const [editDocs, seteditDocs] = useState({ "name": '',});

    const [parentState, setParentState] = useState(false);

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getOsbbDocs('meeting_documents', data, fileName))
    }, [parentState, data, fileName ])

    const handleDelete = (file) => {
        requestDelDoc(file.id);
        changeParentState();
    }

    const changeParentState = () => {
        // Вызывается после закрытия дочерней компоненты
        setParentState(!parentState);
    };

    const handleAddDocs = () => {setOpen(true); };
    const handleCloseAddDocs = () => {
        changeParentState();
        setOpen(false);
    };
    // const onError = (e) => {
    //     console.log("error in file-viewer",e);
    //   };
    // const handlePreviewDoc = (doc) => {
    //     console.log('*** doc',doc);
    //   return( <FileViewer
    //             fileType={ doc["file_type"] }
    //             filePath={ doc["file"] }
    //             //errorComponent={CustomErrorComponent}
    //             onError={onError}
    //            />
    //   )
    // };

    const handleDataChange = (selectedDate) => {
        setData(selectedDate.toISOString().split('T')[0]);
    };

    const handleFileNameChange = (event) => {
        if (event.target.value.length > 3) {
            setFileName(event.target.value)
        }
    };

    const handleClear = () => {
        setFileName();
        setData();
    };

     return (
    <DashboardLayout>
        <DashboardNavbar />
        <ListSubheader style={{ color: "#1A73E8"}}  component="div">Протоколи зборів (правління/загальних)</ListSubheader>
        <MDBox mb={1} mt={1} opacity={1} p={3}>
            <MDButton style={{align: 'center'}} variant="gradient" fontWeight="small" color="info" onClick={handleAddDocs}>
                    <Icon>upload</Icon>&nbsp;
                    Додати новий протокол
            </MDButton>
        </MDBox>
        <DocEditForm open={open} editDocs={editDocs} handleClose={handleCloseAddDocs} />

        <ListSubheader style={{ color: "#1A73E8"}}  component="div">Протоколи минулих зборів </ListSubheader>

        <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox ml={1} mb={1} lineHeight={1}>
                        <MDTypography variant="caption" color="text" fontWeight="light">
                            Шукати за датою
                        </MDTypography>
                    </MDBox>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale ={uk} >
                        <DatePicker
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat='yyyy-MM-dd'
                            closeOnSelect={true}
                            ampm={false}
                            clearable
                            value={data}
                            onChange={handleDataChange}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox ml={1} mb={1} lineHeight={1}>
                        <MDTypography variant="caption" color="text" fontWeight="light">
                            Шукати за назвою
                        </MDTypography>
                    </MDBox>
                    <MDInput type="text" style={{width: "100%"}}
                            value={name} onChange={handleFileNameChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox  mt={4} ml={3}>
                        <MDButton style={{align: 'center'}} variant="outlined" fontWeight="small" color="info" onClick={handleClear}>
                                <Icon>clear</Icon>&nbsp;
                                Очистити фільтр
                        </MDButton>
                    </MDBox>
                </Grid>
        </Grid>
        <br/>
        {osbbdocs.map((p) => (
                <MDBox key={p.id}>
                    <MDBox mb={1} mt={1} lineHeight={1} >
                        <MDTypography variant="caption" color="text" fontWeight="light">
                            {p.data}
                        </MDTypography>
                    </MDBox>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={6} lg={3}  >
        {/*                     <MDButton style={{align: 'center'}} variant="outlined" fontWeight="small" color="info" onClick={()=>handlePreviewDoc(p)}>
                                    <Icon>visibility</Icon>&nbsp;
                                    Переглянути
                            </MDButton> */}
                            <MDBox mb={1} lineHeight={1}>
                                <MDTypography  variant="h6" fontWeight="medium" textTransform="capitalize" color="info">
                                    {p.name}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                                <MDButton style={{align: 'center'}} variant="outlined" fontWeight="small" color="info" onClick={()=>window.open(p.file, '_blank', 'noreferrer')}>
                                        <Icon>download</Icon>&nbsp;
                                        Завантажити протокол
                                </MDButton>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                        { role === 'admin' && (
                            <MDBox display="flex" justifyContent="space-between">
        {/*                             <MDTypography variant="h6" component={Link} to={'#'} onClick={()=>handleEdit(p)} >
                                    <Tooltip title={'Редагувати'} placement="top">
                                        <Icon>edit</Icon>
                                    </Tooltip>
                                </MDTypography> */}
                                <MDBox >
                                    <MDButton style={{align: 'center'}} variant="outlined" fontWeight="small" color="info" onClick={()=>handleDelete(p)}>
                                            <Icon>delete</Icon>&nbsp;
                                            Видалити
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        )}
                        </Grid>
                    </Grid>
                </MDBox>
            ))}
        <Footer />
    </DashboardLayout>

    );

};

export default MeetingsDocuments;
