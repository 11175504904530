import {useState  } from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

export default function Data(meters) {
  const [selectedMetter, setSelectedMetter] = useState();

  return {
    selected: selectedMetter,
    columns: [
      { Header: "Назва", accessor: "name", align: "left", width : "max-content" },
      { Header: "Тип приладу", accessor: "device_name", align: "left", width : "max-content" },
      { Header: "Заводський номер", accessor: "factory_number", align: "left" , width : "max-content"},
      { Header: "Порядковий номер", accessor: "counter_no", align: "center" , width : "max-content"},
      { Header: "Період повірки", accessor: "control_period", align: "center", width : "max-content" },
      { Header: "Період зняття показників", accessor: "withdrawal_period", align: "center", width : "max-content" },
      { Header: "Дата встановлення", accessor: "start_date", align: "center", width : "max-content" },
      { Header: "Дата зняття", accessor: "end_date", align: "center", width : "max-content" },
      { Header: "Локалізація", accessor: "localization", align: "center", width : "max-content" },
    ],

    rows: meters.map((meter) => ({
      name: (
        <MDButton size="medium" variant="text" color="info" onClick={() => setSelectedMetter(meter)}>
        {meter.name}
        </MDButton>
      ),
      device_name: (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {meter.device_name}
        </MDTypography>
      ),
      factory_number: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {meter.factory_number}
          </MDTypography>
      ),
      counter_no: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {meter.counter_no}
          </MDTypography>
      ),
      control_period: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {meter.control_period}
          </MDTypography>
      ),
      withdrawal_period: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {meter.withdrawal_period}
          </MDTypography>
      ),
      start_date: (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {meter.start_date}
        </MDTypography>
      ),
      end_date: (
       <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
         {meter.end_date}
       </MDTypography>
      ),
      localization: (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {meter.localization}
        </MDTypography>
       ),
    })),
  };
}
