import React, { useCallback } from "react";
import { Field } from "react-final-form";
import { useDropzone } from "react-dropzone";
import MDButton from "components/MDButton";
import "assets/dragdrop/dd.css";

const FileField = ({ name, ...props }) => {
  return (
    <>
      <Field name={name} {...props} component={FileFieldInput} />
      <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
          touched && error ? <span>{error}</span> : null
        }
      />
    </>
  );
};

function FileFieldInput({ required, input, dropZoneProps, ...props }) {
  const onDrop = useCallback(
    (files) => {
      input.onChange(files);
    },
    [input]
  );

  const { getRootProps, getInputProps,  open,  acceptedFiles } = useDropzone({
    onDrop,
    //noDrag: true,
    noClick: true,
    maxFiles:1,
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    ...dropZoneProps
        // Disable click and keydown behavior
        /// noClick: true,
        // noKeyboard: true
  });

  const files = acceptedFiles.map((file) => (
    <span key={file.path}>
      {file.path} - {file.size} bytes
    </span>
  ));

  return (
    <div className="container">
        <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <MDButton style={{align: 'center'}} variant="gradient" fontWeight="small" color="info" onClick={open}>
                Огляд
        </MDButton>
        <p>Або перетягніть файл сюди</p>
        <em>(*.jpeg / *.png )</em>
        </div>
        <aside>
            <h4>Файл</h4>
            <ul>{files}</ul>
        </aside>
    </div>
  );
}

export default FileField;
