import { Navigate, Outlet } from "react-router-dom";
import {connect} from "react-redux";
import {getIsAuth} from "./redux/auth-selectors";

function PrivateRoute({isAuth}) {
  return isAuth ? <Outlet /> : <Navigate to="/authentication/sign-in" />;
  //return isAuth ? <Outlet /> : <Outlet />;
}

let mapStateToProps = (state) => {
  return {
    isAuth : getIsAuth(state),
  }
}
export default connect(mapStateToProps, null)(PrivateRoute)
