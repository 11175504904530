import React, {useEffect, useState} from "react"
import {requestDebtors, requestDebtorsSummary} from "../../../../redux/debtors-reducer";
import {useDispatch, useSelector} from "react-redux";
import DebtorsTableData from "./data/DebtorsTableData";
import Table from "../../../../components/Tables/Table";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Loader from "../../../../components/Loader/Loader";
import {getUser} from "../../../../redux/auth-selectors";

import {
    getDebtors,
    getDebtorsIsFetching,
    getDebtorsQueryPageIndex,
    getDebtorsQueryPageSize,
    getDebtorsTotalCount,
    getDebtorsSummary
} from "../../../../redux/debtors-selectors";



function Debtors( { balancedate } ) {
  const [minDebtSum, setMinDebtSum] = useState(10000)
  const queryPageIndex = useSelector(getDebtorsQueryPageIndex)
  const queryPageSize = useSelector(getDebtorsQueryPageSize)
  const totalCount = useSelector(getDebtorsTotalCount)
  const debtors = useSelector(getDebtors)
  const debtors_summary = useSelector(getDebtorsSummary)
  const isFetching = useSelector(getDebtorsIsFetching)

  const currentUser = useSelector(getUser)
  const [role, setRole] = useState((currentUser && currentUser.role) ? currentUser.role :'default')

  const handleMinDebtSumChange = (event) => {
    if (event.key === "Enter") {
      setMinDebtSum(event.target.value);
    }
  };

  let { columns, rows } = DebtorsTableData(debtors,balancedate,role);

  const dispatch = useDispatch()

  useEffect( () => {
      dispatch(requestDebtors(queryPageIndex, queryPageSize, minDebtSum, balancedate))
      dispatch(requestDebtorsSummary(minDebtSum, balancedate))
  }, [ minDebtSum, balancedate])

  const onDataChanged = ( queryPageIndex, queryPageSize) => {
      dispatch(requestDebtors( queryPageIndex, queryPageSize, minDebtSum, balancedate))
  }

  return (
  <>
        {isFetching && (
            <Loader />
        )}
        {
        !isFetching && (
          <div>
            <MDBox>
              <MDInput
              inputProps={{ type: "number" }}
              label="Мін.борг ..."
              name="minDebtSum"
              required={true}
              onKeyPress={handleMinDebtSumChange}
              fullWidth />
            </MDBox>
            <Table
                  title={"Боржники із заборгованістю від " + minDebtSum +'грн. Загальний борг = ' + debtors_summary[0]['total_debt_sum'] }
                  columns={columns}
                  rows={rows}
                  queryPageIndex={queryPageIndex}
                  queryPageSize={queryPageSize}
                  totalCount={totalCount}
                  onChanged={onDataChanged}
              />
          </div>
          )}
  </>
  );
}

export default Debtors;
