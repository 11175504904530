import {createSelector} from "reselect";

const getHousesSelector = (state) => {
    return state.houses.houses
}

export const getHouses = createSelector(getHousesSelector, (houses) => {
    return houses;
})

export const  getHousesQueryPageIndex = (state) => {
    return state.houses.queryPageIndex
}

export const getHousesQueryPageSize = (state) => {
    return state.houses.queryPageSize
}

export const  getHousesTotalCount = (state) => {
    return state.houses.totalCount
}

export const getHousesIsFetching = (state) => {
    return state.houses.isFetching
}
