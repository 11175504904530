import {HouseApi} from "../api/api";

const SET_HOUSE = "GET_HOUSE"
const SET_HOUSEADDINFO = "GET_HOUSEADDINFO"

let initialState = {
    house: null,
    houseaddinfo: []
}

const houseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HOUSE:
            return {
                ...state,
                house: action.value
            }
        case SET_HOUSEADDINFO:
            return {
                ...state,
                houseaddinfo: action.value
            }
        default:
            return state;
    }
}

export const setHouse = (value) => ({type: SET_HOUSE, value: value})
export const setHouseAddInfo = (value) => ({type: SET_HOUSEADDINFO, value: value})

export const getHouse = (houseId) => {
    return (dispatch) => {
        HouseApi.getHouse(houseId).then(data => {
                dispatch(setHouse(data))
            });
    }
}

export const updateHouse = async (data) => {
    try {
        let response = await HouseApi.updateHouse(data)
        return response
    } catch (error) {
        console.error("*** updateHouse Щось пішло не так ! ", error)
        return (error);
    }
}

export const get_HouseAddInfo = (houseId) => {
    return (dispatch) => {
        HouseApi.get_HouseAddInfo(houseId).then(data => {
                dispatch(setHouseAddInfo(data.results))
            });
    }
}

export const saveHouseAddInfo = async (data) => {
    try {
        let response;
            if(data.id === undefined){
                response = await HouseApi.createHouseAddInfo(data)
            } else {
                response = await HouseApi.updateHouseAddInfo(data)
            }
        return response
    } catch (error) {
        console.error("*** saveHouseAddInfo Щось пішло не так ! ", error)
        return (error);
    }
}

export const requestDelAddInfo = async (Id) => {
    try {
        const response = await HouseApi.deleteHouseAddInfo(Id)
        console.log("*** Успішно видалено! ", Id, response)
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
    }
}

export const saveHouseMeter = async (data) => {
    try {
        let response;
            if(data.id === undefined){
                response = await HouseApi.createHouseMeter(data)
            } else {
                response = await HouseApi.updateHouseMeter(data)
            }
        return response
    } catch (error) {
        console.error("*** saveHouseMeter Щось пішло не так ! ", error)
        return (error);
    }
}

export const requestDelMeter = async (house, Id) => {
    try {
        const response = await HouseApi.deleteHouseMeter(house, Id)
        console.log("*** Успішно видалено! ", Id, response)
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
    }
}

export default houseReducer
