import {Link, useNavigate} from "react-router-dom";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import { Form, Field } from 'react-final-form'
import { FORM_ERROR, setIn } from 'final-form'
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/pattern-top.svg"
//"assets/images/bg-sign-in-basic.jpeg";

import {useDispatch, useSelector} from "react-redux";
import {login} from "../../../redux/auth-reducer";
import {maxLengthCreator} from "../../../utils/validators";
import {getIsAuth} from "../../../redux/auth-selectors";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
const maxLength100 = maxLengthCreator(100)

function Basic() {

  const history = useNavigate();
  let isAuth = useSelector(getIsAuth)
  if (isAuth) {
    history("/");
  }
  let dispatch = useDispatch()

  const onSubmit = async values => {
    let errors = {}
    const setError = (key, value) => {
      errors = setIn(errors, key, value)
    }
    let status = await dispatch(login(values.email, values.password, values.osbb))
    if (!status.success) {
      values.osbb = ""
      if (status.osbbs?.length > 1) {
        setError("osbbs", status.osbbs)
        return errors
      } else {
        setError(FORM_ERROR, status.error)
        return errors;
      }
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Увійти до особистого кабінету
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Form
              onSubmit={onSubmit}
              initialValues={{ email: "", password: "", "osbb": null }}
              render={({submitError, submitErrors,handleSubmit, form, submitting, pristine, values }) => (
          <MDBox component="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <Field name="email" validate={maxLength100}>
                {props => (
                  <MDBox>
                    <MDInput type="email" label="Email" name={props.input.name} value={props.input.value} onChange={props.input.onChange} required={true} fullWidth />
                    {props.meta.touched && props.meta.error && (
                      <MDAlert color="error">
                        {props.meta.error}
                      </MDAlert>
                    )}
                  </MDBox>
                )}
              </Field>
            </MDBox>
            <MDBox mb={2}>
              <Field name="password">
                {props => (
                  <MDBox>
                    <MDInput type="password" label="Пароль" name={props.input.name} value={props.input.value} onChange={props.input.onChange} required={true} fullWidth />
                  </MDBox>
                )}
              </Field>
            </MDBox>
            {submitErrors && submitErrors.osbbs &&
                <MDBox mb={2}>
                  <Field name="osbb">
                    {props => (
                        <FormControl variant={"filled"} fullWidth>
                          <InputLabel id="outlined-osbb-simple">ОСББ</InputLabel>
                          <Select
                              labelId="outlined-osbb-simple"
                              fullWidth
                              name={props.input.name}
                              value={props.input.value}
                              onChange={props.input.onChange}
                              required={true}
                              label={"ОСББ"}
                          >
                            {submitErrors.osbbs.map((osbb) => (
                                <MenuItem key={osbb.id} value={osbb.id}>{osbb.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                    )}
                  </Field>
                </MDBox>
            }
            {submitError && <MDBox display="flex"
                                   justifyContent="center"
                                   alignItems="center"
                                   color="error" fontSize="small">
                              {submitError}
                            </MDBox>
            }
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={submitting || pristine }>
                Увійти
              </MDButton>
            </MDBox>
{/*             <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Немає акаунту?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Зареєструватися
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
            )}
          />
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
