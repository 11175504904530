import {DeviceApi} from "../api/api";

const SET_DEVICE = "SET_DEVICE"
const DEVICE_TOGGLE_IS_FETCHING = "DEVICE_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    device: [],
}

const deviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEVICE:
            return {
                ...state,
                device: action.value
            }
        case DEVICE_TOGGLE_IS_FETCHING:
                return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setDevice = (value) => ({type: SET_DEVICE, value: value})
export const deviceToggleIsFetching = (isFetching) => ({type: DEVICE_TOGGLE_IS_FETCHING, value: isFetching})

export const requestDevice = () => {
    return async (dispatch) => {
        dispatch(deviceToggleIsFetching(true))
        let response = await DeviceApi.getDevice()
        if (response !== undefined) {
            dispatch(setDevice(response.results))
            dispatch(deviceToggleIsFetching(false))
        }
    }
}

export default deviceReducer
