import {PaymentsApi} from "../api/api";

const GET_PAYSHIST = "GET_PAYSHIST"
const PAYSHIST_PAGE_CHANGED = 'PAYSHIST_PAGE_CHANGED';
const PAYSHIST_PAGE_SIZE_CHANGED = "PAYSHIST_PAGE_SIZE_CHANGED";
const PAYSHIST_TOTAL_COUNT_CHANGED = "PAYSHIST_TOTAL_COUNT_CHANGED";
const PAYSHIST_TOGGLE_IS_FETCHING = "PAYSHIST_TOGGLE_IS_FETCHING";

let initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    isFetching: false,
    payshist: []
}

const paymentshistoryReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case PAYSHIST_PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case PAYSHIST_PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case PAYSHIST_TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case GET_PAYSHIST:
            return {
                ...state,
                payshist: action.value
            }
        case PAYSHIST_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const getPayshist = (value) => ({type: GET_PAYSHIST, value: value})
export const setPayshistPageChanged = (value) => ({type: PAYSHIST_PAGE_CHANGED, value: value})
export const setPayshistPageSizeChanged = (value) => ({type: PAYSHIST_PAGE_SIZE_CHANGED, value: value})
export const setPayshistTotalCountChanged = (value) => ({type: PAYSHIST_TOTAL_COUNT_CHANGED, value: value})
export const payshistToggleIsFetching = (isFetching) => ({type: PAYSHIST_TOGGLE_IS_FETCHING, value: isFetching})

export const requestPayshist = (queryPageIndex, queryPageSize) => {
    return async (dispatch) => {
        dispatch(payshistToggleIsFetching(true))
        dispatch(setPayshistPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await PaymentsApi.get_paymentshistory(offset, queryPageSize)
        if (response !== undefined) {
            dispatch(setPayshistPageSizeChanged(queryPageSize))
            dispatch(setPayshistTotalCountChanged(response.count))
            dispatch(getPayshist(response.results))
            dispatch(payshistToggleIsFetching(false))
        }
    }
}


export default paymentshistoryReducer
