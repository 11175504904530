import {AccountApi, ProfileApi} from "../api/api";

const SET_ACCOUNT = "SET_ACCOUNT"
const GET_ACCOUNTS = "GET_ACCOUNTS"
const GET_ACCOUNT = "GET_ACCOUNT"
const ACCOUNTS_PAGE_CHANGED = 'ACCOUNTS_PAGE_CHANGED';
const ACCOUNTS_PAGE_SIZE_CHANGED = "ACCOUNTS_PAGE_SIZE_CHANGED";
const ACCOUNTS_TOTAL_COUNT_CHANGED = "ACCOUNTS_TOTAL_COUNT_CHANGED";
const ACCOUNTS_TOGGLE_IS_FETCHING = "ACCOUNTS_TOGGLE_IS_FETCHING";

let initialState = {
    account: null,
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    isFetching: false,
    accounts: []
}

const accountsReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case ACCOUNTS_PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case ACCOUNTS_PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case ACCOUNTS_TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case GET_ACCOUNTS:
            return {
                ...state,
                accounts: action.value
            }
        case GET_ACCOUNT:
            return {
                ...state,
                account: action.value
            }
        case ACCOUNTS_TOGGLE_IS_FETCHING:
            return {...state , isFetching: action.value}
        case SET_ACCOUNT:
            return {
                ...state,
                account: action.value
            }
        default:
            return state;
    }
}

export const getAccounts = (value) => ({type: GET_ACCOUNTS, value: value})
export const setAccount = (value) => ({type: SET_ACCOUNT, value: value})
export const setAccountsPageChanged = (value) => ({type: ACCOUNTS_PAGE_CHANGED, value: value})
export const setAccountsPageSizeChanged = (value) => ({type: ACCOUNTS_PAGE_SIZE_CHANGED, value: value})
export const setAccountsTotalCountChanged = (value) => ({type: ACCOUNTS_TOTAL_COUNT_CHANGED, value: value})
export const accountsToggleIsFetching = (isFetching) => ({type: ACCOUNTS_TOGGLE_IS_FETCHING, value: isFetching})

export const requestAccounts = (queryPageIndex, queryPageSize, locationSearch) => {
    return async (dispatch) => {
        dispatch(accountsToggleIsFetching(true))
        dispatch(setAccountsPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await AccountApi.getAccounts(offset, queryPageSize, locationSearch )
        if (response !== undefined) {
            dispatch(setAccountsPageSizeChanged(queryPageSize))
            dispatch(setAccountsTotalCountChanged(response.count))
            dispatch(getAccounts(response.results))
            dispatch(accountsToggleIsFetching(false))
        }
    }
}

export const getAccount = (accountId) => {
    return (dispatch) => {
        AccountApi.getAccount(accountId).then(data => {
            dispatch(setAccount(data))
        });
    }
}

export default accountsReducer
