import Card from "@mui/material/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


function PollResults({open, poll, handleClose}) {

return (
<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
    { poll.choice_set.length>0 && (
            <DialogTitle id="responsive-dialog-title">
                Результати голосування
            </DialogTitle>
    )}
    <DialogContent>
        { poll && (
            <Card style={{ height: "100%"}}>

                <MDBox pt={1} px={2}  pb={1} >
                     <MDTypography variant="body1">{poll.question_text}</MDTypography>
                </MDBox>
                    <MDBox pt={1} px={2}  pb={1} >
                        { poll.choice_set.length>0 && ( <MDTypography variant="body1" fontWeight="bold">Голосування: </MDTypography> )}
                        { poll.choice_set.map((choice,index1) => (
                            <MDBox pt={1} px={1} pb={1} key = {index1} >
                                <MDTypography variant="body2" fontWeight="bold" color="text">* {choice.choice_text}</MDTypography>
                                {choice.vote_users.map((vote,index2) => (
                                    <MDBox pt={1} px={1} pb={1} key = {index2} >
                                        <MDTypography  color="text">{vote.date_str} - {vote.user.username}</MDTypography>
                                    </MDBox>
                                ))}
                            </MDBox>

                        ))}
                    </MDBox>
            </Card>
        )}
    </DialogContent>
    <DialogActions>
        <MDButton variant="gradient" fontWeight="small" color="dark" onClick={handleClose} >
            Закрити
        </MDButton>
    </DialogActions>
</Dialog>
    );
}

export default PollResults;
