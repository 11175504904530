import {chatApi, ChatMessageType, StatusType} from "../api/chat-api";
import {Dispatch} from "redux";
import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {requestMessages} from "./chat-messages-reducer";

export type ChatUserType = {
    id: string
    username: string
}

let initialState = {
    newMessage: null as ChatMessageType,
    readyStatus: 'pending' as StatusType,
    chatUser: null as ChatUserType,
}

const chatReducer = (state = initialState, action: ActionsType): InitialStateType => {

    switch (action.type) {
        case 'OSBB/chat/MESSAGES_RECEIVED':
            return {
                ...state,
                newMessage: action.payload.message
            }
        case 'OSBB/chat/STATUS_CHANGED':
            return {
                ...state,
                readyStatus: action.payload.status
            }
        case 'OSBB/chat/CHANG_USER':
            return {
                ...state,
                chatUser: action.payload.user
            }
        default:
            return state;
    }
}

export const actions = {
    messagesReceived: (message: ChatMessageType) => ({
        type: 'OSBB/chat/MESSAGES_RECEIVED', payload: {message}
    } as const),
    statusChanged: (status: StatusType) => ({
        type: 'OSBB/chat/STATUS_CHANGED', payload: {status}
    } as const),
    changeUser: (user: ChatUserType) => ({
        type: 'OSBB/chat/CHANG_USER', payload: {user}
    } as const),
}

let _newMessageHandlers = {}
let _newMessageHandler: ((message: ChatMessageType) =>void) | null = null;

const newMessageHandlerCreator = (dispatch: Dispatch, profileId: number) => {
    if (!(profileId in _newMessageHandlers)) {
        _newMessageHandler = (message) => {
            dispatch(actions.messagesReceived(message))
        }
        _newMessageHandlers[profileId] = _newMessageHandler
    }
    return _newMessageHandlers[profileId]
}

let _statusChangedHandlers = {}
let _statusChangedHandler: ((status: StatusType) =>void) | null = null;

export const changeChatUser = (user: ChatUserType) => {
    return (dispatch) => {
        dispatch(actions.changeUser(user))
    }
}

const statusChangedHandlerCreator = (dispatch: Dispatch, profileId) => {
    if (!(profileId in _statusChangedHandlers)) {
        _statusChangedHandler = (status) => {
            dispatch(actions.statusChanged(status))
        }
        _statusChangedHandlers[profileId] = _statusChangedHandler
    }
    return _statusChangedHandlers[profileId]
}

export const startMessagesListening = (profileId): ThunkType => async (dispatch, getState) => {
    if (getState().chat.readyStatus !== 'ready') {
        await chatApi.start()
    }
    chatApi.subscribe('messages-received', newMessageHandlerCreator(dispatch, profileId))
    chatApi.subscribe('status-changed', statusChangedHandlerCreator(dispatch, profileId))
}

export const stopMessagesListening = (profileId): ThunkType => async (dispatch) => {
    chatApi.unsubscribe('messages-received', newMessageHandlerCreator(dispatch, profileId))
    chatApi.unsubscribe('status-changed', statusChangedHandlerCreator(dispatch, profileId))
    if (profileId === 0) {
        chatApi.stop()
    }
}

export const sendMessage = (message: string, user_id: bigint): ThunkType => async (dispatch) => {
    chatApi.sendMessage(message, user_id)
}

export default chatReducer

export type InitialStateType = typeof initialState;

type ActionsType = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsType>
