import {createSelector} from "reselect";

const getRatesSelector = (state) => {
    return state.rates.rates
}

export const getRates = createSelector(getRatesSelector, (rates) => {
    return rates;
})

export const getRatesIsFetching = (state) => {
    return state.rates.isFetching
}


