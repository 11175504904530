import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

export default function Data(flats) {

  return {
    columns: [
      { Header: "Номер", accessor: "flat_number", align: "left", width : "max-content" },
      { Header: "Загальна площа", accessor: "total_square", align: "center", width : "max-content" },
      { Header: "Житлова площа", accessor: "living_square", align: "center", width : "max-content" },
      { Header: "Поверх", accessor: "floor_number", align: "center", width : "max-content" },
      { Header: "Підїзд/секція", accessor: "entrance_number", align: "center", width : "max-content" },
      { Header: "Дія", accessor: "action", align: "center", width : "max-content" },
    ],

    rows: flats.map((flat) => ({
      flat_number: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
           кв. {flat.flat_number} ({flat.house.title} )
          </MDTypography>
      ),
      total_square: (
        <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
          {flat.total_square}
        </MDTypography>
      ),
      living_square: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {flat.living_square}
          </MDTypography>
      ),
      floor_number: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {flat.floor_number}
          </MDTypography>
      ),
      entrance_number: (
          <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
            {flat.entrance_number}
          </MDTypography>
      ),
      action: (
        <MDButton component={Link} to={"/flatmetters/" + flat.id} size="small"  variant="text" color="info">
          Детальніше
        </MDButton>
      ),
    })),
  };
}
