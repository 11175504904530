import {createSelector} from "reselect";

const getUnitSelector = (state) => {
    return state.unit.unit
}

export const getUnit = createSelector(getUnitSelector, (unit) => {
    return unit;
})

export const getUnitIsFetching = (state) => {
    return state.unit.isFetching
}
