import { FlatApi } from "../api/api";

const GET_METTERSHIST = "GET_METTERSHIST"
const METTERSHIST_PAGE_CHANGED = 'METTERSHIST_PAGE_CHANGED';
const METTERSHIST_PAGE_SIZE_CHANGED = "METTERSHIST_PAGE_SIZE_CHANGED";
const METTERSHIST_TOTAL_COUNT_CHANGED = "METTERSHIST_TOTAL_COUNT_CHANGED";
const METTERSHIST_TOGGLE_IS_FETCHING = "METTERSHIST_TOGGLE_IS_FETCHING";
const GET_METTERSHIST_REPORT = "GET_METTERSHIST_REPORT";

let initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    isFetching: false,
    mettershist: [],
    GET_METTERSHIST_REPORT: []
}

const mettershistoryReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case METTERSHIST_PAGE_CHANGED:
            return {
                ...state,
                queryPageIndex: action.value,
            };
        case METTERSHIST_PAGE_SIZE_CHANGED:
            return {
                ...state,
                queryPageSize: action.value,
            };
        case METTERSHIST_TOTAL_COUNT_CHANGED:
            return {
                ...state,
                totalCount: action.value,
            };
        case GET_METTERSHIST_REPORT:
                return {
                    ...state,
                    mettershistreport: action.value
                };
        case GET_METTERSHIST:
            return {
                ...state,
                mettershist: action.value
            }
        case METTERSHIST_TOGGLE_IS_FETCHING:
            return { ...state, isFetching: action.value }
        default:
            return state;
    }
}

export const getMettershist = (value) => ({ type: GET_METTERSHIST, value: value })
export const getMettershistReport = (value) => ({ type: GET_METTERSHIST_REPORT, value: value })
export const setMettershistPageChanged = (value) => ({ type: METTERSHIST_PAGE_CHANGED, value: value })
export const setMettershistPageSizeChanged = (value) => ({ type: METTERSHIST_PAGE_SIZE_CHANGED, value: value })
export const setMettershistTotalCountChanged = (value) => ({ type: METTERSHIST_TOTAL_COUNT_CHANGED, value: value })
export const mettershistToggleIsFetching = (isFetching) => ({ type: METTERSHIST_TOGGLE_IS_FETCHING, value: isFetching })

export const requestMettershist = (balancedate, seviceType, queryPageIndex, queryPageSize, metter, balancedate2, locationSearch) => {
    return async (dispatch) => {
        dispatch(mettershistToggleIsFetching(true))
        dispatch(setMettershistPageChanged(queryPageIndex))
        let offset = queryPageIndex * queryPageSize;
        let response = await FlatApi.getFlatMettersData(balancedate ? balancedate.toISOString().slice(0, 10) : null,
                                                        balancedate2 ? balancedate2.toISOString().slice(0, 10) : null,
                                                        seviceType, offset, queryPageSize, metter, locationSearch)
        if (response !== undefined) {
            dispatch(setMettershistPageSizeChanged(queryPageSize))
            dispatch(setMettershistTotalCountChanged(response.count))
            dispatch(getMettershist(response.results))
            dispatch(mettershistToggleIsFetching(false))
        }
    }
}

export const requestMettershistReport = (balancedate, balancedate2, seviceType) => {
    return async (dispatch) => {
        let response = await FlatApi.getFlatMettersDataReport(balancedate ? balancedate.toISOString().slice(0, 10) : null,
                                                            // balancedate2 ? balancedate2.toISOString().slice(0, 10) : null,
                                                            seviceType)
        if (response !== undefined) {
            dispatch(getMettershistReport(response.results))
        }
    }
}

export default mettershistoryReducer
