import {useEffect, useState} from "react"
import Account from "./Account"
import {connect, useDispatch} from "react-redux";
import { getAccount } from "../../redux/accounts-reducer";
import {useParams} from "react-router-dom";
import {requestAccountProfiles} from "../../redux/account-profiles-reducer";
import {requestAccountServices} from "../../redux/account-services-reducer";
import {requestAccountAgreements} from "../../redux/account-agreements-reducer";



function AccountContainer(props) {
    let { accountId, balancedate } = useParams();
    const dispatch = useDispatch()
    const [BalanceDate, setBalanceDate] = useState(balancedate?new Date(balancedate):new Date());
    const handleDateChange = (BalanceDate) => {
        setBalanceDate(BalanceDate);
    };

    useEffect(() => {
        dispatch(getAccount(accountId))
    }, [accountId]);

    useEffect(() =>{
        dispatch(requestAccountProfiles(accountId))
    }, [accountId])

    useEffect(() => {
        dispatch(requestAccountServices(accountId,BalanceDate))
    }, [accountId,BalanceDate])

    useEffect(() => {
        dispatch(requestAccountAgreements(accountId))
    }, [accountId])

    return <Account
                    account={props.account}
                    accountProfiles={props.accountProfiles}
                    accountAgreements={props.accountAgreements}
                    accountServices={props.accountServices}
                    balancedate={BalanceDate}
                    onChange={handleDateChange}
    />
}

let mapStateToProps = (state) => {
    return {
        account : state.accounts.account,
        accountProfiles: state.accountProfiles.profiles,
        accountServices: state.accountServices.services,
        accountAgreements: state.accountAgreements.agreements,
    }
}

export default connect(mapStateToProps, null) (AccountContainer);
