import {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPolls, getPollsIsFetching} from "../../redux/polls-selectors";
import {requestAllPolls} from "../../redux/polls-reducer";
import Loader from "../Loader/Loader";

import { ImageList } from '@material-ui/core'
import { ImageListItem }  from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import PollOptions from './PollOptions';
import PollDialogForm from "./PollDialogForm";


const styles = theme => ({
    root: {
       display: 'flex',
       flexWrap: 'wrap',
       //justifyContent: 'space-around',
       overflow: 'hidden',
       //backgroundColor: theme.palette.background.paper,
       'line-height': 1,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: '#1a73e8',//theme.palette.primary.light,
    },
  });

function PollsAdmin(props ) {
    const { classes } = props;
    const polls = useSelector(getPolls)
    const isPollFetching = useSelector(getPollsIsFetching)

    const [isChanged, handleChanged] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const defaultPoll = { "question_text":"",
                            "start_date": null,
                            "close_date": null,
                            "choice_set":[],
                            "background_color":"",
                            "closed":false
                        }
    const [editPoll, setEditPoll] = useState(defaultPoll);

    const [activePoll, setActivePoll] = useState(false);
    const [completedPoll, setCompletedPoll] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestAllPolls(activePoll, completedPoll))
    }, [isChanged, isDeleted, activePoll, completedPoll])

    const [open, setOpen] = useState(false);

    const handleAdd = () => {
        setOpen(true)
        setEditPoll(defaultPoll)
    };

    const handleEdit = (poll) => {
        setOpen(true)
        setEditPoll(poll)
    };

    const handleClose = () => {
        setOpen(false);
        handleChanged( !isChanged );
    };

     return (
        <DashboardLayout>
            <DashboardNavbar />
            {isPollFetching && (
                <Loader/>
            )}
            {
                !isPollFetching && (
                    <>

                    <ListSubheader className={classes.title} component="div">Мої оголошення</ListSubheader>
                        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5} >
                            <MDBox ml={4}  mt={0.5}>
                                <MDTypography className={classes.title} component={Link} to={'#'} variant="h2" onClick={()=>handleAdd()}>
                                    <Tooltip title={'Додати нове оголошення'} placement="top">
                                        <Icon>post_add</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                            <MDBox   ml={12} mt={0.5}>
                                <FormControlLabel control={<Switch checked={activePoll} onChange={() => setActivePoll(!activePoll) } />} label="Активні" />
                            </MDBox>
                            <MDBox mt={0.5}>
                                <FormControlLabel control={<Switch checked={completedPoll} onChange={() => setCompletedPoll(!completedPoll) }/>} label="Завершені" />
                            </MDBox>
                        </MDBox>
                        <div className={classes.root}>
                            <ImageList className={classes.gridList} cols={ polls.length < 5 ? polls.length : 4.5 } rowHeight ={'auto'}  >
                                    {polls.map((poll) => (
                                        <ImageListItem  key={poll.id}>
                                            <PollOptions poll={poll} onChange={setIsDeleted} handleEdit={(p)=>handleEdit(p)}/>
                                        </ImageListItem >
                                    ))}
                            </ImageList >
                        </div>
                        <PollDialogForm open={open} handleClose={handleClose} editPoll={editPoll} />
                    </>
                )
            }
            <Footer />
        </DashboardLayout>
    );
}

PollsAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(PollsAdmin);
