import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Link} from "react-router-dom";

export default function Data(accounts) {

    const Account = ({number, id}) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox ml={2} lineHeight={1}>
                <MDButton component={Link} to={"/account/" + id} size="large" variant="text" color="info">
                    {number}
                </MDButton>
            </MDBox>
        </MDBox>
    );

    return {
        columns: [
            {Header: "Особовий рахунок", accessor: "number", align: "center", width : "max-content"},
          //  {Header: "Дата", accessor: "start_date", align: "center", width : "max-content"},
            {Header: "ПІБ", accessor: "profile", align: "center", width : "max-content", enableSorting: false}, // disable sorting for this column
            {Header: "Адреса", accessor: "flat", align: "center", width : "max-content"},
          //  {Header: "Баланс", accessor: "balance", align: "center", width : "max-content"},
        ],

        rows: accounts.map((account) => ({
            number: <Account number={account.number} id={account.id}/>,
            start_date: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.start_date}
                </MDTypography>
            ),
            profile: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.profile.last_name}  {account.profile.first_name}  {account.profile.second_name}
                </MDTypography>
            ),
            flat: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.flat.house.title} {account.flat.flat_number}
                </MDTypography>
            ),
            balance: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {account.balance}
                </MDTypography>
            ),
        })),
    };
}
