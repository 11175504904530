import {useState,useEffect  } from "react";
import {useDispatch, useSelector} from "react-redux";
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CardHeader from "@mui/material/CardHeader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BalanceDatePicker from "components/BalanceDatePicker/BalanceDatePicker";
import ListSubheader from '@material-ui/core/ListSubheader';
import { getOsbbServiceSummary } from "../../redux/summary-reducer";
import { get_OsbbServiceSummary } from "../../redux/summary-selectors";
import ServiceSum from './ServiceSum';
import Summary from './Summary';

function OsbbServiceSummary( { balancedate, onChange }) {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const servicesummary = useSelector(get_OsbbServiceSummary)
    const [selectedDate, setselectedDate] = useState(balancedate?balancedate:new Date());
    const handleDateChange = (selectedDate) => {
          setselectedDate(selectedDate);
          onChange(selectedDate);
    };

    useEffect(() => {
        dispatch(getOsbbServiceSummary(selectedDate))
    }, [selectedDate])

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
    setExpanded(!expanded);
    };

return (  ( servicesummary && servicesummary.results) ?
    <div >
      <ListSubheader style={{color: '#4babf0'}} component="div">
        {"Дані по розрахунках "} &nbsp;&nbsp;&nbsp;
        <BalanceDatePicker selecteddate={selectedDate} onChange={handleDateChange} />
      </ListSubheader>

      {servicesummary.results.map((service) => (
           ( ! service.rollup ) ?
            <ServiceSum key = {service.service} serv = {service} balancedate={selectedDate} /> : <></>
       ))}
        <CardHeader
          subheader="Інші послуги..."
          action={
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="large"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        >
        </CardHeader>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
            {servicesummary.results.map((service) => (
            ( service.rollup ) ?
                <ServiceSum key = {service.service} serv = {service} balancedate={selectedDate} /> : <></>
        ))}
        </CardContent>
      </Collapse>
      <Summary balancedate={selectedDate} />
    </div>
   : <div></div>
    );
}


export default OsbbServiceSummary;
