import {FinanceApi} from "../api/api";

const SET_SERVICE = "SET_SERVICE"
const SERVICE_TOGGLE_IS_FETCHING = "SERVICE_TOGGLE_IS_FETCHING";

let initialState = {
    isFetching: false,
    service: [],
}

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SERVICE:
            return {
                ...state,
                service: action.value
            }
        case SERVICE_TOGGLE_IS_FETCHING:
                return {...state , isFetching: action.value}
        default:
            return state;
    }
}

export const setService = (value) => ({type: SET_SERVICE, value: value})
export const serviceToggleIsFetching = (isFetching) => ({type: SERVICE_TOGGLE_IS_FETCHING, value: isFetching})

export const requestService = () => {
    return async (dispatch) => {
        dispatch(serviceToggleIsFetching(true))
        let response = await FinanceApi.getService()
        if (response !== undefined) {
            dispatch(setService(response.results))
            dispatch(serviceToggleIsFetching(false))
        }
    }
}

export default serviceReducer
