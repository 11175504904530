import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import {getOsbbStats} from "../../../redux/osbb-reducer";
import {getStats} from "../../../redux/osbb-selectors";


export default function Stats(user) {

  const months = ['','Січ.','Лют.','Бер.','Квіт.','Трав.','Черв.','Лип.','Серп.','Вер.','Жов.','Лис.','Груд.' ]
  const st = { debt: { labels: [], datasets: { label:"Заборгованість", data: [] }},
             accruals:{ labels: [], datasets: { label:"Нарахування", data: [] }},
             payments: { labels: [], datasets: { label:"Оплати", data: [] }}
            }
  const dispatch = useDispatch()

  useEffect( () => {
      dispatch(getOsbbStats(user))
  }, [])

  const stats = useSelector(getStats)

  stats.map((item) => {
    st['debt']['labels'].push(months[item.date__month])
    st['debt']['datasets']['data'].push(item.debt_sum)
    st['accruals']['labels'].push(months[item.date__month])
    st['accruals']['datasets']['data'].push(item.total_accrual)
    st['payments']['labels'].push(months[item.date__month])
    st['payments']['datasets']['data'].push(item.total_payments_buh)
  })

  return st;

}
