import {createSelector} from "reselect";

const getDebtorsSelector = (state) => {
    return state.debtors.debtors
}

export const getDebtors = createSelector(getDebtorsSelector, (debtors) => {
    return debtors;
})

export const  getDebtorsQueryPageIndex = (state) => {
    return state.debtors.queryPageIndex
}

export const getDebtorsQueryPageSize = (state) => {
    return state.debtors.queryPageSize
}

export const  getDebtorsTotalCount = (state) => {
    return state.debtors.totalCount
}

export const getDebtorsIsFetching = (state) => {
    return state.debtors.isFetching
}

export const  getDebtorsSummary = (state) => {
    return state.debtors.debtors_summary
}
