import {ProfileApi} from "../api/api";

const SET_PROFILE = "GET_PROFILE"
const SET_PROFILETYPES = "GET_PROFILETYPES"

let initialState = {
    profile: null,
    profiletypes: [],
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                profile: action.value
            };
        case SET_PROFILETYPES:
                return {
                    ...state,
                    profiletypes: action.value
                };
        default:
            return state;
    }
}

export const setProfile = (value) => ({type: SET_PROFILE, value: value})
export const setProfileTypes = (value) => ({type: SET_PROFILETYPES, value: value})

export const getProfile = (profileId) => {
    return (dispatch) => {
        ProfileApi.getProfile(profileId).then(data => {
                dispatch(setProfile(data))
            });
    }
}

export const requestProfileTypes = () => {
    return (dispatch) => {
        ProfileApi.getProfileTypes().then(data => {
                dispatch(setProfileTypes(data))
            });
    }
}

export const saveProfile = async (data) => {
    try {
        let response ;
        if (data.values ) {
            if(data.values.id === undefined){
                response = await ProfileApi.createProfile(data.values)
            } else {
                response = await ProfileApi.updateProfile(data.values)
            }
        }
        return response
    } catch (error) {
        console.error("*** saveProfile Щось пішло не так ! ", error)
        return (error);
    }
}

export const sendAccount = async (profileId) => {
    try {
        const response = await ProfileApi.sendAccount(profileId)
        console.log("*** Успішно відправлено! ", profileId, response)
        return response
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
        return (error);
    }
}

export const deactivate = async (profileId) => {
    try {
        const response = await ProfileApi.deactivate(profileId)
        console.log("*** Успішно deactivate! ", profileId, response)
        return response
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
        return (error);
    }
}

export const activate = async (profileId) => {
    try {
        const response = await ProfileApi.activate(profileId)
        console.log("*** Успішно activate! ", profileId, response)
        return response
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
        return (error);
    }
}

export const deleteProfile = async (profileId) => {
    try {
        const response = await ProfileApi.deleteProfile(profileId)
        console.log("*** Успішно deleted! ", profileId, response)
        return response
    } catch (error) {
        console.error("*** Щось пішло не так ! ", error)
        return (error);
    }
}

export default profileReducer
