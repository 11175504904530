import React, {useEffect} from "react";
import Poll from "./Poll";
import {useDispatch, useSelector} from "react-redux";
import {getPolls, getPollsIsFetching} from "../../redux/polls-selectors";
import {requestPolls} from "../../redux/polls-reducer";
import Loader from "../Loader/Loader";

import { ImageList } from '@material-ui/core'
import { ImageListItem }  from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';

const styles = theme => ({
    root: {
       display: 'flex',
       flexWrap: 'wrap',
       //justifyContent: 'space-around',
       overflow: 'hidden',
       //backgroundColor: theme.palette.background.paper,
       'line-height': 1,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: '#4babf0',//theme.palette.primary.light,
    },
  });

function Polls(props) {
    const { classes } = props;
    const polls = useSelector(getPolls)
    const isPollFetching = useSelector(getPollsIsFetching)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestPolls())
    }, [])

    return (
        <>
            {isPollFetching && (
                <Loader/>
            )}
            {
                !isPollFetching && (
                    <div className={classes.root}>
                        <ListSubheader className={classes.title} component="div">Мої оголошення</ListSubheader>
                        <ImageList className={classes.gridList}  cols={4.5} rowHeight ={'auto'}  >
                                {polls.map((poll) => (
                                    <ImageListItem  key={poll.id}>
                                        <Poll poll={poll}/>
                                    </ImageListItem >
                                ))}
                        </ImageList >
                    </div>

                )
            }
        </>
    );
}

Polls.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(Polls);
