import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

export default function Data( houses ) {
    const House = ({title}) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="body2" fontWeight="medium">
                    {title}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    return {
        columns: [
            {Header: "Назва", accessor: "title", align: "left", width : "max-content"},
            {Header: "Будинок", accessor: "house_number", align: "center", width : "max-content"},
            {Header: "дія", accessor: "action", align: "center", width : "max-content"},
        ],

        rows: houses.map((house) => ({
            title: <House title={house.title}/>,
            house_number: (
                <MDTypography component="span" variant="body2" color="text" fontWeight="medium">
                    {house.house_number} {house.house_corps}
                </MDTypography>
            ),
            action: (
                <MDButton component={Link} to={"/housemetters/" + house.id} size="small"  variant="text" color="info">
                    Детальніше
                </MDButton>
            ),
        })),
    };
}
